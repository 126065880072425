import React from "react";
import { withRouter } from "react-router";

class CopdForm extends React.Component {
    state = {
        historyCopd: '',
        breathlessness: '',
        sputum: '',
        drugsList: '',
        wheezes: '',
        pulseRateIncreased: ''
    }

    updateHisotryCopd = (event) => {
        const value = event.target.value;
        this.setState({historyCopd: value});
    }

    updateBreathlessness = (event) => {
        const value = event.target.value;

        this.setState({breathlessness: value});
    }

    updateSputum = (event) => {
        const value = event.target.value;

        this.setState({sputum: value});
    }

    udpateDrugsList = (event) => {
        const value = event.target.value;

        this.setState({drugsList: value});
    }

    updatePulseRateIncrease10 = (event) => {
        const value = event.target.value;

        this.setState({pulseRateIncreased: value});
    }

    updateWheezes = (event) => {
        const value = event.target.value;

        this.setState({wheezes: value});
    }



    render() {
        const {
            historyCopd,
            breathlessness,
            sputum,
            drugsList,
            pulseRateIncreased,
            wheezes
        } = this.state;

        const historyCopdYesColor = historyCopd === 'yes' ? 'button-hightlight-bg' : '';
        const historyCopdNoColor = historyCopd === 'no' ? 'button-hightlight-bg' : '';

        const breathlessnessYesColor = breathlessness === 'yes' ? 'button-hightlight-bg' : '';
        const breathlessnessNoColor = breathlessness === 'no' ? 'button-hightlight-bg' : '';

        const sputumYesColor = sputum === 'yes' ? 'button-hightlight-bg' : '';
        const sputumNoColor = sputum === 'no' ? 'button-hightlight-bg' : '';

        const drugsListYesColor = drugsList === 'yes' ? 'button-hightlight-bg' : '';
        const drugsListNoColor = drugsList === 'no' ? 'button-hightlight-bg' : '';

        const pulseRateIncreaseYesColor = pulseRateIncreased === 'yes' ? 'button-hightlight-bg' : '';
        const pulseRateIncreaseNoColor = pulseRateIncreased === 'no' ? 'button-hightlight-bg' : '';

        const wheezesYesColor = wheezes === 'yes' ? 'button-hightlight-bg' : '';
        const wheezesNoColor = wheezes === 'no' ? 'button-hightlight-bg' : '';



        return (
            <form className="copd-form-container">
                <div className="menu-icon-with-apollo-logo">
                    <button className="button back-button" onClick={this.pushToUserList}>
                        <img src="./back-arrow.svg" alt="back-arrow.svg" />
                    </button>
                    <img className="small-apollo-logo" src="./logo.png" alt="apollo.png" />
                </div>
                <div className="large-back-button-container">
                    <button className="button back-button" onClick={this.pushToUserList}>
                        <img src="./back-arrow.svg" alt="back-arrow.svg" />
                    </button>
                    <p className="large-back-button-text">Back</p>
                </div>
                <div className="health-camp-heading-container">
                    <p className="apollo-hospitals-text">Apollo Hospitals</p>
                    <h1 className="heading text-bold">Health Camps</h1>
                    <div className="heading-style-element-container">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center margin-bottom-40">
                    <div className="style-element-container bottom-0">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                    <div className="sub-heading-with-logo margin-left-right-8">
                        <h1 className="sub-heading">Clinical Parameters &#38; History</h1>
                        <img className="sub-heading-icon" src="./receipt-outline.svg" alt="list.svg" />
                    </div>
                    <div className="style-element-container bottom-0 rotate-180">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
                    <div className="user-create-input">
                        <label className="label padding-0">Does the participant have history of COPD / Tuberculosis or Heart Disease ?</label>
                        <div className="d-flex flex-row margin-top-10 margin-bottom-30">
                            <button className={`button yes-no-button ${historyCopdYesColor}`} value="yes" type="button" onClick={this.updateHisotryCopd} >Yes</button>
                            <button className={`button yes-no-button ${historyCopdNoColor}`} value="no" type="button" onClick={this.updateHisotryCopd}>No</button>
                        </div>
                    </div>
                    <div className="user-create-input">
                        <label className="label padding-0">Does participant feel progressive breathlessness during normal activity, exercise or at night ?</label>
                        <div className="d-flex flex-row margin-top-10 margin-bottom-30">
                            <button className={`button yes-no-button ${breathlessnessYesColor}`} value="yes" type="button" onClick={this.updateBreathlessness} >Yes</button>
                            <button className={`button yes-no-button ${breathlessnessNoColor}`} value="no" type="button" onClick={this.updateBreathlessness}>No</button>
                        </div>
                    </div>
                </div>
                <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
                    <div className="user-create-input">
                        <label className="label padding-0">Does the participant have cough with sputum production ?</label>
                        <div className="d-flex flex-row margin-top-10 margin-bottom-30">
                            <button className={`button yes-no-button ${sputumYesColor}`} value="yes" type="button" onClick={this.updateSputum} >Yes</button>
                            <button className={`button yes-no-button ${sputumNoColor}`} value="no" type="button" onClick={this.updateSputum}>No</button>
                        </div>
                    </div>
                    <div className="user-create-input">
                        <label className="label padding-0">Does the participant use any one the drugs listed below ?</label>
                        <p className="drugs-list">Group 2 : Inhalers[Budesonide I Formoterol I Ipratropium I (levo) Salbutamol ISalmeterol I Tiotropium I Others]</p>
                        <p className="drugs-list">Group 3 : Bronchodilator I Lung Surfactant I Nebulizers[Acetylcysteine|Aminophyline|Phospholipidfraction|Others]</p>
                        <div className="d-flex flex-row margin-top-10 margin-bottom-30">
                            <button className={`button yes-no-button ${drugsListYesColor}`} value="yes" type="button" onClick={this.udpateDrugsList} >Yes</button>
                            <button className={`button yes-no-button ${drugsListNoColor}`} value="no" type="button" onClick={this.udpateDrugsList}>No</button>
                        </div>
                    </div>
                </div>
                <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850 margin-bottom-40">
                    <div className="user-create-input">
                        <label className="label padding-0">Has the participant's pulse rate increased by 10% from baseline recently ?</label>
                        <div className="d-flex flex-row margin-top-10 margin-bottom-30">
                            <button className={`button yes-no-button ${pulseRateIncreaseYesColor}`} value="yes" type="button" onClick={this.updatePulseRateIncrease10} >Yes</button>
                            <button className={`button yes-no-button ${pulseRateIncreaseNoColor}`} value="no" type="button" onClick={this.updatePulseRateIncrease10}>No</button>
                        </div>
                    </div>
                    <div className="user-create-input">
                        <label className="label padding-0">On participant's chest auscultation, are there presents of wheezes ?</label>
                        <div className="d-flex flex-row margin-top-10 margin-bottom-30">
                            <button className={`button yes-no-button ${wheezesYesColor}`} value="yes" type="button" onClick={this.updateWheezes} >Yes</button>
                            <button className={`button yes-no-button ${wheezesNoColor}`} value="no" type="button" onClick={this.updateWheezes}>No</button>
                        </div>
                    </div>
                </div>
                <button className="button next-button">
                    <p className="button-text">Submit</p>
                </button>
                <div className="end-line"></div>
                <p className="copy-rights-text">
                    <span className="font-weight-700">Copyright @{new Date().getFullYear()} Powered By Apollo Hospitals.</span><br /> All rights reserved.
                </p>
            </form>
        )
    }
}

export default withRouter(CopdForm);