import Cookies from "js-cookie";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

class Navigation extends React.Component {
  logout = () => {
    const { history } = this.props;
    history.replace("/login");
    Cookies.remove("jwt_token");
    localStorage.clear();
  };

  render() {
    const jwt_token = Cookies.get("jwt_token");
    let role = "";
    // let userName = "";
    if (jwt_token !== undefined) {
      const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
          console.log(e.message);
          return null;
        }
      };

      const parseJwtObj = parseJwt(jwt_token);
      role = parseJwtObj.uRole;
      // userName = parseJwtObj.name;
    } else {
      <Redirect to="/login" />;
    }
    return (
      <div className="navigation-container">
        <img className="large-apollo-logo" src="./logo.png" alt="apollo.png" />
        {role === "admin" ? (
          <>
            <Link className="link-tag" to="/dashboard">
              <div className="nav-option-container">
                <img
                  className="nav-option-icon"
                  src="./clipboard-outline.svg"
                  alt="clipboard-2.svg"
                />
                <p className="nav-option-text">Dashboard</p>
              </div>
            </Link>
            <Link className="link-tag" to="/camps-list">
              <div className="nav-option-container">
                <img
                  className="nav-option-icon"
                  src="./clipboard-outline.svg"
                  alt="clipboard-2.svg"
                />
                <p className="nav-option-text">Camps</p>
              </div>
            </Link>
            <Link className="link-tag" to="/user-list">
              <div className="nav-option-container">
                <img
                  className="nav-option-icon"
                  src="./people-outline.svg"
                  alt="clipboard-2.svg"
                />
                <p className="nav-option-text">Users</p>
              </div>
            </Link>
            <Link className="link-tag" to="/form-list">
              <div className="nav-option-container nav-option-container-padding">
                <img
                  className="nav-option-icon"
                  src="./contact-form-svgrepo-com.svg"
                  alt="clipboard-2.svg"
                />
                <p className="nav-option-text">Forms</p>
              </div>
            </Link>
          </>
        ) : (
          <>
            <Link className="link-tag" to="/camps-list">
              <div className="nav-option-container">
                <img
                  className="nav-option-icon"
                  src="./home-outline.svg"
                  alt="clipboard-2.svg"
                />
                <p className="nav-option-text">Home</p>
              </div>
            </Link>
            <Link className="link-tag" to="/camps-list">
              <div className="nav-option-container">
                <img
                  className="nav-option-icon"
                  src="./clipboard-outline.svg"
                  alt="clipboard-2.svg"
                />
                <p className="nav-option-text">Camps</p>
              </div>
            </Link>
            <Link className="link-tag" to="/total-patient-list">
              <div className="nav-option-container">
                <img
                  className="nav-option-icon"
                  src="patient.png"
                  alt="clipboard-2.svg"
                />
                <p className="nav-option-text">Patients</p>
              </div>
            </Link>
          </>
        )}
        <div className="user-details-container"></div>
        <button className="button logout-button" onClick={this.logout}>
          <p className="button-text">Logout</p>
        </button>
      </div>
    );
  }
}

export default withRouter(Navigation);
