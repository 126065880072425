import React from "react";
import "./index.css"; // Import the CSS file

const InputField = ({ placeholder, type, value, id, name, onChange }) => {
  return (
    <div key={id} className="input-field-container">
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        className="input-field"
        onChange={(e) => onChange(name, e.target.value)}
      />
      <input className="disabled-input-field" disabled value={placeholder} />
    </div>
  );
};

export default InputField;
