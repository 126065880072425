import {
  IoMdArrowDropdownCircle,
  IoIosCheckbox,
  IoIosRadioButtonOn,
} from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { MdDateRange, MdNumbers } from "react-icons/md";
import { CiTextAlignLeft } from "react-icons/ci";
import { MdShortText } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BiMaleFemale } from "react-icons/bi";
import { PiSelectionPlusDuotone } from "react-icons/pi";
import { IoIosCloudUpload } from "react-icons/io";
import { FaUpload } from "react-icons/fa6";

import "./index.css";

const Data = {
  contactDetails: {
    title: "Contact Details",
    items: {
      name: {
        id: "name",
        icon: <FaRegUser className="name-icon-color" />,
        title: "Name",
        helpText: "Enter the name",
        label: "Name",
        value: "", // Initial value
        type: "text",
        formtype: "text",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      email: {
        id: "email",
        icon: <MdEmail className="email-icon-color" />,
        title: "Email",
        helpText: "Email input field",
        label: "Email",
        value: "",
        type: "text",
        formtype: "email",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      phoneNumber: {
        id: "phoneNumber",
        icon: <FaPhoneAlt className="phone-number-icon-color" />,
        title: "Phone Number",
        label: "Phone Number",
        value: "",
        helpText: "Phone number input field",
        type: "text",
        formtype: "number",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      gender: {
        id: "gender",
        icon: <BiMaleFemale className="gender-icon-color" />,
        title: "Gender",
        helpText: "Select gender",
        label: "Gender",
        type: "button",
        value: [],
        options: [
          { id: "male", label: "Male", value: "male" },
          { id: "female", label: "Female", value: "female" },
        ],
        formtype: "button",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
    },
  },
  essential: {
    title: "Essential",
    items: {
      date: {
        id: "date",
        icon: <MdDateRange className="date-icon-color" />,
        title: "Date",
        helpText: "Date input field",
        label: "Date",
        value: "", // Initial value
        type: "text",
        formtype: "date",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      shortText: {
        id: "short-text",
        icon: <MdShortText className="short-text-icon-color" />,
        title: "Short Text",
        helpText: "Text paragraph",
        label: "Short Text",
        value: "",
        type: "text-area",
        formtype: "text-area",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      longText: {
        id: "long-text",
        icon: <CiTextAlignLeft className="long-text-icon-color" />,
        title: "Long Text",
        helpText: "Text paragraph",
        label: "Long Text",
        value: "",
        type: "text-area",
        formtype: "text-area",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      select: {
        id: "select",
        icon: <IoMdArrowDropdownCircle className="select-icon-color" />,
        title: "Select",
        helpText: "Select input field",
        label: "Select",
        value: "",
        options: [
          { id: "option-1", label: "Option 1", checked: false },
          { id: "option-2", label: "Option 2", checked: false },
        ],
        type: "select",
        formtype: "select",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      checkbox: {
        id: "checkbox",
        icon: <IoIosCheckbox className="checkbox-icon-color" />,
        title: "Checkbox",
        helpText: "Checkbox input field",
        label: "Checkbox",
        value: [],
        options: [
          { id: "option-1", label: "Option 1", checked: false },
          { id: "option-2", label: "Option 2", checked: false },
        ],
        type: "checkbox",
        formtype: "checkbox",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      radio: {
        id: "radio",
        icon: <IoIosRadioButtonOn className="radio-icon-color" />,
        title: "Radio",
        helpText: "Radio input field",
        label: "Radio",
        value: [],
        options: [
          { id: "option-1", label: "Option 1", checked: false },
          { id: "option-2", label: "Option 2", checked: false },
        ],
        type: "radio",
        formtype: "radio",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      number: {
        id: "number",
        icon: <MdNumbers className="number-icon-color" />,
        title: "Number",
        helpText: "Enter the number",
        label: "Number",
        value: "", // Initial value
        type: "text",
        formtype: "number",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      yesNo: {
        id: "yes-no",
        icon: <PiSelectionPlusDuotone className="yesorno-icon-color" />,
        title: "Yes/No",
        helpText: "Yes or no options",
        label: "Yes/No",
        type: "button",
        value: "",
        options: [
          { id: "yes", label: "Yes", value: "yes" },
          { id: "no", label: "No", value: "no" },
        ],
        formtype: "button",
        required: false,
        mandatory: false,
        recursive: false,
        placeholder: "",
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      imageUpload: {
        id: "imageUpload",
        icon: <IoIosCloudUpload className="imageupload-icon-color" />,
        title: "Image Upload",
        helpText: "Upload image",
        label: "Upload your Image here",
        placeholder: "Drop your Image",
        type: "upload",
        value: "",
        formtype: "image-upload",
        required: false,
        mandatory: false,
        recursive: false,
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
      fileUpload: {
        id: "fileUpload",
        icon: <FaUpload className="file-icon-color" />,
        title: "File Upload",
        helpText: "Upload File",
        label: "Upload your File here",
        placeholder: "Drop your File",
        type: "upload",
        value: "",
        formtype: "file-upload",
        required: false,
        mandatory: false,
        recursive: false,
        api_key: "",
        units: "",
        min_length: "",
        max_length: "",
      },
    },
  },
};

export default Data;
