import React, { useState, useEffect, useRef, useMemo } from "react";
import { DndContext } from "@dnd-kit/core";
import DraggableItem from "./DraggableItem/index.js";
import Data from "./sidebarelements.js";
import "./index.css";
import DroppableArea from "./DroppableArea/index.js";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import SuccessPopup from "../CopdForm/SuccessPop/index.js";
import { v4 as uuidv4 } from "uuid";
import { IoEyeOutline } from "react-icons/io5";
import Minimap from "../Minimap/index.js";
import { IoIosArrowDown } from "react-icons/io";

const generateUniqueId = (prefix) => `${prefix}-${uuidv4()}`;

function FormBuilder() {
  const location = useLocation();
  const history = useHistory();
  const sectionRefs = useRef({});
  const itemRefs = useRef({});

  // const formInfo = location.state || {};
  const formInfo = useMemo(() => location.state || {}, [location.state]);

  const [sections, setSections] = useState([]);
  const [formName, setFormName] = useState("Untitled form");
  const [formDescription, setFormDescription] = useState("Form description");
  const [formNameError, setFormNameError] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [activeSectionId, setActiveSectionId] = useState(null);
  const [isSectionAdded, setIsSectionAdded] = useState(false);
  const [showFieldsPanel, setShowFieldsPanel] = useState(false); // New state
  const [form_Id, setFormID] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [formList, setFormList] = useState([]);
  const [item, setItem] = useState("");
  const [showSetting, setSetting] = useState(false);
  const [scrollableItem, setScrollableItem] = useState("");
  const [itemDetails, setItemDetails] = useState({
    required: item.required,
    mandatory: item.mandatory,
    recursive: item.recursive,
  });

  const minimapRef = useRef(null);
  const mainContentRef = useRef(null);

  useEffect(() => {
    const minimap = minimapRef.current;
    const mainContent = mainContentRef.current;

    if (minimap && mainContent) {
      minimap.addEventListener("scroll", handleMinimapScroll);
      mainContent.addEventListener("scroll", handleMainContentScroll);
    }

    return () => {
      if (minimap && mainContent) {
        minimap.removeEventListener("scroll", handleMinimapScroll);
        mainContent.removeEventListener("scroll", handleMainContentScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (formInfo.formDetails) {
      setFormName(formInfo.formDetails.form_name || "Untitled form");
      setFormDescription(
        formInfo.formDetails.form_description || "Form description"
      );
      setSections(formInfo.formDetails.sections[0].fields || []);
      setFormID(formInfo.formDetails.form_id);
      setIsSectionAdded(true);
      setFormList(formInfo.formList);
    } else if (formInfo.formList) {
      setFormList(formInfo.formList);
      fetchAICVDForm();
    } else if (formInfo.sections) {
      const formList = JSON.parse(localStorage.getItem("formlist"));
      setSections(formInfo.sections);
      setFormName(formInfo.form_title);
      setIsSectionAdded(true);
      setFormList(formList);
    }
  }, [formInfo]);

  const fetchAICVDForm = async () => {
    const formId = "0c8cd918392b11efbd29cc6b1e4948ce";
    const jwt_token = Cookies.get("jwt_token");
    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/get_form_details?form_id=${formId}`; // Use backticks for string interpolation
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const responseObj = await response.json();

      if (responseObj.status === "success") {
        const formDetails = responseObj.data;
        setSections((prevSections) => [
          ...prevSections,
          ...formDetails.sections[0].fields,
        ]);
        setIsSectionAdded(true);
      } else {
        console.error("Error fetching form details:", responseObj.msg);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    sections.forEach((section) => {
      if (!sectionRefs.current[section.id]) {
        sectionRefs.current[section.id] = React.createRef();
      }
    });
  }, [sections]);

  useEffect(() => {
    if (isSectionAdded) {
      const lastSectionId = sections[sections.length - 1]?.id;
      if (lastSectionId) {
        scrollToSection(lastSectionId);
      }
    }
  }, [isSectionAdded, sections]);

  const ensureElementVisibility = (element) => {
    const rect = element.getBoundingClientRect();
    const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
    if (!isVisible) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 3000);
    }
  };

  const handleSectionClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      ensureElementVisibility(element);
    }
  };

  const handleItemClick = (id) => {
    setScrollableItem(id);
  };

  const scrollToSection = (sectionId) => {
    if (sectionRefs.current[sectionId]) {
      sectionRefs.current[sectionId].scrollIntoView({ behavior: "smooth" });
    }
    if (minimapRef.current) {
      minimapRef.current.scrollTo({
        top: minimapRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleMinimapScroll = () => {
    const minimap = minimapRef.current;
    const mainContent = mainContentRef.current;
    if (minimap && mainContent) {
      const scrollRatio =
        minimap.scrollTop / (minimap.scrollHeight - minimap.clientHeight);
      const mainContentScrollTop =
        scrollRatio * (mainContent.scrollHeight - mainContent.clientHeight);
      mainContent.scrollTop = mainContentScrollTop;
    }
  };

  const handleMainContentScroll = () => {
    const minimap = minimapRef.current;
    const mainContent = mainContentRef.current;
    if (minimap && mainContent) {
      const scrollRatio =
        mainContent.scrollTop /
        (mainContent.scrollHeight - mainContent.clientHeight);
      const minimapScrollTop =
        scrollRatio * (minimap.scrollHeight - minimap.clientHeight);
      minimap.scrollTop = minimapScrollTop;
    }
  };

  const handleDrop = (sectionId, e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("text/plain");
    const newItem = JSON.parse(data);
    newItem.id = generateUniqueId(newItem.id.split("-")[0]);
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              items: [
                ...section.items,
                { ...newItem, position: section.items.length },
              ],
            }
          : section
      )
    );
    scrollToSection(sectionId);
  };

  const handleDragClick = (clickedItem) => {
    if (!activeSectionId) return;
    clickedItem.id = generateUniqueId(clickedItem.id.split("-")[0]);
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === activeSectionId
          ? {
              ...section,
              items: [
                ...section.items,
                { ...clickedItem, position: section.items.length },
              ],
            }
          : section
      )
    );
    scrollToSection(activeSectionId);
  };

  const handleDragEnd = () => {
    setActiveSectionId(null);
  };

  const addSection = () => {
    const newSection = {
      id: generateUniqueId("section"),
      name: "New Section",
      items: [],
    };
    setIsSectionAdded(true);
    setActiveSectionId(newSection.id);
    setSections((prevSections) => {
      return [...prevSections, newSection];
    });
    scrollToSection(newSection.id);
  };

  const removeSection = (sectionId) => {
    setSections((prevSections) =>
      prevSections.filter((el) => el.id !== sectionId)
    );
  };

  const cloneForm = async (event) => {
    const selectedOption = event.target.selectedOptions[0];
    const formId = selectedOption.id;
    const jwt_token = Cookies.get("jwt_token");
    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/get_form_details?form_id=${formId}`; // Use backticks for string interpolation
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const responseObj = await response.json();

      if (responseObj.status === "success") {
        const formDetails = responseObj.data;
        setSections((prevSections) => [
          ...prevSections,
          ...formDetails.sections[0].fields,
        ]);
        setIsSectionAdded(true);
      } else {
        console.error("Error fetching form details:", responseObj.msg);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const cloneSection = (sectionId) => {
    const sectionToClone = sections.find((section) => section.id === sectionId);
    if (!sectionToClone) return;

    const clonedSection = {
      ...sectionToClone,
      id: generateUniqueId("section"),
      items: sectionToClone.items.map((item) => ({
        ...item,
        id: generateUniqueId(item.id.split("-")[0]),
      })),
    };

    setSections((prevSections) => {
      const index = prevSections.findIndex(
        (section) => section.id === sectionId
      );
      return [
        ...prevSections.slice(0, index + 1),
        clonedSection,
        ...prevSections.slice(index + 1),
      ];
    });
  };

  const handleSectionNameChange = (sectionId, newName) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId ? { ...section, name: newName } : section
      )
    );
  };

  function getFormattedDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const saveFormApp = async () => {
    if (!formName) {
      setFormNameError(true);
      return;
    }
    const totalItemCount = sections.reduce(
      (count, section) => count + section.items.length,
      0
    );
    const formData = {
      form_id: form_Id,
      form_name: formName,
      form_description: formDescription,
      totalItemCount: totalItemCount,
      sections: sections.map((section) => ({
        name: section.name,
        id: section.id,
        removeStatus: section.removeStatus,
        items: section.items.map((item) => ({
          id: item.id,
          label: item.label,
          options: item.options || [],
          required: item.required,
          formtype: item.formtype,
          position: item.position,
          mandatory: item.mandatory,
          recursive: item.recursive,
          type: item.type,
          api_key: item.api_key,
          units: item.units,
          placeholder: item.placeholder,
          min_length: item.min_length,
          max_length: item.max_length,
          removeStatus: item.removeStatus,
          title: item.title,
        })),
      })),
      created_date: getFormattedDate(),
    };

    const url =
      "https://apollo-totalhealth-camp-ai.azurewebsites.net/create_form_fields";
    const jwtToken = Cookies.get("jwt_token");

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${jwtToken}`);

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(formData),
      redirect: "follow",
    };

    try {
      const response = await fetch(url, options);
      const responseObj = await response.json();

      if (responseObj.status === "success") {
        setShowSuccessPopup(true);
        history.push("/form-list");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleDragStart = (sectionId) => {
    setActiveSectionId(sectionId);
  };

  const toggleFieldsPanel = () => {
    setShowFieldsPanel(!showFieldsPanel);
  };

  const sectionOnClick = (sectionId) => {
    setActiveSectionId(sectionId);
  };

  const pushto_view_forom = () => {
    try {
      const state = {
        sections: JSON.parse(JSON.stringify(sections)),
        form_title: formName,
      };

      history.push({
        pathname: "/form-fields",
        state: state,
      });
    } catch (error) {
      console.error("Error serializing state:", error);
    }
  };

  const handleRequiredChange = () => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      required: !prevDetails.required,
    }));
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((el) =>
          el.id === item.id ? { ...el, required: !el.required } : el
        ),
      }))
    );
  };

  const handleMandotoryChange = () => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      mandatory: !prevDetails.mandatory,
    }));
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((el) =>
          el.id === item.id ? { ...el, mandatory: !el.mandatory } : el
        ),
      }))
    );
  };

  const handleRecursiveChange = () => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      recursive: !prevDetails.recursive,
    }));
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((el) =>
          el.id === item.id ? { ...el, recursive: !el.recursive } : el
        ),
      }))
    );
  };

  const setShowSetting = (value) => {
    setSetting(value);
  };

  const onClose = () => {
    setSetting(false);
  };

  const handleItemSetting = (item) => {
    setItem(item);

    console.log(item);
    setItemDetails({
      required: item.required,
      mandatory: item.mandatory,
      recursive: item.recursive,
      api_key: item.api_key,
      units: item.units,
      min_length: item.min_length,
      max_length: item.max_length,
      placeholder: item.placeholder,
    });
  };

  const updateAPIkey = (event) => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      api_key: event.target.value,
    }));
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((el) =>
          el.id === item.id ? { ...el, api_key: event.target.value } : el
        ),
      }))
    );
  };

  const updateUnits = (event) => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      units: event.target.value,
    }));
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((el) =>
          el.id === item.id ? { ...el, units: event.target.value } : el
        ),
      }))
    );
  };

  const updateMinLength = (event) => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      min_length: event.target.value,
    }));
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((el) =>
          el.id === item.id ? { ...el, min_length: event.target.value } : el
        ),
      }))
    );
  };

  const updateMaxLength = (event) => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      max_length: event.target.value,
    }));
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((el) =>
          el.id === item.id ? { ...el, max_length: event.target.value } : el
        ),
      }))
    );
  };

  const updatePlaceholder = (event) => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      placeholder: event.target.value,
    }));
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((el) =>
          el.id === item.id ? { ...el, placeholder: event.target.value } : el
        ),
      }))
    );
  };
  return (
    <div className="form-builder">
      <div className="header">
        <div>
          <h1 className="headline-text">Form Builder</h1>
        </div>
        <div className="create-button-container">
          <div className="preview-section">
            <button className="preview-button" onClick={pushto_view_forom}>
              Preview
            </button>
            <IoEyeOutline className="dropdown-icon" />
          </div>
          <div className="select-clone-container">
            <select onChange={cloneForm} className="select-tag">
              <option value="" disabled>
                Clone
              </option>{" "}
              {formList.map((eachform) => (
                <option
                  key={eachform.form_id}
                  id={eachform.form_id}
                  value={eachform.form_name}
                  className="select-clone-option"
                >
                  {eachform.form_name}
                </option>
              ))}
            </select>
            <IoIosArrowDown className="dropdown-icon" />
          </div>

          <button className="add_section_button" onClick={addSection}>
            Add Section
          </button>
          <button className="create_form_button" onClick={saveFormApp}>
            <span>Save</span>
          </button>
        </div>
      </div>
      <DndContext onDragEnd={(event) => handleDragEnd(event)}>
        <div className="dnd-container">
          <div className="form-builder-right">
            <Minimap
              sections={sections}
              onSectionClick={handleSectionClick}
              onItemClick={handleItemClick}
              ref={minimapRef}
              onScroll={handleMinimapScroll}
            />
          </div>
          <div
            className="left-side-bar"
            ref={mainContentRef}
            onScroll={handleMainContentScroll}
          >
            <div className="form-details">
              <div className="form-input-bar">
                {isEditingName ? (
                  <input
                    type="text"
                    value={formName}
                    onChange={(e) => setFormName(e.target.value)}
                    onBlur={() => setIsEditingName(false)}
                    autoFocus
                    className={`form-input ${formNameError ? "error" : ""}`}
                  />
                ) : (
                  <h1
                    className="form-name-title"
                    onClick={() => setIsEditingName(true)}
                  >
                    {formName}
                  </h1>
                )}
              </div>
              {formNameError && (
                <div className="error-message">Form Name is required.</div>
              )}
              <div className="form-input-bar">
                {isEditingDescription ? (
                  <textarea
                    value={formDescription}
                    onChange={(e) => setFormDescription(e.target.value)}
                    onBlur={() => setIsEditingDescription(false)}
                    autoFocus
                    className="form-textarea"
                  />
                ) : (
                  <h2
                    className="form-description-title"
                    onClick={() => setIsEditingDescription(true)}
                  >
                    {formDescription}
                  </h2>
                )}
              </div>
            </div>
            {sections.map((section) => (
              <div
                key={section.id}
                className="section"
                onDrop={(e) => handleDrop(section.id, e)}
                onDragOver={(e) => e.preventDefault()}
                onDragStart={() => handleDragStart(section.id)}
                onClick={() => sectionOnClick(section.id)}
                id={section.id}
                ref={(el) => (sectionRefs.current[section.id] = el)}
              >
                <div className="section-related-container">
                  <input
                    type="text"
                    value={section.name}
                    // value={`${section.name} + ${section.id}`}
                    onChange={(e) =>
                      handleSectionNameChange(section.id, e.target.value)
                    }
                    className="section-title"
                  />
                  <div className="button-container">
                    <button
                      className="clone-button"
                      onClick={() => cloneSection(section.id)}
                    >
                      Clone
                    </button>
                    {!section.removeStatus && (
                      <button
                        className="remove-button"
                        onClick={() => removeSection(section.id)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
                <DroppableArea
                  items={section.items}
                  setSections={setSections}
                  sectionId={section.id}
                  handleItemSetting={handleItemSetting}
                  setShowSetting={setShowSetting}
                  itemRefs={itemRefs}
                  scrollableItem={scrollableItem}
                />
              </div>
            ))}
          </div>
          <div className={`right-side-bar ${showFieldsPanel ? "active" : ""}`}>
            <div className="form-builder-btn" onClick={toggleFieldsPanel}>
              <button className="cross-button">X</button>
            </div>
            <div className="each-form-element">
              {showSetting ? (
                <div class="field-settings">
                  <div className="field-setting-tittle">
                    <h1 class="title">Field Settings</h1>
                    <button id="close-button" onClick={onClose}>
                      &times;
                    </button>
                  </div>
                  <div class="field">
                    <label for="short-text" class="field-setting-label">
                      {item.label}
                    </label>
                  </div>
                  <div class="toggle">
                    <label for="required">Required</label>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="required"
                        checked={itemDetails.required}
                        onChange={handleRequiredChange}
                      />
                      <span class="slider"></span>
                    </label>
                  </div>
                  <div class="toggle">
                    <label for="required">Mandatory</label>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="required"
                        checked={itemDetails.mandatory}
                        onChange={handleMandotoryChange}
                      />
                      <span class="slider"></span>
                    </label>
                  </div>
                  <div class="toggle">
                    <label for="required">Recursive</label>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="required"
                        checked={itemDetails.recursive}
                        onChange={handleRecursiveChange}
                      />
                      <span class="slider"></span>
                    </label>
                  </div>

                  <div class="field placeholder">
                    <label for="placeholder">Placeholder</label>
                    <input
                      type="text"
                      value={itemDetails.placeholder}
                      onChange={updatePlaceholder}
                    />
                  </div>

                  <div class="field placeholder">
                    <label for="placeholder">Api Key</label>
                    <input
                      type="text"
                      value={itemDetails.api_key}
                      onChange={updateAPIkey}
                    />
                  </div>

                  <div class="field placeholder">
                    <label for="placeholder">Units</label>
                    <input
                      type="text"
                      value={itemDetails.units}
                      onChange={updateUnits}
                    />
                  </div>

                  <div class="field placeholder">
                    <label for="min-length">Minumum length</label>
                    <input
                      type="text"
                      value={itemDetails.min_length}
                      onChange={updateMinLength}
                    />
                  </div>

                  <div class="field placeholder">
                    <label for="max-length">Maximum length</label>
                    <input
                      type="text"
                      value={itemDetails.max_length}
                      onChange={updateMaxLength}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {Object.entries(Data).map(([categoryKey, category]) => (
                    <div key={categoryKey} className="category">
                      <h2 className="category-title">{category.title}</h2>
                      <div className="category-items">
                        {Object.values(category.items).map((item) => (
                          <DraggableItem
                            key={item.id}
                            item={item}
                            id={item.id}
                            onClick={() => handleDragClick(item)}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </DndContext>
      <button
        className={`form-builder-btn-plus ${showFieldsPanel ? "active" : ""}`}
        onClick={toggleFieldsPanel}
      >
        +
      </button>
      {!isSectionAdded && (
        <div id="tooltip" className="tooltip show-tooltip">
          Click 'Add Section' to start building your form
        </div>
      )}
      {showSuccessPopup && <SuccessPopup />}
    </div>
  );
}

export default withRouter(FormBuilder);
