import { Route } from "react-router-dom";
import { Redirect } from 'react-router';
import Cookies from 'js-cookie';

const ProtectRouter = (props) => {
    const token = Cookies.get('jwt_token');
    if (token === undefined){
        return <Redirect to="/login" />;
    }
    return <Route {...props} />;
}

export default ProtectRouter;