import React, { forwardRef } from "react";
import "./index.css";

const Minimap = forwardRef(({ sections, onSectionClick, onItemClick }, ref) => {
  return (
    <div className="minimap-container" ref={ref}>
      <h4>Form Minimap</h4>
      <ul className="minimap-list">
        {sections.map((section) => (
          <li key={section.id} onClick={() => onSectionClick(section.id)}>
            {section.name}
            <ul>
              {section.items.map((item) => (
                <li
                  key={item.id}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the section click
                    onItemClick(item.id);
                  }}
                >
                  {item.label || item.type}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
});

export default Minimap;
