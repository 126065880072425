import Cookies from "js-cookie";
import React from "react";
import { withRouter } from "react-router";
import ExportCSV from "../ExportCSV";
import { Link } from "react-router-dom";

class CampsList extends React.Component {
  state = {
    mainCampList: [],
    campList: [],
    searchByValue: "Name",
    starts_from: 0,
    camps_list_complete_status: false,
    loaderStatus: true,
  };

  campListApiCall = async () => {
    const jwt_token = Cookies.get("jwt_token");

    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/camps-list?starts_from=${this.state.starts_from}`;
    // const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/camps-list?starts_from=${this.state.starts_from}`;
    let myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      let campList = responseObj.camps_list.map((each) => ({
        campId: each.camp_id,
        campCode: each.camp_code,
        city: each.city,
        hospital: each.hospital,
        location: each.location,
        campTag: each.camp_tag,
        createdDate: this.returnDate(each.created_date),
        endDate: this.returnDate(each.end_date),
        executives: each.executives,
        doctors: each.doctors,
        patinentCount: each.patient_count,
        campCategory: each.campCategory,
        formId: each.form_id,
      }));
      let currentCampList = this.state.mainCampList;
      currentCampList.push(...campList);
      let totalCampListCount = this.state.starts_from;

      this.setState({
        campList: currentCampList,
        mainCampList: currentCampList,
        camps_list_complete_status: responseObj.camps_list_complete_status,
        starts_from: totalCampListCount + campList.length,
        loaderStatus: false,
      });
    }
  };

  componentDidMount = async () => {
    this.campListApiCall();
  };

  returnDate = (date) => {
    const dateFormat = new Date(date);
    return `${dateFormat.getDate()}-${
      dateFormat.getMonth() + 1
    }-${dateFormat.getFullYear()}`;
  };

  pushPatientList = (event) => {
    const campId = event.target.value;
    const formId = event.target.id;
    const { history } = this.props;

    const particularCamp = this.state.campList.filter(
      (each) => each.campId === campId
    );

    localStorage.setItem("camp_data", JSON.stringify(particularCamp[0]));

    Cookies.set("global_camp_id", campId, { expires: 1, path: "/" });
    Cookies.set("global_form_id", formId, { expires: 1, path: "/" });
    history.push({
      pathname: "/participant-list",
      state: formId,
    });
  };

  pushToDashboard = () => {
    const { history } = this.props;
    history.push("/dashboard");
  };

  pushToCreateCamp = () => {
    const { history } = this.props;

    history.push("/create-camp");
  };

  filterUserList = (event) => {
    const userEnterWord = event.target.value.toLowerCase();
    const { searchByValue } = this.state;

    let filterList;

    if (searchByValue === "Name") {
      filterList = this.state.mainCampList.filter((each) =>
        each.campTag.toLowerCase().includes(userEnterWord)
      );
    } else if (searchByValue === "Camp Code") {
      filterList = this.state.mainCampList.filter((each) =>
        each.campCode.toLowerCase().includes(userEnterWord)
      );
    } else if (searchByValue === "Start Date") {
      filterList = this.state.mainCampList.filter((each) =>
        each.createdDate.toLowerCase().includes(userEnterWord)
      );
    } else if (searchByValue === "Category") {
      filterList = this.state.mainCampList.filter((each) => {
        return (
          each.campCategory != null &&
          each.campCategory.toLowerCase().includes(userEnterWord.toLowerCase())
        );
      });
    } else {
      filterList = this.state.mainCampList.filter((each) =>
        each.executives
          .map((each) => each.toLowerCase().includes(userEnterWord))
          .includes(true)
      );
    }

    this.setState({
      campList: filterList,
    });
  };

  logoutButtonClicked = () => {
    const { history } = this.props;
    history.replace("/login");
    Cookies.remove("jwt_token");
  };

  renderHoverMenu = () => {
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };
    const jwt_token = Cookies.get("jwt_token");

    const parseJwtObj = parseJwt(jwt_token);
    const userName = parseJwtObj.name;
    const role = parseJwtObj.uRole;

    return (
      <div id="hoverMenu" className="hover-menu-container">
        <div className="back-button-container">
          <button
            className="button back-button"
            type="button"
            onClick={this.closeMenu}
          >
            <img src="./back-arrow.svg" alt="back-arrow.svg" />
          </button>
          <p className="button-text">Back</p>
        </div>
        <Link className="link-tag" to="/camps-list">
          <div className="nav-option-container">
            <p className="nav-option-text">Home</p>
            <img
              className="nav-option-icon"
              src="./home-outline.svg"
              alt="clipboard-2.svg"
            />
          </div>
        </Link>
        <div className="user-details-container">
          <div className="user-image-cirlce">
            <img
              className="icon"
              src="person-outline.svg"
              alt="person-outline"
            />
          </div>
          <div className="user-data">
            <p className="user-name">{userName}</p>
            <p className="user-role">{role}</p>
          </div>
        </div>
        <button
          className="button logout-button maring-top-auto"
          onClick={this.logoutButtonClicked}
        >
          <p className="button-text">Logout</p>
          <img
            className="nav-option-icon"
            src="./log-out-outline.svg"
            alt="logoout.svg"
          />
        </button>
      </div>
    );
  };

  openMenu = () => {
    const element = document.getElementById("hoverMenu");
    element.style.transform = "translateX(0%)";
    element.style.boxShadow = "8px 0px 10px rgba(0, 0, 0, 0.19)";
  };

  closeMenu = () => {
    const element = document.getElementById("hoverMenu");
    element.style.transform = "translateX(-100%)";
    element.style.boxShadow = "none";
  };

  renderUsersCard = (data, index) => {
    const jwt_token = Cookies.get("jwt_token");
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };

    const parseJwtObj = parseJwt(jwt_token);
    const role = parseJwtObj.uRole;

    return (
      <div id="card" className="details-card-container" key={index}>
        <div className="d-flex flex-row justify-content-between align-items-center card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Camp Code</p>
            <p className="content highlight-camp-content">{data.campCode}</p>
          </div>
          <div className="title-with-content-container">
            <p className="title">No Of Participants</p>
            <p className="content ">{data.patinentCount}</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Created Date</p>
            <p className="content">{data.createdDate}</p>
          </div>
          <div className="title-with-content-container">
            <p className="title">End Date</p>
            <p className="content ">{data.endDate}</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Camp Name</p>
            <p className="content overflow-x-auto">{data.campTag}</p>
          </div>
          {data.campCategory !== undefined && (
            <div className="title-with-content-container">
              <p className="title">Camp Category</p>
              <p className="content overflow-x-auto">{data.campCategory}</p>
            </div>
          )}
        </div>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container executive-style">
            <p className="title">Executive</p>
            <ul className="camp-card-list-container">
              {data.executives.map((each, key) => (
                <li className="content" key={key}>
                  {each}
                </li>
              ))}
            </ul>
          </div>
          <div className="title-with-content-container doctor-style">
            <p className="title">Doctor</p>
            {data.doctors.length === 0 ? (
              <p className="without-doctor-text">
                This camp is running without doctor
              </p>
            ) : (
              <ul className="camp-card-list-container">
                {data.doctors.map((each, key) => (
                  <li className="content" key={key}>
                    {each}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="title-with-content-container width-50">
            <p className="title">Camp Location</p>
            <p className="content highlight-camp-content">{data.location}</p>
          </div>
          {role !== "doctor" && <ExportCSV campId={data.campId} />}
          {role !== "admin" && (
            <button
              type="button"
              className="button camp-view-button"
              value={data.campId}
              id={data.formId}
              onClick={this.pushPatientList}
            >
              View
            </button>
          )}
        </div>
      </div>
    );
  };

  updateSearchBy = (event) => {
    const value = event.target.value;
    this.setState({ searchByValue: value });
  };

  render() {
    const { campList, camps_list_complete_status, loaderStatus } = this.state;
    const jwt_token = Cookies.get("jwt_token");
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };

    const parseJwtObj = parseJwt(jwt_token);
    const userName = parseJwtObj.name;
    const role = parseJwtObj.uRole;

    return (
      <div className="user-list-container">
        <div className="d-flex flex-row align-items-center justify-content-between">
          {role === "admin" ? (
            <>
              <div className="menu-icon-with-apollo-logo">
                <button
                  className="button back-button"
                  onClick={this.pushToDashboard}
                >
                  <img src="./back-arrow.svg" alt="back-arrow.svg" />
                </button>
                <img
                  className="small-apollo-logo"
                  src="./logo.png"
                  alt="apollo.png"
                />
              </div>
              <div className="large-back-button-container">
                <button
                  className="button back-button"
                  onClick={this.pushToDashboard}
                >
                  <img src="./back-arrow.svg" alt="back-arrow.svg" />
                </button>
                <p className="large-back-button-text">Back</p>
              </div>
            </>
          ) : (
            <div className="menu-icon-with-apollo-logo">
              <div className="menu-container" onClick={this.openMenu}>
                <span className="box"></span>
                <span className="box"></span>
                <span className="box"></span>
                <span className="box"></span>
              </div>
              <img
                className="small-apollo-logo"
                src="./logo.png"
                alt="apollo.png"
              />
            </div>
          )}
          {this.renderHoverMenu()}
        </div>
        <div className="sub-heading-with-button-container">
          <div className="sub-heading-with-logo">
            <h1 className="sub-heading">Camps List</h1>
            <img
              className="sub-heading-icon"
              src="./layers-outline.svg"
              alt="list.svg"
            />
          </div>
          {role === "admin" && (
            <button
              className="button add-button"
              onClick={this.pushToCreateCamp}
            >
              Create Camp
            </button>
          )}
          <div className="userinfo-app-title-conainer">
            <div className="large-userinfo-container">
              <div className="large-userinfo">
                <p className="larg-user-name">{userName}</p>
                <p className="large-user-role">{role}</p>
              </div>
              <div className="user-image-cirlce">
                <img
                  className="icon"
                  src="person-outline.svg"
                  alt="person-outline"
                />
              </div>
            </div>
            <div className="camp-heading">
              <p className="apollo-hospitals-text">Apollo Hospitals</p>
              <h1 className="heading text-bold">Health Camps</h1>
              <div className="heading-style-element-container">
                <div className="style-line"></div>
                <div className="style-dot"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-container max-width-400 margin-none search-with-select">
          <div className="input-with-logo">
            <input
              type="text"
              className="login-input"
              placeholder="Search by Name"
              name="search"
              onChange={this.filterUserList}
            />
            <img className="icon" src="./search.svg" alt="apollo.png" />
          </div>
          <select className="select-bar" onChange={this.updateSearchBy}>
            <option>Name</option>
            <option>Camp Code</option>
            <option>Start Date</option>
            <option>Executive</option>
            <option>Category</option>
          </select>
        </div>
        <div className="details-cards-container">
          {loaderStatus ? (
            <div className="main-loader-container">
              <div className="loader-spinner"></div>
              <p>Loading...!</p>
            </div>
          ) : (
            <>
              {campList.length > 0 ? (
                campList.map((each, index) => this.renderUsersCard(each, index))
              ) : (
                <div className="d-flex flex-row justify-content-center">
                  <p className="not-found-text">Oops..! Camps Not Found</p>
                  <img
                    className="sub-heading-icon color-gray"
                    src="./sad-outline.svg"
                    alt="sad.svg"
                  />
                </div>
              )}
            </>
          )}
          {camps_list_complete_status ? (
            <div className="show-more-button-container">
              <button
                className="button show-more"
                onClick={this.campListApiCall}
              >
                View More
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(CampsList);
