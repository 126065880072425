import React from "react";
import { withRouter } from "react-router";
import Cookies from "js-cookie";
import Loader from "react-loader-spinner";

const roles_list = ["doctor", "executive", "admin"];
class CreateNewUser extends React.Component {
  state = {
    role: "",
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    location: "",

    popupStatus: "",

    fullNameIsEmpty: false,
    emailIsEmpty: false,
    passwordIsEmpty: false,
    confirmPasswordIsEmpty: false,
    phoneIsEmpty: false,
    locationIsEmpty: false,
    isAnyNotFilledInput: false,
    mainErrorText: "",
    createUserLoader: true,
    InValidphoneNumber: false,
  };

  // componentDidMount = async () => {
  //     await this.fetchRolesFromDataBase();
  // }

  // fetchRolesFromDataBase = async () => {
  //     const url = 'https://apollo-totalhealth-camp-ai.azurewebsites.net/role-list';

  //     const jwt_token = Cookies.get('jwt_token');

  //     let myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     myHeaders.append("Authorization", `Bearer ${jwt_token}`)

  //     let requestOptions = {
  //         method: 'GET',
  //         headers: myHeaders,
  //         redirect: 'follow'
  //     };

  //     const response = await fetch(url, requestOptions);
  //     const responseObj = await response.json()

  //     if (responseObj.status === 'success') {
  //         const roleList = responseObj.roles_list.map(each => ({roleId: each.id, role: each.role}));
  //         this.setState({roleList: roleList});
  //     }
  // }

  getTodayDate = () => {
    const fullDate = new Date();
    return `${fullDate.getFullYear()}-${
      fullDate.getMonth() + 1
    }-${fullDate.getDate()}`;
  };

  removeCreateUserPopup = () => {
    const element = document.getElementById("createUserPopup");
    const body = document.querySelector("body");

    element.classList.add("d-none");
    body.style.overflow = "scroll";

    const { history } = this.props;
    history.push("/user-list");
  };

  createUserApiCall = async (user) => {
    const popEle = document.getElementById("createUserPopup");
    popEle.classList.remove("d-none");
    const body = document.querySelector("body");
    body.style.overflow = "hidden";

    const jwt_token = Cookies.get("jwt_token");

    const url =
      "https://apollo-totalhealth-camp-ai.azurewebsites.net/create-user";
    let raw = JSON.stringify({
      name: user.fullName,
      email: user.email.toLowerCase(),
      password: user.password,
      role: user.role,
      mobile: user.phone,
      location: user.location,
      created_dtm: this.getTodayDate(),
      created_by: user.userId,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      this.setState({
        createUserLoader: false,
        popupStatus: "success",
      });
    } else {
      this.setState({
        createUserLoader: false,
        popupStatus: "error",
      });
    }
  };

  createUserValidation = (event) => {
    event.preventDefault();

    const jwt_token = Cookies.get("jwt_token");

    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        console.log(e.message);
        return null;
      }
    };

    const parseJwtObj = parseJwt(jwt_token);
    const userId = parseJwtObj.user_id;

    const newUser = {
      fullName: this.state.fullName,
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      phone: this.state.phone,
      location: this.state.location,
      role: this.state.role,
      userId: userId,
    };

    if (
      newUser.fullName !== "" &&
      newUser.email !== "" &&
      newUser.password !== "" &&
      newUser.confirmPassword !== "" &&
      newUser.phone !== "" &&
      newUser.location !== "" &&
      newUser.role !== ""
    ) {
      if (newUser.password !== newUser.confirmPassword) {
        this.setState({
          isAnyNotFilledInput: true,
          mainErrorText: "Password did not Matched..! Try Agian",
        });
      } else {
        this.createUserApiCall(newUser);
        this.setState({ isAnyNotFilledInput: false });
      }
    } else {
      this.setState({
        isAnyNotFilledInput: true,
        mainErrorText: "Please fill the inputs properly",
      });
    }
  };

  phoneNumberVaidation = (mobileNumber) => {
    var expr = /^(0|91)?[6-9][0-9]{9}$/;
    return expr.test(mobileNumber);
  };

  isInputEmpty = (event) => {
    const value = event.target.value;
    const elementName = event.target.name;

    if (value === "") {
      if (elementName === "full-name") {
        this.setState({ fullNameIsEmpty: true });
      } else if (elementName === "email") {
        this.setState({ emailIsEmpty: true });
      } else if (elementName === "password") {
        this.setState({ passwordIsEmpty: true });
      } else if (elementName === "confirm-password") {
        this.setState({ confirmPasswordIsEmpty: true });
      } else if (elementName === "location") {
        this.setState({ locationIsEmpty: true });
      } else {
        this.setState({ phoneIsEmpty: true });
      }
    } else {
      if (elementName === "phone") {
        const isValidNumber = this.phoneNumberVaidation(value);
        if (!isValidNumber) event.target.value = "";
        this.setState({
          InValidphoneNumber: !isValidNumber,
        });
      }
    }
  };

  updateInput = (event) => {
    const value = event.target.value;
    const elementName = event.target.name;

    if (elementName === "full-name") {
      this.setState({ fullName: value });
    } else if (elementName === "email") {
      this.setState({ email: value });
    } else if (elementName === "password") {
      this.setState({ password: value });
    } else if (elementName === "confirm-password") {
      this.setState({ confirmPassword: value });
    } else if (elementName === "location") {
      this.setState({ location: value });
    } else {
      this.setState({ phone: value });
    }
  };

  removeError = (event) => {
    const elementName = event.target.name;
    if (elementName === "full-name") {
      this.setState({ fullNameIsEmpty: false });
    } else if (elementName === "email") {
      this.setState({ emailIsEmpty: false });
    } else if (elementName === "password") {
      this.setState({ passwordIsEmpty: false });
    } else if (elementName === "confirm-password") {
      this.setState({ confirmPasswordIsEmpty: false });
    } else if (elementName === "location") {
      this.setState({ locationIsEmpty: false });
    } else {
      this.setState({ phoneIsEmpty: false, InValidphoneNumber: false });
    }
  };

  updateRoleListStatus = () => {
    const element = document.getElementById("roleDropDown");
    const arrow = document.getElementById("roleIcon");

    if (element.style.display === "block") {
      element.style.display = "none";
      arrow.style.transform = "rotate(0deg)";
    } else {
      element.style.display = "block";
      arrow.style.transform = "rotate(540deg)";
    }
  };

  updateRole = (event) => {
    const value = event.target.value;

    const element = document.getElementById("roleDropDown");
    const arrow = document.getElementById("roleIcon");

    element.style.display = "none";
    arrow.style.transform = "rotate(0deg)";

    this.setState({
      role: value,
    });
  };

  pushToUserList = () => {
    const { history } = this.props;
    history.replace("/user-list");
  };

  render() {
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };
    const jwt_token = Cookies.get("jwt_token");
    const parseJwtObj = parseJwt(jwt_token);

    if (parseJwtObj.uRole !== "admin") {
      const { history } = this.props;
      history.goBack();
      return null;
    }

    const {
      fullNameIsEmpty,
      emailIsEmpty,
      passwordIsEmpty,
      confirmPasswordIsEmpty,
      phoneIsEmpty,
      isAnyNotFilledInput,
      mainErrorText,
      role,
      createUserLoader,
      locationIsEmpty,
      popupStatus,
      InValidphoneNumber,
    } = this.state;
    return (
      <form
        id="createUserForm"
        className="user-create-page-container"
        onSubmit={this.createUserValidation}
      >
        <div id="createUserPopup" className="pop-up-container d-none">
          <div className="pop-up-box">
            {createUserLoader === true ? (
              <Loader type="Oval" color="#FF883E" height={50} width={50} />
            ) : popupStatus === "success" ? (
              <div className="success-container">
                <div className="tickmark-container">
                  <img
                    className="tickmark-icon"
                    src="./checkmark-outline.svg"
                    alt="tickmark.png"
                  />
                </div>
                <p className="success-text">User Created Successfully</p>
                <button
                  className="button done-button"
                  onClick={this.removeCreateUserPopup}
                >
                  Done
                </button>
              </div>
            ) : (
              <div className="success-container">
                <div className="close-container">
                  <img
                    className="tickmark-icon"
                    src="./close-white-outline.svg"
                    alt="tickmark.png"
                  />
                </div>
                <p className="success-text">User already exists</p>
                <button
                  className="button done-button"
                  onClick={this.removeCreateUserPopup}
                >
                  Back
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="menu-icon-with-apollo-logo">
          <button className="button back-button" onClick={this.pushToUserList}>
            <img src="./back-arrow.svg" alt="back-arrow.svg" />
          </button>
          <img
            className="small-apollo-logo"
            src="./logo.png"
            alt="apollo.png"
          />
        </div>
        <div className="large-back-button-container">
          <button className="button back-button" onClick={this.pushToUserList}>
            <img src="./back-arrow.svg" alt="back-arrow.svg" />
          </button>
          <p className="large-back-button-text">Back</p>
        </div>
        <div className="heading-with-icon-container">
          <h1 className="heading">User Details</h1>
          <img
            className="heading-icon"
            src="./create-outline.svg"
            alt="clipboard-2.svg"
          />
        </div>
        <div className=".large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Full Name
            </label>
            <div className="input-with-logo">
              <input
                type="text"
                className="login-input"
                placeholder="John Doe"
                name="full-name"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
              <img
                className="icon"
                src="./person-outline.svg"
                alt="apollo.png"
              />
            </div>
            {fullNameIsEmpty && (
              <p className="error-text">Please enter the Full Name</p>
            )}
          </div>
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Email
            </label>
            <div className="input-with-logo">
              <input
                type="text"
                className="login-input"
                placeholder="johndoe@apollohospitals.com"
                name="email"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
              <img className="icon" src="./at-outline.svg" alt="apollo.png" />
            </div>
            {emailIsEmpty && (
              <p className="error-text">Please enter the Email</p>
            )}
          </div>
        </div>
        <div className=".large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Password
            </label>
            <div className="input-with-logo">
              <input
                type="password"
                className="login-input"
                placeholder="*********"
                name="password"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
              <img className="icon" src="./key-outline.svg" alt="apollo.png" />
            </div>
            {passwordIsEmpty && (
              <p className="error-text">Please enter the Password</p>
            )}
          </div>
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Confirm Password
            </label>
            <div className="input-with-logo">
              <input
                type="password"
                className="login-input"
                placeholder="*********"
                name="confirm-password"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
              <img className="icon" src="./key-outline.svg" alt="apollo.png" />
            </div>
            {confirmPasswordIsEmpty && (
              <p className="error-text">Please enter the Confirm Password</p>
            )}
          </div>
        </div>
        <div className=".large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Phone
            </label>
            <div className="input-with-logo">
              <input
                type="number"
                className="login-input no-increment"
                placeholder="9999988888"
                name="phone"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
              <img className="icon" src="./call-outline.svg" alt="apollo.png" />
            </div>
            {phoneIsEmpty && (
              <p className="error-text">Please enter the Phone number</p>
            )}
            {InValidphoneNumber && (
              <p className="error-text">Please enter the valid Phone number</p>
            )}
          </div>
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Location
            </label>
            <div className="input-with-logo">
              <input
                type="text"
                className="login-input"
                name="location"
                placeholder="Hyderabad"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
            </div>
            {locationIsEmpty && (
              <p className="error-text">Please enter the Location</p>
            )}
          </div>
        </div>
        <div className=".large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850 margin-bottom-30">
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Role
            </label>
            <div
              className="input-with-logo cursor-pointer"
              onClick={this.updateRoleListStatus}
            >
              <input
                type="text"
                className="login-input pointer-event-none text-capitalize"
                name="role"
                placeholder="Select"
                value={role}
                readOnly
              />
              <img
                id="roleIcon"
                className="icon"
                src="./chevron-down-outline.svg"
                alt="apollo.png"
              />
            </div>
            <ul id="roleDropDown" className="drop-down-option-container">
              {roles_list.map((each, index) => (
                <li className="drop-down-option" key={index}>
                  <button
                    className="drop-down-option-button"
                    type="button"
                    onClick={this.updateRole}
                    value={each}
                  >
                    {each}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {isAnyNotFilledInput && (
          <p id="mainError" className="error-text text-center pos-relative">
            {mainErrorText}
          </p>
        )}
        <button type="submit" className="button create-button">
          Create user
        </button>
        <div className="end-line"></div>
        <p className="copy-rights-text">
          <span className="font-weight-700">
            Copyright @{new Date().getFullYear()} Powered By Apollo Hospitals.
          </span>
          <br /> All rights reserved.
        </p>
      </form>
    );
  }
}

export default withRouter(CreateNewUser);
