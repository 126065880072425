import React from "react";

const ImageUpload = ({ id, onChange, value }) => {
  const handleImageChange = (event) => {
    const image = event.target.files[0];
    onChange(id, image);
  };
  return (
    <div>
      <label htmlFor={`image-upload-${id}`}>Upload Image</label>
      <input
        type="file"
        id={`image-upload-${id}`}
        accept="image/*"
        onChange={handleImageChange}
        src={value}
      />
    </div>
  );
};

export default ImageUpload;
