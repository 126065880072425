import React from "react";

const FileUpload = ({ id, onChange, value }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onChange(id, file);
  };
  return (
    <div>
      <label htmlFor={`file-upload-${id}`}>Upload File</label>
      <input
        type="file"
        id={`file-upload-${id}`}
        onChange={handleFileChange}
        src={value}
      />
    </div>
  );
};

export default FileUpload;
