import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MdDeleteOutline } from "react-icons/md";
import { IoAddCircleSharp } from "react-icons/io5";
import { MdDragIndicator } from "react-icons/md";

const DropdownOption = ({ item, onRemove, onAdd, onChange }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="checkbox-option">
      <div className="drag-handle" {...attributes} {...listeners}>
        <MdDragIndicator className="option-operation-icons" />
      </div>
      <input
        type="text"
        value={item.label}
        onChange={(e) => onChange(item.id, e.target.value)}
        className="text-input-element"
      />
      <button className="add-btn" onClick={onAdd}>
        <IoAddCircleSharp className="option-operation-icons" />
      </button>
      <button className="remove-btn" onClick={onRemove}>
        <MdDeleteOutline className="option-operation-icons" />
      </button>
    </div>
  );
};

export default DropdownOption;
