import React from "react";
import { withRouter } from "react-router";
import Loader from 'react-loader-spinner';
import ReactToPrint from "react-to-print";
import Cookies from "js-cookie";

const specialityList = [
    {
        title: 'COPD',
        imgPath: './lungs.png',
        content: 'COPD AI - This novel Machine Learning tool identifies the risk of Acute Exacerbation of Asthma and COPD computing the Patient�s medication use data, clinical features and Air Quality Index (Environmental Data) with an accuracy of 90%'
    },
    {
        title: 'Liver',
        imgPath: './liver.png',
        content: 'Liver Fibrosis - Identifies the risk of Liver Fibrosis in patients with Fatty Liver using a eXtreme Gradient Boosting model with an accuracy over 85%.'
    },
    {
        title: 'Antibiotic',
        imgPath: './antibiotic.png',
        content: 'At Apollo Hospitals, we have been able to analyse over 106k bacterial isolates pan India in past two years (2019 - 2020) and have been able to provide antibiotic associated trends for age, gender, inpatient or outpatients, site of infection and day from admission.'
    }
]


class ComponentToPrint extends React.Component {
    renderCurrentDate = () => {
        const date = new Date()
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    }

    render() {
        const aicvd = this.props.aicvdModData;
        const prediabetes = this.props.prediabetsModData;
        const patientData = this.props.patientData;

        const riskProfile = parseFloat((aicvd.riskScore / aicvd.optimal).toFixed(2));

        let aicvdBgColor = ''
        if (aicvd.riskStatus.toLowerCase() === 'high risk') aicvdBgColor = 'high-risk';
        else if (aicvd.riskStatus.toLowerCase() === ' risk' || aicvd.riskStatus.toLowerCase() === 'medium risk' || aicvd.riskStatus.toLowerCase() === 'mid risk') aicvdBgColor = 'medium-risk';
        else aicvdBgColor = 'low-risk';

        let prediabetesBgColor = '';
        if (patientData.p_diabetes === 'no') {
            if (prediabetes.riskStatus.toLowerCase() === 'high risk') prediabetesBgColor = 'high-risk';
            else if (prediabetes.riskStatus.toLowerCase() === 'medium risk' || prediabetes.riskStatus.toLowerCase() === 'mid risk') prediabetesBgColor = 'medium-risk';
            else prediabetesBgColor = 'low-risk';
        }
        const jwt_token = Cookies.get('jwt_token');

        const parseJwt = (token) => {
            try {
              return JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
              console.log(e.message);
              return null;
            }
        };
        const parseJwtObj = parseJwt(jwt_token);

        return (
            <div className="pdf-container">
                <div className="page">
                    <div className="page-header">
                        <h1 className="header-heading">Participant Report</h1>
                        <img className="header-log" src="./apollo-white-logo.png" alt="apollo-logo" />
                    </div>
                    <div className="pdf-content-container">
                        <ul className="pdf-user-info-container">
                            <li><p className="pdf-user-info-heading">UHID</p>:<p className="pdf-user-info-value">{patientData.uhid}</p></li>
                            <li><p className="pdf-user-info-heading">Name</p>:<p className="pdf-user-info-value">{patientData.name}</p></li>
                            <li><p className="pdf-user-info-heading">Age</p>:<p className="pdf-user-info-value">{patientData.age}</p></li>
                            <li><p className="pdf-user-info-heading">Gender</p>:<p className="pdf-user-info-value">{patientData.gender}</p></li>
                            <li><p className="pdf-user-info-heading">Location</p>:<p className="pdf-user-info-value">{patientData.location}</p></li>
                            <li><p className="pdf-user-info-heading">Mobile</p>:<p className="pdf-user-info-value">{patientData.phone}</p></li>
                        </ul>
                        <p className="line"></p>
                        <h1 className="pdf-sub-heading">APOLLO HOSPITALS THANK YOU FOR YOUR CONSENT</h1>
                        <p className="pdf-page-text">Based on current parameters, personal &#38; family history, AI enabled tools derive health risk scores to alert participant on his/her likely predisposition to a disease to aid early detection &#38; early medical intervention to mitigate the risk.</p>
                        <h1 className="pdf-sub-heading">ABOUT CAMP :</h1>
                        <div className="pdf-camp-detials-container">
                            {/* <div className="pdf-each-camp-details-container width-48">
                                <p>Camp Name</p>
                                <p className="pdf-camp-details-var-container">{campData.campTag}</p>
                            </div> */}
                            {/* <div className="pdf-each-camp-details-container width-20">
                                <p>Date</p>
                                <p className="pdf-camp-details-var-container">{campData.createdDate}</p>
                            </div> */}
                            <div className="pdf-each-camp-details-container width-30">
                                <p>Executive</p>
                                <p className="pdf-camp-details-var-container">{parseJwtObj.name}</p>
                            </div>
                        </div>
                        <div className="history-info-main-container">
                            <div className="history-info-container">
                                <p className="pdf-box-heading">PERSONAL HEALTH HISTORY</p>
                                <div className="history-each-info">
                                    <p className="history-info-heading">Diabetes</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.p_diabetes === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.p_diabetes === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="history-each-info">
                                    <p className="history-info-heading">Hypertension</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.p_hypertension === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.p_hypertension === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="history-each-info">
                                    <p className="history-info-heading">Anemia</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.p_anemia === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.p_anemia === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="history-each-info">
                                    <p className="history-info-heading">Heart Disease</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.p_heart_disease === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.p_heart_disease === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="history-each-info">
                                    <p className="history-info-heading">Dyslipidemia</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.p_dyslipidemia === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.p_dyslipidemia === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="history-each-info border-none">
                                    <p className="history-info-heading">Cancer</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.p_cancer === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.p_cancer === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="history-info-container">
                                <p className="pdf-box-heading">FAMILY HEALTH HISTORY</p>
                                <div className="history-each-info">
                                    <p className="history-info-heading">Diabetes</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.f_diabetes === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.f_diabetes === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="history-each-info">
                                    <p className="history-info-heading">Hypertension</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.f_hypertension === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.f_hypertension === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="history-each-info">
                                    <p className="history-info-heading">Anemia</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.f_anemia === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.f_anemia === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="history-each-info">
                                    <p className="history-info-heading">Heart Disease</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.f_heart_disease === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.f_heart_disease === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="history-each-info">
                                    <p className="history-info-heading">Dyslipidemia</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.f_dyslipidemia === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.f_dyslipidemia === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="history-each-info border-none">
                                    <p className="history-info-heading">Cancer</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container ${patientData.f_cancer === 'yes' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container ${patientData.f_cancer === 'no' && 'red-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="covid-info-container">
                            <p className="pdf-box-heading covid-heading">COVID-19 INFORMATION</p>
                            <div className="three-field-container">
                                <div className="field-container width-33">
                                    <p className="field-text">COVID Infected</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container covid-border ${patientData.p_covid_infection === 'yes' && 'blue-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container covid-border ${patientData.p_covid_infection === 'no' && 'blue-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="field-container width-33">
                                    <p className="field-text">Date of RTPCR</p>
                                    <p className="date-container">{patientData.date_of_rtpcr}</p>
                                </div>
                                <div className="field-container width-33">
                                    <p className="field-text">COVID Vaccinated</p>
                                    <div className="yes-no-container">
                                        <p>Yes</p>
                                        <div className={`tik-container covid-border ${patientData.covid_vaccinated === 'yes' && 'blue-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">No</p>
                                        <div className={`tik-container covid-border ${patientData.covid_vaccinated === 'no' && 'blue-solid-bg'}`}><img className="pdf-check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="field-container width-33">
                                    <p className="field-text">Vaccination Name</p>
                                    <p className="date-container">{patientData.covid_vaccine_name}</p>
                                </div>
                                <div className="field-container width-33">
                                    <p className="field-text">No.of Dose</p>
                                    <div className="yes-no-container">
                                        <p>1</p>
                                        <div className={`tik-container covid-border ${patientData.no_of_dose === '1' && 'blue-solid-bg'}`}><img className="check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">2</p>
                                        <div className={`tik-container covid-border ${patientData.no_of_dose === '2' && 'blue-solid-bg'}`}><img className="check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                        <p className="ml-10">3</p>
                                        <div className={`tik-container covid-border ${patientData.no_of_dose === '3' && 'blue-solid-bg'}`}><img className="check-mark" src="checkmark-outline-white.svg" alt="checkmark.png" /></div>
                                    </div>
                                </div>
                                <div className="field-container width-33">
                                    <p className="field-text">Last date of Dose</p>
                                    <p className="date-container">{patientData.date_of_last_dose}</p>
                                </div>
                            </div>
                        </div>
                        <div className="lifestyle-info-container">
                            <p className="pdf-box-heading life-style-bg">CURRENT LIFESTYLE</p>
                            <div className="three-field-container">
                                <div className="col">
                                    <div className="field-container width-100">
                                        <p className="field-text">Diet</p>
                                        <p className="date-container lifestyle-bg">{patientData.diet}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Smoking</p>
                                        <p className="date-container lifestyle-bg">{patientData.smoking}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Alcohol</p>
                                        <p className="date-container lifestyle-bg">{patientData.alcohol}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Tobacco</p>
                                        <p className="date-container lifestyle-bg">{patientData.tobacco}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Physical activity</p>
                                        <div className="date-container lifestyle-bg">{patientData.physical_activity}</div>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Psychological stress</p>
                                        <div className="date-container lifestyle-bg">{patientData.psychological_stress}</div>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Past medical history</p>
                                        <div className="date-container lifestyle-bg">{patientData.past_medical_history}</div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="field-container width-100">
                                        <p className="field-text">Height (in cm)</p>
                                        <p className="date-container lifestyle-bg">{patientData.height}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Weight (in kg)</p>
                                        <p className="date-container lifestyle-bg">{patientData.weight}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">BMI</p>
                                        <p className="date-container lifestyle-bg">{patientData.bmi}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Heart rate (per min)</p>
                                        <p className="date-container lifestyle-bg">{patientData.heart_rate}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Systolic BP</p>
                                        <p className="date-container lifestyle-bg">{patientData.s_bp}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Diastolic BP</p>
                                        <p className="date-container lifestyle-bg">{patientData.d_bp}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">SpO2</p>
                                        <p className="date-container lifestyle-bg">{patientData.spo2}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Temperature</p>
                                        <p className="date-container lifestyle-bg">{patientData.temperature}</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="field-container width-100">
                                        <p className="field-text">RBS (in mg/dl)</p>
                                        <p className="date-container lifestyle-bg">{patientData.rbs}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Waist</p>
                                        <p className="date-container lifestyle-bg">{patientData.waist}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Change in body weight</p>
                                        <p className="date-container lifestyle-bg">{patientData.change_in_weight}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Chest pain</p>
                                        <p className="date-container lifestyle-bg">{patientData.chest_pain}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Pulse rhythm</p>
                                        <p className="date-container lifestyle-bg">{patientData.pulse_rhythm}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Respiration rate <br />(in min)</p>
                                        <p className="date-container lifestyle-bg">{patientData.rate_respiration}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer-container">
                        <div className="d-flex flex-row justify-space-between plr-25">
                            <p className="footer-text">Apollo Health Camps</p>
                            <div className="d-flex flex-column alingn-item-end">
                                <p className="footer-text mb-4">This report is accessed by <span className="text-capital">{parseJwtObj.name}</span></p>
                                <p className="footer-text">{this.renderCurrentDate()}</p>
                            </div>
                        </div>
                        <div className="style-line-container">
                            <div className="line-1"></div>
                            <div className="line-2"></div>
                        </div>
                    </footer>
                </div>
                <div className="page">
                    <div className="page-header">
                        <h1 className="header-heading">Participant Report</h1>
                        <img className="header-log" src="apollo-white-logo.png" alt="apollo-logo" />
                    </div>
                    <div className="pdf-content-container">
                        <div className="second-page-container">
                            <p className="pdf-box-heading aicvd-bg width-60">AI CALCULATED RISK SCORE BASED ON YOUR INPUTS</p>
                            <div className="three-field-container">
                                <div className="col">
                                    <div className="field-container width-100">
                                        <p className="field-text">Report Name</p>
                                        <p className="date-container">Risk of Heart Disease</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Risk Status</p>
                                        <p className={`date-container ${aicvdBgColor}`}>{aicvd.riskStatus}</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="field-container width-100">
                                        <p className="field-text">Risk Score</p>
                                        <p className="date-container">{aicvd.riskScore}</p>
                                    </div>
                                    <div className="field-container width-100">
                                        <p className="field-text">Acceptable Score</p>
                                        <p className="date-container">{aicvd.optimal}</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="field-container width-100">
                                    <p className="field-text">Risk Profile</p>
                                    <p className="date-container">{riskProfile}x</p>
                                    </div>
                                </div>
                                {
                                    patientData.aicvdcat === 'Minimal Risk' ?
                                    <p className="risk-text">Low Risk - Your likelihood to Cardiovascular disease is within acceptable limit compared to other individuals of your age &#38; gender in next 10 years</p> :
                                    <p className="risk-text">{`Your likelihood to Cardiovascular disease is ${riskProfile === 1.00 ? 'with in acceptable range' : ''} ${riskProfile < 1.00 ? 'lower' : ''} ${riskProfile > 1.00 ? 'higher' : ''} ${patientData.aicvdcat !== 'Low Risk' ? `(${riskProfile}x)`: ''} to other people of your age & gender in next 10 years`}</p>
                                }
                            </div>
                            <p className="aicvd-note-text">Note : The risk category is determined through the ratio between patient score and acceptable score at multiple decimal points. The output is shown in whole numbers.</p>
                        </div>
                        {
                            patientData.p_diabetes !== 'yes' ?
                            <div className="second-page-container diabetes-container">
                                <div className="d-flex flex-row justify-space-between">
                                    <div className="d-flex flex-column width-30">
                                        <p className="each-container-text text-white ">Report Name</p>
                                        <p className="diabetes-var-container">Risk of Diabetes</p>
                                    </div>
                                    <div className="d-flex flex-column width-30">
                                        <p className="each-container-text text-white ">Risk Status</p>
                                        <p className={`diabetes-var-container ${prediabetesBgColor}`}>{prediabetes.riskStatus}</p>
                                    </div>
                                    <div className="d-flex flex-column width-30">
                                        <p className="each-container-text text-white ">Risk Score</p>
                                        <p className="diabetes-var-container">{prediabetes.riskScore}</p>
                                    </div>
                                </div>
                                <p className="diabetes-text">Note : The risk status is computed with the Machine Learning Algorithm and categorised based on the individual's chances of Prediabetes/Diabetes. It also provides a score based on the probability of the risk status.</p> 
                            </div> :
                            <p className="diabetes-note">--- Note: Participants with a confirmed diagnosis of Diabetes shall not have Prediabetes Risk. ---</p>
                        }
                        <p className="certificate-text">The Clinical AI Models and APIs used at Apollo Hospitals are certified by ISO 13485 : 2016 vide certificate no. MD 763515</p>
                        <div className="second-page-container aicvd-recommendations-border">
                            <p className="pdf-box-heading aicvd-recommendations width-90">HEART DISEASE PREVENTION - AI RECOMMENDED PROTOCOL (FOR DR REVIEW &#38; ADVISE)</p>
                            <ul className="aicvd-recommendation-list-container">
                                <li className="aicvd-recommendation-list-item">
                                    <p className="list-item-heading">Lab Investigation</p>
                                    <p className="list-item-value">{aicvd.labInvestigation}</p>
                                </li>
                                <li className="aicvd-recommendation-list-item">
                                    <p className="list-item-heading">Diagnostics and Imaging</p>
                                    <p className="list-item-value">{aicvd.diagnosticsAndImagingRecommended}</p>
                                </li>
                                <li className="aicvd-recommendation-list-item">
                                    <p className="list-item-heading">Referral</p>
                                    <p className="list-item-value">{aicvd.reffal}</p>
                                </li>
                                <li className="aicvd-recommendation-list-item">
                                    <p className="list-item-heading">Advice</p>
                                    <p className="list-item-value">{aicvd.advice}</p>
                                </li>
                                <li className="aicvd-recommendation-list-item">
                                    <p className="list-item-heading">Repeat Visit</p>
                                    <p className="list-item-value">{aicvd.repeatVisit}</p>
                                </li>
                                <li className="aicvd-recommendation-list-item border-none">
                                    <p className="list-item-heading">Treatment Protocol</p>
                                    <p className="list-item-value">{aicvd.treatmentGoal}</p>
                                </li>
                            </ul>
                        </div>
                        {
                            patientData.p_diabetes !== 'yes' &&
                            <div className="second-page-container mb-8 diabetes-recommendations-border">
                                <p className="pdf-box-heading diabetes-recommendations width-85"> DIABETES PREVENTION - AI RECOMMENDED PROTOCOL (FOR DR REVIEW &#38; ADVISE)</p>
                                <ul className="aicvd-recommendation-list-container">
                                    <li className="aicvd-recommendation-list-item diabetes-list-bottom-border">
                                        <p className="list-item-heading diabetes-list-right-border">Lab Investigation</p>
                                        <p className="list-item-value">{prediabetes.labInvestigation}</p>
                                    </li>
                                    <li className="aicvd-recommendation-list-item diabetes-list-bottom-border">
                                        <p className="list-item-heading diabetes-list-right-border">Activity</p>
                                        <p className="list-item-value">{prediabetes.activity}</p>
                                    </li>
                                    <li className="aicvd-recommendation-list-item diabetes-list-bottom-border">
                                        <p className="list-item-heading diabetes-list-right-border">Referral to consulted</p>
                                        <p className="list-item-value">{prediabetes.referralToConsultant}</p>
                                    </li>
                                    <li className="aicvd-recommendation-list-item diabetes-list-bottom-border">
                                        <p className="list-item-heading diabetes-list-right-border">Nutrition</p>
                                        <p className="list-item-value">{prediabetes.nutrition}</p>
                                    </li>
                                    <li className="aicvd-recommendation-list-item diabetes-list-bottom-border">
                                        <p className="list-item-heading diabetes-list-right-border">Tests follow up</p>
                                        <p className="list-item-value">{prediabetes.testsFollowup}</p>
                                    </li>
                                    <li className="aicvd-recommendation-list-item border-none">
                                        <p className="list-item-heading diabetes-list-right-border">Treatment Protocol</p>
                                        <p className="list-item-value">{prediabetes.treatmentGoal}</p>
                                    </li>
                                </ul>
                            </div>
                        }
                        
                    </div>
                    <footer className="footer-container">
                        <div className="d-flex flex-row justify-space-between plr-25">
                            <p className="footer-text">Apollo Health Camps</p>
                            <div className="d-flex flex-column alingn-item-end">
                                <p className="footer-text mb-4">This report is accessed by <span className="text-capital">{parseJwtObj.name}</span></p>
                                <p className="footer-text">{this.renderCurrentDate()}</p>
                            </div>
                        </div>
                        <div className="style-line-container">
                            <div className="line-1"></div>
                            <div className="line-2"></div>
                        </div>
                    </footer>
                </div>
                <div className="page">
                <div className="page-header">
                    <h1 className="header-heading">Participant Report</h1>
                    <img className="header-log" src="apollo-white-logo.png" alt="apollo-logo" />
                </div>
                <div className="pdf-content-container mt-20">
                    {/* <h1 className="pdf-sub-heading mb-20">DOCTOR REVIEW :</h1>
                    <div className="review-container">
                        <p className="review-heading">Doctor Name :</p>
                        <p className="review-value">{patientData.doctor_name}</p>
                    </div>
                    <div className="review-container">
                        <p className="review-heading">Review :</p>
                        <p className="review-value">{patientData.doctor_review}</p>
                    </div> */}
                    <h1 className="pdf-sub-heading mb-20 mt-70">DISCLAIMER :</h1>
                    <ul className="disclaimer-list-conatiner">
                        <li className="disclaimer-list-item">This test only derives the 'RISK SCORE' to alert the participant of his/her likely risk predisposition to a disease. It is not a diagnostic tool and does not guarantee accuracy of the result &#38; cannot be independently acted upon.</li>
                        <li className="disclaimer-list-item">This Risk score and Clinical Algorithm is a general guideline for Physicians. Any additional laboratory investigations, Diagnostic Imaging, Treatment or Patient Education related to lifestyle management is under Physician's or Cardiologist's discretion.</li>
                        <li className="disclaimer-list-item">To ensure the information in the report is up to date, accurate and correct, Doctor shall be consulted for interpretation of the report.</li>
                        <li className="disclaimer-list-item">Apollo Hospitals and its Staff does not offer any assurance on the information made available or be liable for any loss or damage as the said report is based on the AICVD Cardiac Risk Score without any intervention from their side.</li>
                        <li className="disclaimer-list-item">It is deemed that by providing consent to derive the RISK SCORE, the participant has done so at his/her own risk &#38; further agrees with this disclaimer without any limitation to any clauses or sub-clauses</li>
                    </ul>
                </div>
                <div className="logo-container">
                    <img className="branding-logo" src="apollo-247.png" alt="apollo-log" />
                    <img className="branding-logo" src="askapollo.png" alt="apollo-log" />
                </div>
                <footer className="footer-container">
                    <div className="d-flex flex-row justify-space-between plr-25">
                        <p className="footer-text">Apollo Health Camps</p>
                        <div className="d-flex flex-column alingn-item-end">
                            <p className="footer-text mb-4">This report is accessed by <span className="text-capital">{parseJwtObj.name}</span></p>
                            <p className="footer-text">{this.renderCurrentDate()}</p>
                        </div>
                    </div>
                    <div className="style-line-container">
                        <div className="line-1"></div>
                        <div className="line-2"></div>
                    </div>
                </footer>
                </div>
            </div>
        )
    }
}


class CommonReportPage extends React.Component {
    state = {
        reportLoaderStatus: true,
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    pushNewPatient = () => {
        const {history} = this.props;
        history.replace('/form-fields');
    }

    renderRiskScoreCard = (data, index) => {
        let riskContainerColor = '';
        if (data.riskStatus.toLowerCase() === 'high risk') riskContainerColor = 'high-risk';
        else if (data.riskStatus.toLowerCase() === 'moderate risk' || data.riskStatus.toLowerCase() === 'medium risk' || data.riskStatus.toLowerCase() === 'mid risk') riskContainerColor = 'medium-risk';
        else riskContainerColor = 'low-risk';
        return (
            <div id="card" className="details-card-container" key={index}>
                <div className="d-flex flex-row justify-content-between align-items-center card-content-gap">
                    <p className="risk-card-heading">{data.title}</p>
                    <img className="risk-card-img" src={data.img} alt={`${data.title}.png`} />
                </div>
                <div className="d-flex align-items-end">
                    <div className="risk-catagory-container">
                        <p className="catagory-title">Risk Status</p>
                        <p className={`catagory-value ${riskContainerColor}`}>{data.riskStatus}</p>
                    </div>
                    <div className="risk-catagory-container">
                        <p className="catagory-title">Risk Score</p>
                        <p className="catagory-value">{data.riskScore}</p>
                    </div>
                    {
                        data.title === 'AICVD' && <div className="risk-catagory-container">
                        <p className="catagory-title">Acceptable Score</p>
                        <p className="catagory-value">{data.optimal}</p>
                    </div>
                    }
                    
                </div>
            </div>
        )
    }

    returnLoader = () => (
        <div className="common-report-loader-container">
            <Loader type="Oval" color="#FF883E" height={50} width={50} /> 
        </div>
    )

    renderSpecialityReport = (data, index) => {
        return (
            <div id="card" className="details-card-container" key={index}>
                <div className="d-flex flex-row justify-content-between align-items-center card-content-gap">
                    <p className="risk-card-heading">{data.title}</p>
                    <img className="risk-card-img" src={data.imgPath} alt={`${data.title}.png`} />
                </div>
                <div className="d-flex justify-content-between align-items-end margin-top-auto">
                    <p className="speciality-content">{data.content}</p>
                    <button className="button get-button disabled-button" disabled >Get</button>
                </div>
            </div>
        )
    }

    newPatientButtonClicked = () => {
        const {clearPrvPatinetData, history} = this.props;
        clearPrvPatinetData();
        const formId = Cookies.get("global_form_id");

        history.push({
            pathname: "/form-fields",
            state: { formId: formId },
          });
    }

    renderReport = () => {
        const {newPatientObject, aicvdReport, prediabetesReport} = this.props.data;



        if (newPatientObject.covid_vaccinated === 'no') {
            newPatientObject.covid_vaccine_name = 'None';
            newPatientObject.no_of_dose = 'None';
        }

        const {Prediction} = aicvdReport.Data[0];

        const allLabInvestigation = Prediction.MedicalProtocol.LabInvestigationRecommended;
        let filterLabInvestigation = ''
        for (let key in allLabInvestigation) {
            if (allLabInvestigation[key] === 'Yes') {
                filterLabInvestigation += key + ', '
            }
        }

        const allDiagnosticsAndImagingRecommended = Prediction.MedicalProtocol.DiagnosticsAndImagingRecommended;
        let filterDiagnosticsAndImagingRecommended = ''
        for (let key in allDiagnosticsAndImagingRecommended) {
            if (allDiagnosticsAndImagingRecommended[key] === 'Yes') {
                filterDiagnosticsAndImagingRecommended += key + ', '
            }
        }

        const aicvdModData = {
            title: 'AICVD',
            img: './heart.png',
            riskScore: Prediction.HeartRisk.Score,
            optimal: Prediction.HeartRisk.Acceptable,
            riskStatus: Prediction.HeartRisk.Risk,
            labInvestigation: filterLabInvestigation,
            diagnosticsAndImagingRecommended: filterDiagnosticsAndImagingRecommended,
            advice: Prediction.MedicalProtocol.Management.GeneralAdvice,
            repeatVisit: Prediction.MedicalProtocol.Management.RepeatVisit.Comments,
            treatmentGoal: Prediction.MedicalProtocol.Medication.GeneralTreatment,
            referral: `${Prediction.MedicalProtocol.Referral.Department} + (${Prediction.MedicalProtocol.Referral.Urgency})`
        } 

        const campData = JSON.parse(localStorage.getItem('camp_data'));
        let riskReportList = [aicvdModData];

        let prediabetsModData = {}
        if (newPatientObject.p_diabetes === 'no') {
            prediabetsModData = {
                title: 'Prediabetes',
                img: './diabetes.png',
                riskScore: prediabetesReport.data.score,
                riskStatus: prediabetesReport.data.risk,
                activity: prediabetesReport.data.activity,
                educate: prediabetesReport.data.educate,
                labInvestigation: prediabetesReport.data.lab_investigation,
                nutrition: prediabetesReport.data.nutrition,
                referralToConsultant: prediabetesReport.data.referral_to_consultant,
                testsFollowup: prediabetesReport.data.tests_followup,
                treatmentGoal: prediabetesReport.data.treatment_goal
            }
            riskReportList.push(prediabetsModData)
        }

        return (
            <div className="report-container">
                <div className="menu-icon-with-apollo-logo">
                    <button className="button back-button" onClick={this.pushNewPatient}>
                        <img src="./back-arrow.svg" alt="back-arrow.svg" />
                    </button>
                    <img className="small-apollo-logo" src="./logo.png" alt="apollo.png" />
                </div>
                <div className="large-back-button-container">
                    <button className="button back-button" onClick={this.pushNewPatient}>
                        <img src="./back-arrow.svg" alt="back-arrow.svg" />
                    </button>
                    <p className="large-back-button-text">Back</p>
                </div>
                <div className="health-camp-heading-container">
                    <p className="apollo-hospitals-text">Apollo Hospitals</p>
                    <h1 className="heading text-bold">Health Camps</h1>
                    <div className="heading-style-element-container">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center margin-bottom-30">
                    <div className="style-element-container bottom-0">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                    <div className="sub-heading-with-logo margin-left-right-8">
                        <h1 className="sub-heading">Participant Info</h1>
                        <img className="sub-heading-icon" src="./person-outline.svg" alt="list.svg" />
                    </div>
                    <div className="style-element-container bottom-0 rotate-180">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <div className="participant-info-container">
                    <div className="info-line-container">
                        <p className="line-name">Participant ID</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.uhid}</p>
                    </div>
                    <div className="info-line-container">
                        <p className="line-name">Name</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.name}</p>
                    </div>
                    <div className="info-line-container">
                        <p className="line-name">Age</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.age}</p>
                    </div>
                    <div className="info-line-container">
                        <p className="line-name">Gender</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.gender}</p>
                    </div>
                    <div className="info-line-container">
                        <p className="line-name">Location</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.location}</p>
                    </div>
                    <div className="info-line-container">
                        <p className="line-name">Phone</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.phone}</p>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center margin-bottom-30">
                    <div className="style-element-container bottom-0">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                    <div className="sub-heading-with-logo margin-left-right-8">
                        <h1 className="sub-heading">General Health Score</h1>
                        <img className="sub-heading-icon" src="./receipt-outline.svg" alt="list.svg" />
                    </div>
                    <div className="style-element-container bottom-0 rotate-180">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <div className="details-cards-container margin-bottom-40">
                    {
                        riskReportList.map((each, index) => (this.renderRiskScoreCard(each, index)))
                    }
                    {
                        newPatientObject.p_diabetes === 'yes' &&
                        <div id="card" className="details-card-container">
                            <div className="d-flex flex-row justify-content-between align-items-center card-content-gap">
                                <p className="risk-card-heading">Prediabetes</p>
                                <img className="risk-card-img" src="./diabetes.png" alt='prediabetes.png' />
                            </div>
                            <div className="d-flex align-items-end">
                                <p className="diabetes-note-text"><span className="diabetes-note-title">Note: </span>Participants with a confirmed diagnosis of Diabetes shall not have Prediabetes Risk.</p>
                            </div>
                        </div>
                    }
                    
                </div>
                <div className="d-flex flex-row align-items-center margin-bottom-30">
                    <div className="style-element-container bottom-0">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                    <div className="sub-heading-with-logo margin-left-right-8">
                        <h1 className="sub-heading">Speciality Reports</h1>
                        <img className="sub-heading-icon" src="./person-outline.svg" alt="list.svg" />
                    </div>
                    <div className="style-element-container bottom-0 rotate-180">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <div className="details-cards-container justify-content-start margin-bottom-40">
                    {
                        specialityList.map((each, index) => (this.renderSpecialityReport(each, index)))
                    }
                </div>
                <div className="report-button-container">
                    <button className="button print-report-button" onClick={this.newPatientButtonClicked}>New Participant</button>
                    <ReactToPrint
                        trigger={() => <button className="button generate-report-button">Get Report</button>}
                        content={() => this.componentRef}
                    />
                    <div style={{display: 'none'}}>
                        <ComponentToPrint ref={(el) => (this.componentRef = el)} aicvdModData={aicvdModData} prediabetsModData={prediabetsModData} patientData={newPatientObject} campData={campData} />
                    </div>
                </div>
                <div className="end-line"></div>
                <p className="copy-rights-text">
                    <span className="font-weight-700">Copyright @{new Date().getFullYear()} Powered By Apollo Hospitals.</span><br /> All rights reserved.
                </p>
            </div>
        )
    }



    render() {
        return (
            <>
                {
                    this.renderReport()
                }
            </>
        )
    }
}
export default withRouter(CommonReportPage);
