import Cookies from "js-cookie";
import React from "react";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";
// import { Link } from "react-router-dom";

class LoginPage extends React.Component {
  state = {
    username: "",
    password: "",
    usernameIsEmpty: false,
    passwordIsEmpty: false,
    invalidUser: false,
    loginLoader: false,
  };

  formSubmit = async (event) => {
    event.preventDefault();

    const { username, password } = this.state;

    if (username !== "" && password !== "") {
      this.setState({ loginLoader: true });
      const { history } = this.props;

      const url = "https://apollo-totalhealth-camp-ai.azurewebsites.net/login";

      let raw = JSON.stringify({
        username: username.toLowerCase(),
        password: password,
      });

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(url, requestOptions);
      const responseObj = await response.json();

      if (responseObj.status === "success") {
        Cookies.set("jwt_token", responseObj.jwt_token, {
          expires: 1,
          path: "/",
        });
        const parseJwt = (token) => {
          try {
            return JSON.parse(atob(token.split(".")[1]));
          } catch (e) {
            return null;
          }
        };

        const parseJwtObj = parseJwt(responseObj.jwt_token);
        const role = parseJwtObj.uRole;

        if (role === "admin") {
          history.replace("/dashboard");
        } else {
          history.replace("/camps-list");
        }
      } else {
        this.setState({
          invalidUser: true,
          loginLoader: false,
        });
      }
    } else {
      if (username === "") {
        this.setState({
          usernameIsEmpty: true,
        });
      }
      if (password === "") {
        this.setState({
          passwordIsEmpty: true,
        });
      }
    }
  };

  rememberTrigger = (event) => {
    this.setState({
      tickMarkStatus: event.target.checked,
    });
  };

  inputFieldCheck = (event) => {
    const element = event.target.name;
    const value = event.target.value;

    if (value === "") {
      if (element === "username") {
        this.setState({
          usernameIsEmpty: true,
        });
      } else {
        this.setState({
          passwordIsEmpty: true,
        });
      }
    }
  };

  removeErrorText = (event) => {
    const element = event.target.name;

    if (element === "username") {
      this.setState({
        usernameIsEmpty: false,
      });
    } else {
      this.setState({
        passwordIsEmpty: false,
      });
    }
  };

  updateInput = (event) => {
    const element = event.target.name;

    if (element === "username") {
      this.setState({
        username: event.target.value,
      });
    } else {
      this.setState({
        password: event.target.value,
      });
    }
  };

  render() {
    const { usernameIsEmpty, passwordIsEmpty, invalidUser, loginLoader } =
      this.state;

    return (
      <form className="login-container" onSubmit={this.formSubmit}>
        <img className="login-logo" src="./logo.png" alt="apollo.png" />
        <img className="svg-1" src="./svg1.svg" alt="apollo.png" />
        <img className="svg-2" src="./svg-2.svg" alt="apollo.png" />
        <div className="login-form-container">
          <h1 className="login-heading">Login</h1>
          <div className="input-container">
            <label className="label">Username</label>
            <div className="input-with-logo">
              <input
                type="text"
                className="login-input"
                placeholder="example@apollohospitals.com"
                name="username"
                onBlur={this.inputFieldCheck}
                onFocus={this.removeErrorText}
                onChange={this.updateInput}
              />
              <img
                className="icon"
                src="./person-outline.svg"
                alt="apollo.png"
              />
            </div>
            {usernameIsEmpty && (
              <p className="error-text">Please enter the username</p>
            )}
          </div>
          <div className="input-container">
            <label className="label">Password</label>
            <div className="input-with-logo">
              <input
                type="password"
                className="login-input"
                placeholder="**********"
                name="password"
                onBlur={this.inputFieldCheck}
                onFocus={this.removeErrorText}
                onChange={this.updateInput}
              />
              <img className="icon" src="./key-outline.svg" alt="apollo.png" />
            </div>
            {passwordIsEmpty && (
              <p className="error-text">Please enter the password</p>
            )}
          </div>
          {/* <Link className="forget-text" to="/forgot-password">Forgot Password?</Link> */}
          <button className="button login-button large-screen" type="submit">
            {loginLoader ? (
              <Loader type="TailSpin" color="#ffffff" height={30} width={30} />
            ) : (
              "Login"
            )}
          </button>
        </div>
        {invalidUser && (
          <p className="error-text-custom">Invalid username or password</p>
        )}
        <button
          className="button login-button mb-50 small-screen"
          type="submit"
        >
          {loginLoader ? (
            <Loader type="TailSpin" color="#ffffff" height={30} width={30} />
          ) : (
            "Login"
          )}
        </button>
      </form>
    );
  }
}

export default withRouter(LoginPage);
