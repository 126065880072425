// SelectList.js
import React from "react";
import GenericList from "../common/OptionList";
import DropdownOption from "./DropdownOption";

const SelectList = (props) => {
  return <GenericList {...props} OptionComponent={DropdownOption} />;
};

export default SelectList;
