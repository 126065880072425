// CheckboxList.js
import React from "react";
import GenericList from "../common/OptionList";
import CheckboxOption from "./CheckboxOption";

const CheckboxList = (props) => {
  return <GenericList {...props} OptionComponent={CheckboxOption} />;
};

export default CheckboxList;
