import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const VitalsGraph = ({ color, data, height, width }) => {
  const dataPoints = data?.map((data, index) => ({
    x: index + 1,
    y: data.y,
    label: data.x,
  }));

  const options = {
    animationEnabled: true,
    backgroundColor: "transparent",
    axisX: {
      lineThickness: 1,
      tickThickness: 0,
      lineColor: "#CCCCCC",
      labelFormatter: () => " ",
      gridThickness: 0,
      interval: 1,
      labelFontColor: "#CCCCCC",
    },
    axisY: {
      lineThickness: 1,
      lineColor: "#CCCCCC",
      tickThickness: 0,
      // labelFormatter: () => " ",
      gridThickness: 0,
      labelFontColor: "#CCCCCC",
    },
    width: width,
    height: height,
    data: [
      {
        type: "splineArea",
        color: color,
        fillOpacity: 0.2,
        dataPoints: dataPoints,
        fillColor: {
          type: "linear-gradient",
          colorStops: [
            { offset: 0, color: "rgba(246, 160, 26, 0.5)" },
            { offset: 1, color: "rgba(246, 160, 26, 0.1)" },
          ],
        },
      },
    ],
    creditText: "smhdga",
  };

  return <CanvasJSChart options={options} />;
};

export default VitalsGraph;
