// Sortable Item

import React, { useState, useRef, useEffect } from "react";
import {
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import InputField from "../components/Name/index.js";
import CheckboxList from "../components/checkbox/index.js";
import RadioList from "../components/Radio/index.js";
import SelectList from "../components/Dropdown/index.js";
import ButtonList from "../components/Button/index.js";
import TextAreaField from "../components/TextArea/index.js";
import { MdDragIndicator } from "react-icons/md";
import "./index.css";
import { IoSettings } from "react-icons/io5";
import { IoEllipsisVertical } from "react-icons/io5";
import {  FaTrashAlt, FaArrowDown } from "react-icons/fa";

// Droppable Area
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";
import { v4 as uuidv4 } from "uuid";
import UploadComponent from "../components/uploadComponent";

const generateUniqueId = (prefix) => `${prefix}-${uuidv4()}`;

function SortableItem({
  item,
  setSections,
  handleItemSetting,
  setShowSetting,
  scrollableItem,
}) {
  const [itemDetails, setItemDetails] = useState({
    label: item.label || "",
    placeholder: item.placeholder || "",
    position: item.position,
  });

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (isDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownVisible]);

  useEffect(() => {
    const element = document.getElementById(scrollableItem);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollableItem]);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  const handleInputChange = (name, value) => {
    setItemDetails((prevDetails) => ({ ...prevDetails, [name]: value }));

    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((el) =>
          el.id === item.id ? { ...el, [name]: value } : el
        ),
      }))
    );
  };

  const handleCheckboxOptionsChange = (newOptions) => {
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((el) =>
          el.id === item.id ? { ...el, options: newOptions } : el
        ),
      }))
    );
  };

  const handleRemoveItem = () => {
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items
          .filter((el) => el.id !== item.id)
          .map((el, index) => ({ ...el, position: index })),
      }))
    );
  };

  const handleFieldSetting = (item, element) => {
    setShowSetting(true);
    handleItemSetting(item, element);
  };

  const handleCloneItem = (itemID) => {
    setSections((prevSections) => {
      return prevSections.map((section) => {
        const itemIndex = section.items.findIndex((item) => item.id === itemID);
        if (itemIndex !== -1) {
          const itemToClone = section.items[itemIndex];
          const newId = generateUniqueId("item");
          const newItem = {
            ...itemToClone,
            id: newId,
            label: `${itemToClone.label}`,
          };
          const newItems = [
            ...section.items.slice(0, itemIndex + 1),
            newItem,
            ...section.items.slice(itemIndex + 1),
          ];

          return {
            ...section,
            items: newItems,
          };
        }
        return section;
      });
    });
  };

  const renderItem = (element) => {
    switch (element.type) {
      case "text":
        return (
          <div
            className="each-droppable-item"
            ref={setNodeRef}
            style={style}
            id={element.id}
          >
            <div className="drag-remove-conatiner">
              <MdDragIndicator
                className="drag-handle drag-icon"
                {...attributes}
                {...listeners}
              />
              <div className="more-options-container">
                <button
                  className="more-options-button"
                  onClick={() => handleFieldSetting(item, element)}
                >
                  <IoSettings size={20} />
                </button>
                <button
                  onClick={toggleDropdown}
                  className="more-options-button"
                >
                  <IoEllipsisVertical size={20} />
                </button>
                {isDropdownVisible && (
                  <div className="dropdown-menu">
                    <button
                      onClick={() => handleCloneItem(element.id)}
                      className="dropdown-item"
                    >
                      <FaArrowDown /> Clone
                    </button>
                    {!element.removeStatus && (
                      <button
                        onClick={handleRemoveItem}
                        className="dropdown-item"
                      >
                        <FaTrashAlt /> Remove
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <InputField
              type={element.type}
              placeholder={element.placeholder}
              id={element.id}
              name="label"
              value={itemDetails.label}
              onChange={handleInputChange}
            />
          </div>
        );
      case "text-area":
        return (
          <div
            className="each-droppable-item"
            ref={setNodeRef}
            style={style}
            id={element.id}
          >
            <div className="drag-remove-conatiner">
              <MdDragIndicator
                className="drag-handle drag-icon"
                {...attributes}
                {...listeners}
              />
              <div className="more-options-container">
                <button
                  className="more-options-button"
                  onClick={() => handleFieldSetting(item, element)}
                >
                  <IoSettings size={20} />
                </button>
                <button
                  onClick={toggleDropdown}
                  className="more-options-button"
                >
                  <IoEllipsisVertical size={20} />
                </button>
                {isDropdownVisible && (
                  <div className="dropdown-menu">
                    <button
                      onClick={() => handleCloneItem(element.id)}
                      className="dropdown-item"
                    >
                      <FaArrowDown /> Clone
                    </button>
                    {!element.removeStatus && (
                      <button
                        onClick={handleRemoveItem}
                        className="dropdown-item"
                      >
                        <FaTrashAlt /> Remove
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <TextAreaField
              type={element.type}
              placeholder={element.placeholder}
              id={element.id}
              name="label"
              value={itemDetails.label}
              onChange={handleInputChange}
            />
          </div>
        );
      case "checkbox":
        return (
          <div
            className="each-droppable-item"
            ref={setNodeRef}
            style={style}
            id={element.id}
          >
            <div className="drag-remove-conatiner">
              <MdDragIndicator
                className="drag-handle drag-icon"
                {...attributes}
                {...listeners}
              />
              <div className="more-options-container">
                <button
                  className="more-options-button"
                  onClick={() => handleFieldSetting(item, element)}
                >
                  <IoSettings size={20} />
                </button>
                <button
                  onClick={toggleDropdown}
                  className="more-options-button"
                >
                  <IoEllipsisVertical size={20} />
                </button>
                {isDropdownVisible && (
                  <div className="dropdown-menu">
                    <button
                      onClick={() => handleCloneItem(element.id)}
                      className="dropdown-item"
                    >
                      <FaArrowDown /> Clone
                    </button>
                    {!element.removeStatus && (
                      <button
                        onClick={handleRemoveItem}
                        className="dropdown-item"
                      >
                        <FaTrashAlt /> Remove
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <CheckboxList
              placeholder={element.placeholder}
              id={element.id}
              name="label"
              options={element.options}
              value={itemDetails.label}
              setOptions={handleCheckboxOptionsChange}
              onChange={handleInputChange}
            />
          </div>
        );
      case "radio":
        return (
          <div
            className="each-droppable-item"
            ref={setNodeRef}
            style={style}
            id={element.id}
          >
            <div className="drag-remove-conatiner">
              <MdDragIndicator
                className="drag-handle drag-icon"
                {...attributes}
                {...listeners}
              />
              <div className="more-options-container">
                <button
                  className="more-options-button"
                  onClick={() => handleFieldSetting(item, element)}
                >
                  <IoSettings size={20} />
                </button>
                <button
                  onClick={toggleDropdown}
                  className="more-options-button"
                >
                  <IoEllipsisVertical size={20} />
                </button>
                {isDropdownVisible && (
                  <div className="dropdown-menu">
                    <button
                      onClick={() => handleCloneItem(element.id)}
                      className="dropdown-item"
                    >
                      <FaArrowDown /> Clone
                    </button>
                    {!element.removeStatus && (
                      <button
                        onClick={handleRemoveItem}
                        className="dropdown-item"
                      >
                        <FaTrashAlt /> Remove
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <RadioList
              placeholder={element.placeholder}
              id={element.id}
              name="label"
              options={element.options}
              value={itemDetails.label}
              setOptions={handleCheckboxOptionsChange}
              onChange={handleInputChange}
            />
          </div>
        );
      case "select":
        return (
          <div
            className="each-droppable-item"
            ref={setNodeRef}
            style={style}
            id={element.id}
          >
            <div className="drag-remove-conatiner">
              <MdDragIndicator
                className="drag-handle drag-icon"
                {...attributes}
                {...listeners}
              />
              <div className="more-options-container">
                <button
                  className="more-options-button"
                  onClick={() => handleFieldSetting(item, element)}
                >
                  <IoSettings size={20} />
                </button>
                <button
                  onClick={toggleDropdown}
                  className="more-options-button"
                >
                  <IoEllipsisVertical size={20} />
                </button>
                {isDropdownVisible && (
                  <div className="dropdown-menu">
                    <button
                      onClick={() => handleCloneItem(element.id)}
                      className="dropdown-item"
                    >
                      <FaArrowDown /> Clone
                    </button>
                    {!element.removeStatus && (
                      <button
                        onClick={handleRemoveItem}
                        className="dropdown-item"
                      >
                        <FaTrashAlt /> Remove
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <SelectList
              placeholder={element.placeholder}
              id={element.id}
              name="label"
              options={element.options}
              value={itemDetails.label}
              setOptions={handleCheckboxOptionsChange}
              onChange={handleInputChange}
            />
          </div>
        );
      case "button":
        return (
          <div
            className="each-droppable-item"
            ref={setNodeRef}
            style={style}
            id={element.id}
          >
            <div className="drag-remove-conatiner">
              <MdDragIndicator
                className="drag-handle drag-icon"
                {...attributes}
                {...listeners}
              />
              <div className="more-options-container">
                <button
                  className="more-options-button"
                  onClick={() => handleFieldSetting(item, element)}
                >
                  <IoSettings size={20} />
                </button>
                <button
                  onClick={toggleDropdown}
                  className="more-options-button"
                >
                  <IoEllipsisVertical size={20} />
                </button>
                {isDropdownVisible && (
                  <div className="dropdown-menu">
                    <button
                      onClick={() => handleCloneItem(element.id)}
                      className="dropdown-item"
                    >
                      <FaArrowDown /> Clone
                    </button>
                    {!element.removeStatus && (
                      <button
                        onClick={handleRemoveItem}
                        className="dropdown-item"
                      >
                        <FaTrashAlt /> Remove
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <ButtonList
              placeholder={element.placeholder}
              id={element.id}
              name="label"
              value={itemDetails.label}
              options={element.options}
              data={element}
              onChange={handleInputChange}
            />
          </div>
        );
      case "upload":
        return (
          <div
            className="each-droppable-item"
            ref={setNodeRef}
            style={style}
            id={element.id}
          >
            <div className="drag-remove-conatiner">
              <MdDragIndicator
                className="drag-handle drag-icon"
                {...attributes}
                {...listeners}
              />
              <div className="more-options-container">
                <button
                  className="more-options-button"
                  onClick={() => handleFieldSetting(item, element)}
                >
                  <IoSettings size={20} />
                </button>
                <button
                  onClick={toggleDropdown}
                  className="more-options-button"
                >
                  <IoEllipsisVertical size={20} />
                </button>
                {isDropdownVisible && (
                  <div className="dropdown-menu">
                    <button
                      onClick={() => handleCloneItem(element.id)}
                      className="dropdown-item"
                    >
                      <FaArrowDown /> Clone
                    </button>
                    {!element.removeStatus && (
                      <button
                        onClick={handleRemoveItem}
                        className="dropdown-item"
                      >
                        <FaTrashAlt /> Remove
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <UploadComponent
              type={element.type}
              placeholder={element.placeholder}
              id={element.id}
              name="label"
              value={itemDetails.label}
              onChange={handleInputChange}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return <div>{renderItem(item)}</div>;
}

const DroppableArea = ({
  items,
  setSections,
  sectionId,
  handleItemSetting,
  setShowSetting,
  itemRefs,
  scrollableItem,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setSections((prevSections) =>
        prevSections.map((section) => {
          if (section.id === sectionId) {
            const oldIndex = section.items.findIndex((i) => i.id === active.id);
            const newIndex = section.items.findIndex((i) => i.id === over.id);
            const updatedItems = arrayMove(section.items, oldIndex, newIndex);
            return {
              ...section,
              items: updatedItems,
            };
          }
          return section;
        })
      );
    }
  };
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items.map((item) => item.id)}>
        {items.map((item) => (
          <SortableItem
            key={item.id}
            item={item}
            setSections={setSections}
            handleItemSetting={handleItemSetting}
            setShowSetting={setShowSetting}
            itemRefs={itemRefs}
            scrollableItem={scrollableItem}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default DroppableArea;
