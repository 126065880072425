import Cookies from "js-cookie";
import React from "react";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";

const campTagLines = [
  "Allergy camp",
  "Cardiac camp",
  "Diabetics camp",
  "General Health camp",
  "Gastro camp",
  "Multi-speciality camp",
  "Neuro camp",
  "Ortho camp",
  "Oncology Camp",
  "Pulmonology camp",
  "Others",
];
class CreateCampFrom extends React.Component {
  state = {
    createCampLoaderStatus: true,

    validStartDate: false,
    validEndDate: false,

    location: "",
    campLocationIsEmpty: false,
    campTag: "",
    campTagIsEmpty: false,

    startDate: "",
    startDateIsEmpty: false,
    endDate: "",
    endDateIsEmpty: false,

    campExecutivesList: [],
    filteredCampExecutivesList: [],
    campDoctorsList: [],
    filterCampDoctorsList: [],

    selectedExecutives: [],
    selectedDoctors: [],

    isAnyFieldsMissing: false,
    whatsappEnableStatus: 1,
    communityChecked: false,
    corporationChecked: false,
    camp_category: "",
    formSubmit: false,
    formList: [],
    selectedFormValue: "",
    selectedFormId: "",
  };

  fetchUsersFromApi = async () => {
    const jwt_token = Cookies.get("jwt_token");

    const url =
      "https://apollo-totalhealth-camp-ai.azurewebsites.net/users-list";

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    let campExeList = [];
    let campDoctorList = [];

    if (responseObj.status === "success") {
      responseObj.users_list.forEach((each) => {
        if (each.role === "executive" && each.user_status === true) {
          campExeList.push(each);
        } else if (each.role === "doctor" && each.user_status === true) {
          campDoctorList.push(each);
        }
      });

      this.setState({
        campExecutivesList: campExeList,
        filteredCampExecutivesList: campExeList,
        campDoctorsList: campDoctorList,
        filterCampDoctorsList: campDoctorList,
      });
    }
  };

  async componentDidMount() {
    await this.fetchUsersFromApi();
    await this.fetchFormApi();
  }

  fetchFormApi = async () => {
    const jwt_token = Cookies.get("jwt_token");
    const url =
      "https://apollo-totalhealth-camp-ai.azurewebsites.net/get_forms_list";
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      this.setState({
        formList: responseObj.forms,
        filteredFormList: responseObj.forms,
      }); // Assuming responseObj.data contains the list of forms
    }
  };

  generateUnqCampCode = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  formValidation = async (event) => {
    event.preventDefault();
    this.setState({ formSubmit: true });

    let { selectedExecutives, selectedDoctors } = this.state;

    selectedDoctors = selectedDoctors.map((each) => each.split("|")[0]);
    selectedExecutives = selectedExecutives.map((each) => each.split("|")[0]);
    const newCampObj = {
      camp_code: this.generateUnqCampCode(5),
      location: this.state.location,
      camp_tag: this.state.campTag,
      created_date: this.state.startDate,
      end_date: this.state.endDate,
      executive: selectedExecutives,
      doctor: selectedDoctors,
      whatsapp_enable_status: this.state.whatsappEnableStatus,
      camp_category: this.state.camp_category,
      form_id: this.state.selectedFormId,
    };

    if (
      newCampObj.location !== "" &&
      newCampObj.camp_tag !== "" &&
      newCampObj.created_date !== "" &&
      newCampObj.end_date !== "" &&
      newCampObj.camp_category !== "" &&
      newCampObj.executive.length > 0 &&
      newCampObj.form_id !== ""
    ) {
      const startDate = new Date(newCampObj.created_date);
      const endDate = new Date(newCampObj.end_date);

      if (
        startDate.getFullYear() <= endDate.getFullYear() &&
        startDate.getMonth() <= endDate.getMonth() &&
        (endDate.getMonth() > startDate.getMonth() ||
          startDate.getDate() <= endDate.getDate())
      ) {
        const popEle = document.getElementById("createCampPopup");
        popEle.classList.remove("d-none");
        const body = document.querySelector("body");
        body.style.overflow = "hidden";

        const jwt_token = Cookies.get("jwt_token");

        const url =
          "https://apollo-totalhealth-camp-ai.azurewebsites.net/create-camp";
        // const url = "https://apollo-totalhealth-camp-ai.azurewebsites.net/create-camp";
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${jwt_token}`);

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(newCampObj),
          redirect: "follow",
        };

        const response = await fetch(url, requestOptions);
        const responseObj = await response.json();

        if (responseObj.status === "success") {
          this.setState({
            createCampLoaderStatus: false,
          });
        }
      } else {
        this.setState({
          validStartDate: true,
          startDateIsEmpty: true,
          endDateIsEmpty: true,
          validEndDate: true,
        });
      }
    } else {
      this.setState({ isAnyFieldsMissing: true });
    }
  };

  updateLocation = (event) => {
    const value = event.target.value;
    this.setState({ location: value });
  };

  updateInput = (event) => {
    const value = event.target.value;
    const elementName = event.target.name;

    if (elementName === "camp-location") {
      this.setState({ location: value });
    } else if (elementName === "start-date") {
      const enterDate = new Date(value);
      const currentDate = new Date();

      if (
        enterDate.getFullYear() >= currentDate.getFullYear() &&
        enterDate.getMonth() >= currentDate.getMonth() &&
        (enterDate.getMonth() > currentDate.getMonth() ||
          enterDate.getDate() >= currentDate.getDate())
      ) {
        this.setState({
          startDateIsEmpty: false,
          validStartDate: false,
          startDate: value,
        });
      } else {
        event.target.value = "";
        this.setState({
          startDateIsEmpty: true,
          validStartDate: true,
        });
      }
    } else if (elementName === "end-date") {
      const enterDate = new Date(value);
      const currentDate = new Date();

      if (
        enterDate.getFullYear() >= currentDate.getFullYear() &&
        enterDate.getMonth() >= currentDate.getMonth() &&
        (enterDate.getMonth() > currentDate.getMonth() ||
          enterDate.getDate() >= currentDate.getDate())
      ) {
        this.setState({
          endDateIsEmpty: false,
          validEndDate: false,
          endDate: value,
        });
      } else {
        event.target.value = "";
        this.setState({
          endDateIsEmpty: true,
          validEndDate: true,
        });
      }
    }
  };

  isInputEmpty = (event) => {
    const elementName = event.target.name;
    const value = event.target.value;

    if (value === "") {
      if (elementName === "camp-location") {
        this.setState({ campLocationIsEmpty: true });
      } else if (elementName === "camp-tag") {
        this.setState({ campTagIsEmpty: true });
      } else if (elementName === "start-date") {
        this.setState({ startDateIsEmpty: true });
      } else if (elementName === "end-date") {
        this.setState({ endDateIsEmpty: true });
      }
    }
  };

  executiveTrigger = (event) => {
    const value = event.target.value;

    const element = document.getElementById("roleDropDown");

    let { selectedExecutives } = this.state;

    if (!selectedExecutives.includes(value)) {
      selectedExecutives.push(value);
      this.setState({
        selectedExecutives: selectedExecutives,
      });
    }
    element.style.display = "none";
  };

  removeExecutive = (event) => {
    const value = event.target.value;

    let { selectedExecutives } = this.state;

    selectedExecutives = selectedExecutives.filter((each) => each !== value);
    this.setState({
      selectedExecutives: selectedExecutives,
    });
  };

  doctorTrigger = (event) => {
    const value = event.target.value;

    const element = document.getElementById("doctorDropDown");

    let { selectedDoctors } = this.state;

    if (!selectedDoctors.includes(value)) {
      selectedDoctors.push(value);
      this.setState({
        selectedDoctors: selectedDoctors,
      });
    }
    element.style.display = "none";
  };

  triggeredFormValue = (event) => {
    const value = event.target.value;
    const Id = event.target.id;
    const element = document.getElementById("formDropDown");
    this.setState({
      selectedFormId: Id,
      selectedFormValue: value,
    });

    element.style.display = "none";
  };

  removeDoctor = (event) => {
    const value = event.target.value;

    let { selectedDoctors } = this.state;

    selectedDoctors = selectedDoctors.filter((each) => each !== value);
    this.setState({
      selectedDoctors: selectedDoctors,
    });
  };

  removeError = (event) => {
    const elementName = event.target.name;
    if (elementName === "camp-location") {
      this.setState({ campLocationIsEmpty: false });
    } else if (elementName === "camp-tag") {
      this.setState({ campTagIsEmpty: false });
    } else if (elementName === "start-date") {
      this.setState({ startDateIsEmpty: false });
    } else if (elementName === "end-date") {
      this.setState({ endDateIsEmpty: false });
    }
  };

  pushToCampsList = () => {
    const { history } = this.props;

    history.push("/camps-list");
  };

  removePopAndPushToCampList = () => {
    const { history } = this.props;

    const popEle = document.getElementById("createCampPopup");
    popEle.classList.add("d-none");
    const body = document.querySelector("body");
    body.style.overflow = "scroll";

    history.push("/camps-list");
  };

  filterExecutivesList = (event) => {
    const enteredValue = event.target.value.toLowerCase();

    const { campExecutivesList } = this.state;

    const filteredCampExecutivesList = campExecutivesList.filter((each) =>
      each.name.toLowerCase().includes(enteredValue)
    );
    this.setState({
      filteredCampExecutivesList: filteredCampExecutivesList,
    });
  };

  filterDoctorsList = (event) => {
    const enteredValue = event.target.value.toLowerCase();

    const { campDoctorsList } = this.state;

    const filterCampDoctorsList = campDoctorsList.filter((each) =>
      each.name.toLowerCase().includes(enteredValue)
    );
    this.setState({
      filterCampDoctorsList: filterCampDoctorsList,
    });
  };

  filterFormList = (event) => {
    const enteredValue = event.target.value.toLowerCase();

    const { formList } = this.state;

    const filteredFormList = formList.filter((each) =>
      each.form_name.toLowerCase().includes(enteredValue)
    );
    this.setState({
      filteredFormList: filteredFormList,
    });
  };

  renderExecutivesList = () => {
    const element = document.getElementById("roleDropDown");
    element.style.display = "block";
  };

  renderDoctorsList = () => {
    const element = document.getElementById("doctorDropDown");
    element.style.display = "block";
  };

  renderFormsList = () => {
    const element = document.getElementById("formDropDown");
    element.style.display = "block";
  };

  updateCampTagStatus = () => {
    const element = document.getElementById("campTagDropDown");
    const arrow = document.getElementById("campTagIcon");

    if (element.style.display === "block") {
      element.style.display = "none";
      arrow.style.transform = "rotate(0deg)";
    } else {
      element.style.display = "block";
      arrow.style.transform = "rotate(540deg)";
    }
  };

  updateCampTag = (event) => {
    this.setState({
      campTag: event.target.value,
    });

    this.updateCampTagStatus();
  };

  updateWhatsappEnableStatus = (event) => {
    this.setState({ whatsappEnableStatus: event.target.checked ? 1 : 0 });
  };

  handleCommunityChange = (event) => {
    this.setState((prevState) => ({
      communityChecked: !prevState.communityChecked,
      corporationChecked: false,
      camp_category: event.target.value,
    }));
  };
  handleCorporationChange = (event) => {
    this.setState((prevState) => ({
      corporationChecked: !prevState.corporationChecked,
      communityChecked: false,
      camp_category: event.target.value,
    }));
  };

  render() {
    const {
      campLocationIsEmpty,
      campTagIsEmpty,
      campTag,
      startDateIsEmpty,
      endDateIsEmpty,
      filteredCampExecutivesList,
      filterCampDoctorsList,
      createCampLoaderStatus,
      selectedExecutives,
      selectedDoctors,
      validStartDate,
      validEndDate,
      isAnyFieldsMissing,
      communityChecked,
      corporationChecked,
      camp_category,
      formSubmit,
      filteredFormList,
    } = this.state;

    return (
      <form className="create-camp-form" onSubmit={this.formValidation}>
        <div id="createCampPopup" className="pop-up-container d-none">
          <div className="pop-up-box">
            {createCampLoaderStatus === true ? (
              <Loader type="Oval" color="#FF883E" height={50} width={50} />
            ) : (
              <div className="success-container">
                <div className="tickmark-container">
                  <img
                    className="tickmark-icon"
                    src="./checkmark-outline.svg"
                    alt="tickmark-icon"
                  />
                </div>
                <p className="success-text">Camp Created Successfully</p>
                <button
                  className="button done-button"
                  onClick={this.removePopAndPushToCampList}
                >
                  Done
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="menu-icon-with-apollo-logo">
          <button className="button back-button" onClick={this.pushToCampsList}>
            <img src="./back-arrow.svg" alt="back-arrow.svg" />
          </button>
          <img
            className="small-apollo-logo"
            src="./logo.png"
            alt="apollo.png"
          />
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="large-back-button-container">
            <button
              className="button back-button"
              onClick={this.pushToCampsList}
            >
              <img src="./back-arrow.svg" alt="back-arrow.svg" />
            </button>
            <p className="large-back-button-text">Back</p>
          </div>
          <div className="health-camp-heading-container">
            <p className="apollo-hospitals-text">Apollo Hospitals</p>
            <h1 className="heading text-bold">Health Camps</h1>
            <div className="heading-style-element-container">
              <div className="style-line"></div>
              <div className="style-dot"></div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-40">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Create New Camp</h1>
            <img
              className="sub-heading-icon"
              src="./calendar-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Camp Name
            </label>
            <div
              className="input-with-logo cursor-pointer"
              onClick={this.updateCampTagStatus}
            >
              <input
                type="text"
                className="login-input pointer-event-none"
                name="camp-tag"
                value={campTag}
                readOnly
                placeholder="Select the camp name"
              />
              <img
                id="campTagIcon"
                className="icon"
                src="./chevron-down-outline.svg"
                alt="apollo.png"
              />
            </div>
            {campTagIsEmpty && (
              <p className="error-text">Please enter the Camp Name</p>
            )}
            <ul id="campTagDropDown" className="drop-down-option-container">
              {campTagLines.map((each, index) => (
                <li className="drop-down-option" key={index}>
                  <button
                    className="drop-down-option-button"
                    type="button"
                    onClick={this.updateCampTag}
                    value={each}
                  >
                    {each}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Location
            </label>
            <div className="input-with-logo">
              <input
                type="text"
                className="login-input"
                placeholder="Enter here"
                name="camp-location"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateLocation}
                autoComplete="off"
              />
              <img
                className="icon"
                src="./location-outline.svg"
                alt="apollo.png"
              />
              <img
                id="locationIcon"
                className="icon d-none"
                src="./close-outline.svg"
                alt="apollo.png"
                onClick={this.removeLocationDropDown}
              />
            </div>
            {campLocationIsEmpty && (
              <p className="error-text">Please enter the Location</p>
            )}
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="d-flex flex-column justify-content-between start-end-date-container">
            <div className="input-container width-100 margin-bottom-40">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                State Date
              </label>
              <div className="input-with-logo">
                <input
                  type="date"
                  className="login-input date-font-size padding-0 width-100"
                  placeholder=""
                  id="stateDate"
                  name="start-date"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.updateInput}
                />
              </div>
              {startDateIsEmpty &&
                (validStartDate ? (
                  <p className="error-text">
                    Please enter the valid Start Date
                  </p>
                ) : (
                  <p className="error-text">Please enter the Start Date</p>
                ))}
            </div>
            <div className="input-container width-100">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                End Date
              </label>
              <div className="input-with-logo">
                <input
                  type="date"
                  className="login-input date-font-size padding-0 width-100"
                  placeholder=""
                  id="endDate"
                  name="end-date"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.updateInput}
                />
              </div>
              {endDateIsEmpty &&
                (validEndDate ? (
                  <p className="error-text">Please enter the valid End Date</p>
                ) : (
                  <p className="error-text">Please enter the End Date</p>
                ))}
            </div>
          </div>
          <div className="input-container camp-category-container d-flex flex-column justify-content-between">
            <div className="margin-bottom-20">
              <label className="label margin-bottom-20">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Camp category
              </label>
              <div className="d-flex flex-row">
                <div className="checkboxs-container">
                  <div className="check-box-container ml-10">
                    <input
                      className="checkbox-input"
                      name="camp_category"
                      type="radio"
                      value="Community"
                      checked={communityChecked}
                      onChange={this.handleCommunityChange}
                    />
                    <span className="custom-checkbox custom-checkbox_border_radius">
                      <img
                        src="checkmark-outline-white.svg"
                        alt="checkmark-outline-white"
                      />
                    </span>
                    <label className="cursor-pointer camp-type-label light-color padding-top-bottom-none">
                      Community
                    </label>
                  </div>
                </div>
                <div className="checkboxs-container">
                  <div className="check-box-container">
                    <input
                      className="checkbox-input"
                      type="radio"
                      name="camp_category"
                      value="Corporate"
                      checked={corporationChecked}
                      onChange={this.handleCorporationChange}
                    />
                    <span className="custom-checkbox custom-checkbox_border_radius">
                      <img
                        src="checkmark-outline-white.svg"
                        alt="checkmark-outline-white"
                      />
                    </span>
                    <label className="cursor-pointer camp-type-label light-color padding-top-bottom-none">
                      Corporate
                    </label>
                  </div>
                </div>
              </div>
              {formSubmit && camp_category === "" && (
                <p className="error-text">Please enter the Cam Category</p>
              )}
            </div>
            <div className="margin-bottom-20">
              <label className="label margin-bottom-20">Camp Type</label>
              <div className="d-flex flex-row">
                <div className="checkboxs-container ml-10">
                  <div className="check-box-container">
                    <input
                      id="aicvd"
                      className="checkbox-input"
                      defaultChecked
                      type="checkbox"
                    />
                    <span className="custom-checkbox">
                      <img
                        src="checkmark-outline-white.svg"
                        alt="checkmark-outline-white"
                      />
                    </span>
                    <label
                      htmlFor="aicvd"
                      className="cursor-pointer camp-type-label light-color padding-top-bottom-none"
                    >
                      AICVD
                    </label>
                  </div>
                </div>
                <div className="checkboxs-container ml-10">
                  <div className="check-box-container">
                    <input
                      id="prediabetes"
                      className="checkbox-input"
                      defaultChecked
                      type="checkbox"
                    />
                    <span className="custom-checkbox">
                      <img
                        src="checkmark-outline-white.svg"
                        alt="checkmark-outline-white"
                      />
                    </span>
                    <label
                      htmlFor="prediabetes"
                      className="cursor-pointer camp-type-label light-color padding-top-bottom-none"
                    >
                      Pre-diabetes
                    </label>
                  </div>
                </div>
                <div className="checkboxs-container">
                  <div className="check-box-container">
                    <input
                      id="general"
                      className="checkbox-input"
                      type="checkbox"
                    />
                    <span className="custom-checkbox">
                      <img
                        src="checkmark-outline-white.svg"
                        alt="checkmark-outline-white"
                      />
                    </span>
                    <label
                      htmlFor="general"
                      className="cursor-pointer camp-type-label light-color padding-top-bottom-none"
                    >
                      General
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="input-container user-create-input">
            <div>
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Selected Executives
              </label>
              {selectedExecutives.length === 0 ? (
                <p className="none-text">None</p>
              ) : (
                <ul className="camp-selected-list-container">
                  {selectedExecutives.map((each, index) => (
                    <li className="camp-selected-item" key={index}>
                      <p className="camp-selected-item-text">
                        {index + 1}. {each.split("|")[1]}
                      </p>
                      <button
                        type="button"
                        value={each}
                        onClick={this.removeExecutive}
                        className="remove-button"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Choose Camp Executives
              </label>
              <div
                className="input-with-logo"
                onClick={this.renderExecutivesList}
              >
                <input
                  type="text"
                  className="login-input date-font-size padding-0 width-100"
                  placeholder="Search and select the executive"
                  onChange={this.filterExecutivesList}
                />
                <img className="icon" src="./search.svg" alt="apollo.png" />
              </div>
              <ul id="roleDropDown" className="drop-down-option-container">
                {filteredCampExecutivesList.map((each, index) => (
                  <li className="drop-down-option" key={index}>
                    <button
                      className="drop-down-option-button text-capital"
                      type="button"
                      onClick={this.executiveTrigger}
                      value={`${each.user_id}|${each.name}`}
                    >
                      {each.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="input-container user-create-input">
            <div>
              <label className="label">Selected Doctors</label>
              {selectedDoctors.length === 0 ? (
                <p className="none-text">None</p>
              ) : (
                <ul className="camp-selected-list-container">
                  {selectedDoctors.map((each, index) => (
                    <li className="camp-selected-item" key={index}>
                      <p className="camp-selected-item-text">
                        {index + 1}. {each.split("|")[1]}
                      </p>
                      <button
                        type="button"
                        value={each}
                        onClick={this.removeDoctor}
                        className="remove-button"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <label className="label">Choose Camp Doctors</label>
              <div className="input-with-logo" onClick={this.renderDoctorsList}>
                <input
                  type="text"
                  className="login-input date-font-size padding-0 width-100"
                  placeholder="Search and select the doctor"
                  onChange={this.filterExecutivesList}
                />
                <img className="icon" src="./search.svg" alt="apollo.png" />
              </div>
              <ul id="doctorDropDown" className="drop-down-option-container">
                {filterCampDoctorsList.map((each, index) => (
                  <li className="drop-down-option" key={index}>
                    <button
                      className="drop-down-option-button text-capital"
                      type="button"
                      onClick={this.doctorTrigger}
                      value={`${each.user_id}|${each.name}`}
                    >
                      {each.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="input-container min-height-30 user-create-input">
            <div>
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Choose the Form
              </label>
              <div className="input-with-logo" onClick={this.renderFormsList}>
                <input
                  type="text"
                  className="login-input date-font-size padding-0 width-100"
                  placeholder="Search and select the form"
                  value={this.state.selectedFormValue}
                />
                <img className="icon" src="./search.svg" alt="apollo.png" />
              </div>
              <ul id="formDropDown" className="drop-down-option-container">
                {filteredFormList?.map((each, index) => (
                  <li className="drop-down-option" key={index}>
                    <button
                      className="drop-down-option-button text-capital"
                      type="button"
                      onClick={this.triggeredFormValue}
                      value={each.form_name}
                      id={each.form_id}
                    >
                      {each.form_name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="input-container min-height-30 user-create-input">
            <div className="camp-label-check-box-container">
              <input
                id="whatsappInput"
                className="checkbox-input"
                defaultChecked
                type="checkbox"
                onChange={this.updateWhatsappEnableStatus}
              />
              <span className="custom-checkbox">
                <img
                  src="checkmark-outline-white.svg"
                  alt="checkmark-outline-white"
                />
              </span>
              <label htmlFor="whatsappInput" className="camp-checkbox-label">
                Reports through Whatsapp{" "}
                <span className="label-sub-text">(Automatic)</span>
              </label>
            </div>
          </div>
        </div>
        {isAnyFieldsMissing && (
          <p className="submit-error-text">
            Some mandatary filelds are missing...! Please do verify
          </p>
        )}
        <button className="button next-button" type="submit">
          <p className="">Submit</p>
        </button>
        <div className="end-line"></div>
        <p className="copy-rights-text">
          <span className="font-weight-700">
            Copyright @{new Date().getFullYear()} Powered By Apollo Hospitals.
          </span>
          <br /> All rights reserved
        </p>
      </form>
    );
  }
}

export default withRouter(CreateCampFrom);
