import Cookies from "js-cookie";
import React from "react";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";

class ReviewPage extends React.Component {
  state = {
    pageLoaderStatus: true,
    patientData: {},
    aicvdLabTest: [],
    aicvdDiaImg: [],
    doctorSelectedAcivdLabTests: [],
    doctorSelectedDiaImg: [],
    prediabetesLabTest: [],
    doctorSelectedPrediabetesLabTest: [],
    reviewLoader: true,
    confirmStatus: true,
    doctorReview: "",
  };

  pusToPatientList = () => {
    const { history } = this.props;

    history.push("/participant-list");
  };

  submitReview = async () => {
    let review = this.state.doctorReview;

    if (review === "") {
      review = this.state.patientData.review;
    }

    let {
      patientData,
      doctorSelectedAcivdLabTests,
      doctorSelectedDiaImg,
      doctorSelectedPrediabetesLabTest,
    } = this.state;

    let aicvdLabTest = JSON.parse(patientData.aicvd_lab_report);

    for (let key in aicvdLabTest.LabInvestigationRecommended) {
      if (!doctorSelectedAcivdLabTests.includes(key)) {
        aicvdLabTest.LabInvestigationRecommended[key] = "No";
      }
    }

    for (let key of doctorSelectedAcivdLabTests) {
      aicvdLabTest.LabInvestigationRecommended[key] = "Yes";
    }

    for (let key in aicvdLabTest.DiagnosticsAndImagingRecommended) {
      if (!doctorSelectedDiaImg.includes(key)) {
        aicvdLabTest.DiagnosticsAndImagingRecommended[key] = "No";
      }
    }

    for (let key of doctorSelectedDiaImg) {
      aicvdLabTest.DiagnosticsAndImagingRecommended[key] = "Yes";
    }

    let prediabetesLabTest = {};

    if (patientData.p_diabetes === "no") {
      prediabetesLabTest = JSON.parse(patientData.prediabetes_lab_report).data;
      prediabetesLabTest.lab_investigation =
        doctorSelectedPrediabetesLabTest.join(", ");
    }

    const id = Cookies.get("id");
    const url =
      "https://apollo-totalhealth-camp-ai.azurewebsites.net/doctor-review";
    const jwt_token = Cookies.get("jwt_token");

    const raw = {
      id,
      review,
      aicvd_updated_report: JSON.stringify(aicvdLabTest),
      prediabetes_updated_report: JSON.stringify({
        data: prediabetesLabTest,
      }),
    };

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      this.setState({
        reviewLoader: false,
      });
    }
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const id = Cookies.get("id");

    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/patient-data?id=${id}`;
    const jwt_token = Cookies.get("jwt_token");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      let aicvdLabTest = [];
      let lowerCaseAicvdLabTest = [];
      let aicvdDiaImg = [];

      const aicvdLabReport = JSON.parse(
        responseObj.patient_data.aicvd_lab_report
      );

      const allLabInvestigation = aicvdLabReport.LabInvestigationRecommended;
      for (let key in allLabInvestigation) {
        if (allLabInvestigation[key] === "Yes") {
          aicvdLabTest.push(key);
          lowerCaseAicvdLabTest.push(key.toLowerCase());
        }
      }

      const aicvdDiaImgReport = aicvdLabReport.DiagnosticsAndImagingRecommended;

      for (let key in aicvdDiaImgReport) {
        if (aicvdDiaImgReport[key] === "Yes") {
          aicvdDiaImg.push(key);
        }
      }

      let prediabetesLabTest = [];

      if (responseObj.patient_data.p_diabetes === "no") {
        const prediabteLabReport = JSON.parse(
          responseObj.patient_data.prediabetes_lab_report
        );
        const reportPrediabetesLabTests =
          prediabteLabReport.data.lab_investigation;

        for (let item of reportPrediabetesLabTests.split(",")) {
          item = item.trim();
          if (!lowerCaseAicvdLabTest.includes(item.toLowerCase())) {
            prediabetesLabTest.push(item);
          }
          if (
            item.includes("Fasting Blood Sugar") &&
            lowerCaseAicvdLabTest.includes("fasting blood sugar")
          ) {
            prediabetesLabTest.pop();
          }
        }
      }

      this.setState({
        pageLoaderStatus: false,
        patientData: responseObj.patient_data,
        doctorReview: responseObj.patient_data.review,
        aicvdLabTest: aicvdLabTest,
        doctorSelectedAcivdLabTests: [...aicvdLabTest],
        aicvdDiaImg: aicvdDiaImg,
        doctorSelectedDiaImg: [...aicvdDiaImg],
        prediabetesLabTest: prediabetesLabTest,
        doctorSelectedPrediabetesLabTest: [...prediabetesLabTest],
      });
    }
  };
  removeReviewPopUp = () => {
    const reviewPopupEl = document.getElementById("reviewPopup");
    const body = document.querySelector("body");

    reviewPopupEl.classList.add("d-none");
    body.style.overflow = "scroll";

    const { history } = this.props;
    history.push("/participant-list");
  };

  addReviewPopUp = () => {
    const reviewPopupEl = document.getElementById("reviewPopup");
    const body = document.querySelector("body");

    reviewPopupEl.classList.remove("d-none");
    body.style.overflow = "hidden";
  };

  cancelButtonClicked = () => {
    const element = document.getElementById("reviewPopup");
    const body = document.querySelector("body");

    element.classList.add("d-none");
    body.style.overflow = "scroll";
  };

  aicvdLabTestsTrigger = (event) => {
    const value = event.target.value;

    let { doctorSelectedAcivdLabTests } = this.state;

    if (doctorSelectedAcivdLabTests.includes(value)) {
      let index = -1;

      for (let item in doctorSelectedAcivdLabTests) {
        if (doctorSelectedAcivdLabTests[item] === value) index = item;
      }

      doctorSelectedAcivdLabTests.splice(index, 1);
      this.setState({
        doctorSelectedAcivdLabTests: doctorSelectedAcivdLabTests,
      });
    } else {
      doctorSelectedAcivdLabTests.push(value);
      this.setState({
        doctorSelectedAcivdLabTests: doctorSelectedAcivdLabTests,
      });
    }
  };

  aicvdDiaImgTrigger = (event) => {
    const value = event.target.value;

    let { doctorSelectedDiaImg } = this.state;

    if (doctorSelectedDiaImg.includes(value)) {
      let index = -1;

      for (let item in doctorSelectedDiaImg) {
        if (doctorSelectedDiaImg[item] === value) index = item;
      }

      doctorSelectedDiaImg.splice(index, 1);
      this.setState({
        doctorSelectedDiaImg: doctorSelectedDiaImg,
      });
    } else {
      doctorSelectedDiaImg.push(value);
      this.setState({
        doctorSelectedDiaImg: doctorSelectedDiaImg,
      });
    }
  };

  prediabetesLabTestTrigger = (event) => {
    const value = event.target.value;

    let { doctorSelectedPrediabetesLabTest } = this.state;

    if (doctorSelectedPrediabetesLabTest.includes(value)) {
      let index = -1;

      for (let item in doctorSelectedPrediabetesLabTest) {
        if (doctorSelectedPrediabetesLabTest[item] === value) index = item;
      }

      doctorSelectedPrediabetesLabTest.splice(index, 1);
      this.setState({
        doctorSelectedPrediabetesLabTest: doctorSelectedPrediabetesLabTest,
      });
    } else {
      doctorSelectedPrediabetesLabTest.push(value);
      this.setState({
        doctorSelectedPrediabetesLabTest: doctorSelectedPrediabetesLabTest,
      });
    }
  };

  updateNewLabTest = () => {
    let { aicvdLabTest, doctorSelectedAcivdLabTests } = this.state;
    const value = document.getElementById("aiLabTestInput").value;
    if (value !== "") {
      aicvdLabTest.push(value);
      doctorSelectedAcivdLabTests.push(value);
      this.setState({
        aicvdLabTest: aicvdLabTest,
        doctorSelectedAcivdLabTests: doctorSelectedAcivdLabTests,
      });
      document.getElementById("aiLabTestInput").value = "";
    }
  };

  updateNewDiaImgTest = () => {
    let { aicvdDiaImg, doctorSelectedDiaImg } = this.state;
    const value = document.getElementById("diaImgTestInput").value;
    if (value !== "") {
      aicvdDiaImg.push(value);
      doctorSelectedDiaImg.push(value);
      this.setState({
        aicvdDiaImg: aicvdDiaImg,
        doctorSelectedDiaImg: doctorSelectedDiaImg,
      });
      document.getElementById("diaImgTestInput").value = "";
    }
  };

  updateDoctorReview = (event) => {
    this.setState({
      doctorReview: event.target.value,
    });
  };

  confirmButtonClicked = () => {
    this.setState({
      confirmStatus: false,
    });
    this.submitReview();
  };

  render() {
    const {
      patientData,
      reviewLoader,
      aicvdDiaImg,
      confirmStatus,
      aicvdLabTest,
      doctorReview,
      prediabetesLabTest,
    } = this.state;
    const riskProfile = parseFloat(
      (patientData.aicvdscore / patientData.aicvdoptimal).toFixed(2)
    );

    let aicvdBgColor = "";
    if (patientData.aicvdcat === "High Risk") aicvdBgColor = "high-risk";
    else if (
      patientData.aicvdcat === "Medium Risk" ||
      patientData.aicvdcat === "Mid Risk"
    )
      aicvdBgColor = "medium-risk";
    else aicvdBgColor = "low-risk";

    let prediabetesBgColor = "";
    if (patientData.p_diabetes === "no") {
      if (patientData.diarisk === "High Risk") prediabetesBgColor = "high-risk";
      else if (
        patientData.diarisk === "Medium Risk" ||
        patientData.diarisk === "Mid Risk"
      )
        prediabetesBgColor = "medium-risk";
      else prediabetesBgColor = "low-risk";
    }

    return (
      <div className="report-container">
        <div id="reviewPopup" className="pop-up-container d-none">
          {confirmStatus ? (
            <div className="delete-container">
              <p className="delete-text">
                Please <span className="confirm-text">CONFIRM</span> to save the
                Review.
              </p>
              <div className="delete-button-container">
                <button
                  className="button cancel-button"
                  type="button"
                  onClick={this.cancelButtonClicked}
                >
                  Cancel
                </button>
                <button
                  className="button confirm-button"
                  type="button"
                  onClick={this.confirmButtonClicked}
                >
                  Confirm
                </button>
              </div>
            </div>
          ) : (
            <div className="pop-up-box">
              {reviewLoader === true ? (
                <Loader type="Oval" color="#FF883E" height={50} width={50} />
              ) : (
                <div className="success-container">
                  <div className="tickmark-container">
                    <img
                      className="tickmark-icon"
                      src="./checkmark-outline.svg"
                      alt="tickmark.png"
                    />
                  </div>
                  <p className="success-text">Review has Noted</p>
                  <button
                    className="button done-button"
                    onClick={this.removeReviewPopUp}
                  >
                    Done
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="menu-icon-with-apollo-logo">
          <button
            className="button back-button"
            onClick={this.pusToPatientList}
          >
            <img src="./back-arrow.svg" alt="back-arrow.svg" />
          </button>
          <img
            className="small-apollo-logo"
            src="./logo.png"
            alt="apollo.png"
          />
        </div>
        <div className="large-back-button-container">
          <button
            className="button back-button"
            onClick={this.pusToPatientList}
          >
            <img src="./back-arrow.svg" alt="back-arrow.svg" />
          </button>
          <p className="large-back-button-text">Back</p>
        </div>
        <div className="health-camp-heading-container">
          <p className="apollo-hospitals-text">Apollo Hospitals</p>
          <h1 className="heading text-bold">Health Camps</h1>
          <div className="heading-style-element-container">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Participant Info</h1>
            <img
              className="sub-heading-icon"
              src="./person-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="participant-info-container">
          <div className="info-line-container">
            <p className="line-name">Participant ID</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.uhid}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Name</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.name}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Age</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.age}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Gender</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.gender}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Location</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.location}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Phone</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.mobile}</p>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Past History</h1>
            <img
              className="sub-heading-icon"
              src="./receipt-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="participant-info-container">
          <div className="info-line-container">
            line-name
            <p className="line-name">Diabetes</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.p_diabetes}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Heart Disease</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.p_heart_disease}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Hypertesion</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.p_hypertension}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Dyslipidemia</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.p_dyslipidemia}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Anemia</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.p_anemia}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Cancer</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.p_cancer}</p>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Family History</h1>
            <img
              className="sub-heading-icon"
              src="./people-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="participant-info-container">
          <div className="info-line-container">
            <p className="line-name">Diabetes</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.f_diabetes}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Heart Disease</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.f_heart_disease}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Hypertesion</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.f_hypertension}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Dyslipidemia</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.f_dyslipidemia}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Anemia</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.f_anemia}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Cancer</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.f_cancer}</p>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Lifestyle</h1>
            <img
              className="sub-heading-icon"
              src="./people-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="participant-info-container">
          <div className="info-line-container">
            <p className="line-name">Diet</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.diet}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Alcohol</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.alcohol}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Smoking</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.smoking}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Tobacco</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.tobacco}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Physical Activity</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.physical_activity}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Psychological stress</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.psychological_stress}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Past medical history</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.past_medical_history}</p>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Vitals</h1>
            <img
              className="sub-heading-icon"
              src="./receipt-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="participant-info-container">
          <div className="info-line-container ">
            <p className="line-name ">Height</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.height}</p>
          </div>
          <div className="info-line-container ">
            <p className="line-name ">Weight</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.weight}</p>
          </div>
          <div className="info-line-container ">
            <p className="line-name ">BMI</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.bmi}</p>
          </div>
          <div className="info-line-container ">
            <p className="line-name ">Heart Rate</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.heart_rate}</p>
          </div>
          <div className="info-line-container ">
            <p className="line-name ">Systolic BP</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.systolic_bp}</p>
          </div>
          <div className="info-line-container ">
            <p className="line-name ">Diastolic BP</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.diastolic_bp}</p>
          </div>
          <div className="info-line-container ">
            <p className="line-name ">Spo2</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.spo2}</p>
          </div>
          <div className="info-line-container ">
            <p className="line-name ">Temperature</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.temperature}</p>
          </div>
          <div className="info-line-container ">
            <p className="line-name ">RBS</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.RBS}</p>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Cardiac Info</h1>
            <img
              className="sub-heading-icon"
              src="./fitness-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="participant-info-container">
          <div className="info-line-container">
            <p className="line-name">Chest Pain</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.chest_pain}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Pulse Rhythm</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.pulse_rhythm}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Respiration Rate</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.rate_of_respiration}</p>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">AICVD Report</h1>
            <img
              className="sub-heading-icon"
              src="./fitness-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>

        <div className="participant-info-container">
          <div className="info-line-container">
            <p className="line-name">Risk Status</p>
            <p className="colon">:</p>
            <p className="line-value">
              <span className={`review-risk-status-box ${aicvdBgColor}`}>
                {patientData.aicvdcat}
              </span>
            </p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Risk Score</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.aicvdscore}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Acceptable Risk</p>
            <p className="colon">:</p>
            <p className="line-value">{patientData.aicvdoptimal}</p>
          </div>
          <div className="info-line-container">
            <p className="line-name">Risk Profile</p>
            <p className="colon">:</p>
            <p className="line-value">{riskProfile}x</p>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Prediabetes Report</h1>
            <img
              className="sub-heading-icon"
              src="./eyedrop-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        {patientData.p_diabetes === "no" ? (
          <div className="participant-info-container">
            <div className="info-line-container">
              <p className="line-name">Risk Status</p>
              <p className="colon">:</p>
              <p className="line-value">
                <span
                  className={`review-risk-status-box ${prediabetesBgColor}`}
                >
                  {patientData.diarisk}
                </span>
              </p>
            </div>
            <div className="info-line-container">
              <p className="line-name">Risk Score</p>
              <p className="colon">:</p>
              <p className="line-value">{patientData.diascore}</p>
            </div>
          </div>
        ) : (
          <p className="diabetes-note-text">
            <span className="diabetes-note-title">Note: </span>Participants with
            a confirmed diagnosis of Diabetes shall not have Prediabetes Risk.
          </p>
        )}
        <div className="d-flex flex-row align-items-center margin-bottom-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Report Review</h1>
            <img
              className="sub-heading-icon"
              src="./create-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>

        <div className="participant-info-container doctor-selected-box">
          <div className="info-line-container d-flex flex-column align-items-start">
            <p className="custom-lab-label d-flex flex-column">
              AI Based Laboratory Investigations Recommendations
              <span className="sub-text">
                (This is based on your AI Scores for AICVD and Prediabetes
                Scanner)
              </span>
            </p>
            <ul className="custom-patient-report-test-container">
              {aicvdLabTest.map((each, index) => (
                <li className="report-test-item" key={index}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    id={`aicvdLab${index}`}
                    defaultChecked={true}
                    value={each}
                    onChange={this.aicvdLabTestsTrigger}
                  />
                  <span className="c-checkbox">
                    <img
                      className="icon"
                      src="checkmark-outline-white.svg"
                      alt="clipart.png"
                    />
                  </span>
                  <label
                    className="checkbox-label"
                    htmlFor={`aicvdLab${index}`}
                  >
                    {each}
                  </label>
                </li>
              ))}
              {prediabetesLabTest.map((each, index) =>
                each !== "" ? (
                  <li className="report-test-item" key={index}>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id={`prediabetesLab${index}`}
                      value={each}
                      defaultChecked={true}
                      onChange={this.prediabetesLabTestTrigger}
                    />
                    <span className="c-checkbox">
                      <img
                        className="icon"
                        src="checkmark-outline-white.svg"
                        alt="clipart.png"
                      />
                    </span>
                    <label
                      className="checkbox-label"
                      htmlFor={`prediabetesLab${index}`}
                    >
                      {each}
                    </label>
                  </li>
                ) : null
              )}
            </ul>
            <div className="input-container user-create-input mt-auto">
              <div className="input-with-logo">
                <input
                  type="text"
                  className="login-input dark-placeholder"
                  placeholder="Add Further Tests (Physician's Discretion)"
                  autoComplete="off"
                  id="aiLabTestInput"
                />
                <button
                  className="test-add-button"
                  onClick={this.updateNewLabTest}
                >
                  <img
                    className="icon"
                    src="./add-outline.svg"
                    alt="apollo.png"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="info-line-container d-flex flex-column align-items-start">
            <p className="custom-lab-label">
              AICVD Diagnostics And Imaging Recommended
            </p>
            <ul className="custom-patient-report-test-container">
              {aicvdDiaImg.map((each, index) => (
                <li className="report-test-item" key={index}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    id={`aicvdDiaIma${index}`}
                    value={each}
                    defaultChecked={true}
                    onChange={this.aicvdDiaImgTrigger}
                  />
                  <span className="c-checkbox">
                    <img
                      className="icon"
                      src="checkmark-outline-white.svg"
                      alt="clipart.png"
                    />
                  </span>
                  <label
                    className="checkbox-label"
                    htmlFor={`aicvdDiaIma${index}`}
                  >
                    {each}
                  </label>
                </li>
              ))}
            </ul>
            <div className="input-container user-create-input mt-auto">
              <div className="input-with-logo">
                <input
                  type="text"
                  className="login-input dark-placeholder"
                  placeholder="Add Further Tests (Physician's Discretion)"
                  autoComplete="off"
                  id="diaImgTestInput"
                />
                <button
                  className="test-add-button"
                  onClick={this.updateNewDiaImgTest}
                >
                  <img
                    className="icon"
                    src="./add-outline.svg"
                    alt="apollo.png"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="info-line-container d-flex flex-column align-items-start">
            <textarea
              id="doctorReview"
              className="text-area"
              value={doctorReview}
              placeholder="Participant reivew here..."
              onChange={this.updateDoctorReview}
            ></textarea>
            <input
              type="file"
              className="file-input mb-25"
              disabled
              title="Currently not working"
            />
          </div>
        </div>
        <button
          className="button next-button"
          type="button"
          onClick={this.addReviewPopUp}
        >
          Submit
        </button>
        <div className="end-line"></div>
        <p className="copy-rights-text">
          <span className="font-weight-700">
            Copyright @{new Date().getFullYear()} Powered By Apollo Hospitals.
          </span>
          <br /> All rights reserved.
        </p>
      </div>
    );
  }
}

export default withRouter(ReviewPage);
