import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import "./index.css";

const SuccessPopup = ({ link }) => {
  const [visible, setVisible] = useState(true);
  const [confettiVisible, setConfettiVisible] = useState(true);

  useEffect(() => {

    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000); // Adjust this duration for testing

    const confettiTimer = setTimeout(() => {
      setConfettiVisible(false);
    }, 5000); // Confetti duration

    return () => {
      clearTimeout(timer);
      clearTimeout(confettiTimer);
    };
  }, [link]);






  return (
    visible && (
      <>
        <div className="popup">
          <h1>🎉 Form Published Successfully🎉</h1>
        </div>
        {confettiVisible && <Confetti className="blast-background" />}
      </>
    )
  );
};

export default SuccessPopup;
