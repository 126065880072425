// import React from "react";

// const ScrollDialog=()=>{
//   return(
//     <>
//     <div>
//       <h1 className="color-text">
//           Sowjanya
//       </h1>
//     </div>
//     </>
//   )
// }

// export default ScrollDialog

import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import "./index.css";

export default function ScrollDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <div className="background-color">
        <Button onClick={handleClickOpen("paper")}>
          PATIENT INFORMATION SHEET AND INFORMED CONSENT{" "}
          <OpenInNewIcon fontSize="small" />
        </Button>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          PATIENT INFORMATION SHEET AND INFORMED CONSENT
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div className="d-flex flex-column font-14">
              <h1 className="content-header-text m-auto mb-30">
                STUDY PARTICIPANT INFORMATION SHEET
              </h1>
              <div>
                <p className="mb-10">
                  <span className="consent-sub-heading">STUDY TITLE:</span> A
                  Multicentre Prospective Observational Study on Cardiovascular
                  Risk Factors to build a Standardized Predictive Risk Score for
                  Indian Population
                </p>
              </div>
              <div>
                <p className="mb-10">
                  <span className="consent-sub-heading">INSTRUCTIONS:</span>{" "}
                  Please read and understand the information given below. While
                  reading the document and during the course of the study, you
                  are free to ask any study related question. Your question will
                  be answered and required study related clarification will also
                  be provided. A signed Xerox copy of this document will be
                  provided to you.
                </p>
                <ul className="margin-left list-of-instructions">
                  <li>
                    <p className="consent-sub-heading mb-10">
                       Introduction:
                    </p>
                    You are being invited to take part in the research study
                    which is done as a part of identifying risk factors for
                    Cardiovascular Diseases in Indian Population. The details of
                    the study are described below for your understanding. If you
                    have any queries/clarifications feel free to ask me.
                  </li>
                  <li>
                    <p className="consent-sub-heading mb-10">Background:</p>
                    <p className="mb-10">
                      <span className="consent-sub-heading">Overview:</span>{" "}
                      Cardiovascular diseases account for 30% deaths in India.
                      There is a need to conduct a study project on patient data
                      to determine, & interpret risk factors associated with
                      Cardiovascular Diseases(CVDs) in Indian Population.
                    </p>
                    <p className="mb-10">
                      <span className="consent-sub-heading">Goal:</span> The
                      goal of the study is to determine relevant Risk Factors
                      associated with Cardiac Disease, develop CVD risk score
                      prediction model and integrate this in Healthcare
                      Organizations’ Electronic Medical Record.
                    </p>
                    <p className="mb-10">
                      <span className="consent-sub-heading">Methodology:</span>{" "}
                      The retrospective data analysis arm of the study included
                      31,599 participants aged between 18 to 91 between year
                      from 2010 to 2017 from Apollo Hospitals. A multi-step risk
                      factors selection process was used to build the prediction
                      model. The model was built with clinical and life style
                      related risk factors including Hypertension, Diabetes,
                      Blood Pressure, BMI, Diet, Physical Activity, Smoking and
                      Tobacco preferences.
                    </p>
                    <p className="mb-10">
                      <span className="consent-sub-heading">Inferences:</span>{" "}
                      Study was consistent with International Studies on higher
                      significance for risk factors like Diabetes, Hypertension,
                      Diastolic Blood Pressure & Chewing Tobacco on Indian
                      Population.
                    </p>
                  </li>
                </ul>
                <ol className="list-of-instructions margin-left ">
                  <li>
                    <p>The Purpose of the study?</p>
                    <p>
                      To determine a Standardized Risk Score for Indian
                      Population
                    </p>
                  </li>
                  <li>
                    <p>Who can take part in the study?</p>
                    <div>
                      <p>Any individual aged between 18-79 years</p>
                      <p>
                        Willing to provide informed consent and comply with
                        study procedure Presenting for Routine Health Check
                        (Asymptomatic)
                      </p>
                      <p>
                        Presenting with Cardiovascular, Respiratory, General
                        Weakness, Abnormal Lab Values or Imaging Tests
                        (Symptomatic)
                      </p>
                    </div>
                  </li>
                  <li>
                    <p>Duration of Your participation</p>
                    <p>You will be followed up for a period of 5 Years</p>
                  </li>
                  <li>
                    <p>Total No. of study participants:</p>
                    <p>The total number of study participants are 6100</p>
                  </li>
                  <li>
                    <p>Possible benefits of taking part in this study?</p>
                    <div>
                      <p>
                        Understand the different Cardiovascular Risk Factors
                      </p>
                      <p>
                        Determine your current and prospective scores (during
                        follow up and / or annual visits){" "}
                      </p>
                      <p>
                        Determine your modifiable risk factors and a guidance
                        how this can be reduced through therapeutic intervention
                        and lifestyle management
                      </p>
                    </div>
                  </li>
                  <li>
                    <p>
                      Possible disadvantage or risks of taking part in this
                      study
                    </p>
                    <div>
                      <p> Long term – 5 years follow up</p>
                      <p>
                        Annual or repeat visits for Health Check or Outpatient
                        Consultation
                      </p>
                    </div>
                  </li>
                  <li>
                    <p>Study procedure:</p>
                    <div>
                      <p>
                        During the visit for the Health Check Up or Outpatient
                        Consultation, your Investigator (doctor) shall review
                        the following information –
                      </p>
                      <div className="body-instructions">
                        <p>
                          <span>1. Age:</span>Your Age in years.
                        </p>
                        <p>
                          <span>2. Gender:</span>Your Gender - Male/Female.
                        </p>
                        <p>
                          3. Systolic Blood Pressure: The blood pressure when
                          the heart is contracting. It is specifically the
                          maximum arterial pressure during contraction of the
                          left ventricle of the heart.
                        </p>
                        <p>
                          4. Diastolic Blood Pressure: The diastolic pressure is
                          specifically the minimum arterial pressure during
                          relaxation and dilatation of the ventricles of the
                          heart when the ventricles fill with blood.
                        </p>
                        <p>
                          5. BMI: Body mass Index, calculated based on Height
                          and Weight. (Weight in Kgs)/(Height in Mtr)^2
                        </p>
                        <p>
                          6.Diet: To determine, if you eat Vegetarian, Non
                          Vegetarian, Mixed (both vegetarian and
                          non-vegetarian).
                        </p>
                        <p>
                          7.Alcohol: To determine, if you are currently drinking
                          Alcohol or in past or do not drink.
                        </p>
                        <p>
                          8.Smoking: To determine, if you are smoking currently
                          or in past or do not smoke.
                        </p>
                        <p>
                          9.Tobacco: To determine, if you are chewing tobacco
                          currently or in past or do not chew.
                        </p>
                        <p>
                          10. Physical Activity: To determine, if you are
                          Physically Active in following categories: Mild,
                          Moderate, Active, Not active. FH of
                        </p>
                        <p>
                          11. Heart Disease: To determine, if your parents or
                          siblings had cardiovascular disease or not.
                        </p>
                        <p>
                          12. History of Heart Disease: To determine, if you had
                          a heart disease previously or not.
                        </p>
                        <p>
                          13. Hypertension Medication: To determine, if you are
                          taking medication for hypertension or not.
                        </p>
                        <p>
                          14.Diabetes : To determine, if you have controlled,
                          uncontrol or no diabetes.
                        </p>
                        <p>
                          15. Dyslipidaemia: Dyslipidaemia, defined as elevated
                          total or low-density lipoprotein (LDL) cholesterol
                          levels, or low levels of high-density lipoprotein
                          (HDL) cholesterol. Currently capture if a person has
                          dyslipidaemia or not.
                        </p>
                        <p>
                          16. Heart Rate: Number of Heart Beats per minute.
                          Heart Rhythm: Heart Rhythm if it is Regularly Regular,
                          Regularly Irregular, Irregularly Regular or
                          Irregularly Irregular.
                        </p>
                        <p>
                          17.Rate of Respiration: Number of Breaths per Minute.
                          Chronic
                        </p>
                        <p>
                          18.Heart Symptom: If you have recurring Heart Disease
                          related symptom like: Chest Pain, Breathlessness etc.
                        </p>
                        <p>19. Weight: Your Weight in Kg.</p>
                        <p>20. Height: Your Height in cm.</p>
                        <p>
                          21. Psychological Stress - To determine, if you have
                          any of the following - No Stress Negative Thought
                          Patterns and Emotions Depressive Syndromes Anxiety
                          Syndromes Chronic Stress
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <p>Collection of data:</p>
                    <p>
                      Your data shall be collected in a standardized electronic
                      format
                    </p>
                  </li>
                  <li>
                    <p>Confidentiality of data :</p>
                    <div>
                      <p>1. Confidentiality:</p>
                      <p className="margin-left margin-bottom-5">
                        The organization and the Investigator shall maintain the
                        confidentiality of data, especially with respect to the
                        information about the patient or any other individual
                        sharing their health information, which would otherwise
                        be known only to the physician or care givers. The data
                        shall be secured & stored in the Electronic and Manual
                        Medical Records.
                      </p>
                    </div>
                    <div>
                      <p>2. Data Content:</p>
                      <p className="margin-left margin-bottom-5">
                        Your data shall include the demographic & registration
                        details, clinical data – personal & family history,
                        assessment details, treatment and medication details,
                        investigations like lab diagnosis, diagnostic imaging
                        and other invasive procedures.
                      </p>
                    </div>
                    <div>
                      <p>3. Source of Data:</p>
                      <p className="margin-left margin-bottom-5">
                        The source of your data shall include the previous
                        records, results of the assessment and diagnostic &
                        treatment procedures. The source may be from the
                        organization, yourself, your family & friends (e.g. next
                        of kin) and other external sources like lab, diagnostic
                        or treatment facilities. The data are generated and
                        shared with the organization during the processes of
                        registration, consultation and ongoing treatment at the
                        different locations in the organization.
                      </p>
                    </div>
                    <div>
                      <p>4. Data sharing for research purposes:</p>
                      <p className="margin-left margin-bottom-5">
                        The organization and the Investigator shall use your
                        data, following anonymization and de – identification of
                        the individual for research purposes for retrospective
                        and prospective studies.
                      </p>
                    </div>
                    <div>
                      <p>5. Conformation to Laws & Regulation:</p>
                      <p className="margin-left margin-bottom-5">
                        The organization and the Investigator shall conform to
                        the national laws and internal guidelines on collection,
                        security & storage and sharing of the data – Indian
                        Council of Medical Research & Drug Controller General of
                        India guidelines, General Data Protection Regulation
                        2016 (European Union), World Health Organization and
                        Food and Drug Administration (United States). The
                        Institutional Ethics Committee of respective research
                        sites, where data are used and analysed for research
                        purpose, are accredited by National Accreditation Board
                        for Hospitals and Healthcare Providers (India).
                      </p>
                    </div>
                    <div>
                      <p>6. Data Sharing with other Organizations:</p>
                      <p className="margin-left margin-bottom-5">
                        Data Sharing with other Organizations - The organization
                        and the Investigator shall not share data with other
                        organizations unless required under law and following
                        appropriate contract to institutions, where the data
                        isn’t stored in facilities or servers outside the
                        country abiding by the national laws. All data are
                        appropriately encrypted meeting necessary specification
                        and shared with anonymity
                      </p>
                    </div>
                    <div>
                      <p>7. Withdrawal of Consent for Sharing Data:</p>
                      <p className="margin-left margin-bottom-5">
                        You may choose to withdraw your consent to share your
                        data for research purposes which would be respected by
                        the Organization and the Investigator
                      </p>
                    </div>
                  </li>
                  <li>
                    <p>
                      Signing of the consent form and keeping a copy of the
                      Patient Information Sheet:
                    </p>
                    <p>
                      On agreeing to participate in the study, you may sign the
                      consent form with your legally accepted representative.
                      The investigators shall provide a copy of Patient
                      Information in Informed Consent Form to Study Subjects and
                      Versions of the Informed Consent Form
                    </p>
                  </li>
                  <li>
                    <p>
                      What will happen to the results of the research/trial
                      study?
                    </p>
                    <p>
                      The results of the study shall be published and shall be
                      available for public use as Standardized Cardiovascular
                      Risk Score for Indian Population. You will not be
                      identified in any report/publication.
                    </p>
                  </li>
                  <li>
                    <p>
                       Who is organizing the study? Are there any
                      compensation for participation?
                    </p>
                    <p>
                      The study is organized by Microsoft and Apollo Hospitals
                      As this study is an observational, prospective data
                      collection study, there are no allocated compensation. You
                      would have to pay for the visit to the investigator,
                      including your tests and investigations, travel to the
                      hospital or clinic, any further medication or intervention
                      required for your treatment.
                    </p>
                  </li>
                  <li>
                    <p>Who has reviewed the study?</p>
                    <p>
                      The Institutional Ethics Committee has reviewed and
                      approved the study
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
