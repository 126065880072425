import React from "react";

const TextAreaField = ({ placeholder, type, value, id, name, onChange }) => {
  return (
    <div key={id} className="input-field-container">
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        className="input-field"
        onChange={(e) => onChange(name, e.target.value)}
      />
      <input
        className={`${
          placeholder === "Short Text"
            ? "short-text-disabled-input"
            : "long-text-disabled-input"
        }`}
        disabled
        value={placeholder}
        type={type}
      />
    </div>
  );
};

export default TextAreaField;
