import React from "react";
import { withRouter } from "react-router";
import Cookies from "js-cookie";
import Loader from "react-loader-spinner";
import ScrollDialog from "../termsform/terms-modal";

class NewPatientForm extends React.Component {
  state = {
    gender: "",
    covidInfection: "no",
    covidVaccineName: "Select",
    covidVaccinated: "no",
    noOfDose: "Select",
    physicalActivity: "Select",
    psychologicalStreee: "Select",
    pulseRhythm: "Select",
    pulseRhythmIsEmpty: false,
    chestPain: "",
    patientId: "",
    patientIdIsEmpty: false,
    name: "",
    nameIsEmpty: false,
    phone: "",
    phoneIsEmpty: false,
    InValidphoneNumber: false,
    age: "",
    InvalidAge: false,
    ageIsEmpty: false,
    dateOfBirthIsEmpty: false,
    pincode: "",
    pincodeIsEmpty: false,
    location: "",
    locationIsEmpty: false,
    email: "",

    dobDay: "",
    dobMonth: "",
    dobYear: "",

    patientDiabetes: "no",
    patientHeartDisease: "no",
    patientHypertension: "no",
    patientDyslipidemia: "no",
    patientAnemia: "no",
    patientCancer: "no",

    familyDiabetes: "no",
    familyHeartDisease: "no",
    familyHypertension: "no",
    familyDyslipidemia: "no",
    familyAnemia: "no",
    familyCancer: "no",
    familyHistory: "no",

    diet: "mix",
    smoking: "no",
    alcohol: "no",
    tobacco: "no",
    pastMedicalHistory: "",
    pastMedicalHistoryIsEmpty: false,

    dateOfRtpcr: "",
    dateOfRtpcrIsEmpty: false,
    dateOfLastDose: "",
    dateOfLastDoseIsEmpty: false,

    changeInWeight: "",
    changeInWeightIsEmpty: false,
    waist: "",
    waistIsEmpty: "",

    bmi: "",
    height: "",
    heightIsEmpty: false,
    InvalidHeight: false,
    weight: "",
    weightIsEmpty: false,
    heartRate: "",
    heartRateIsEmpty: false,
    systolicBP: "",
    systolicBPIsEmpty: false,
    diastolicBP: "",
    diastolicBPIsEmpty: false,
    spo2: "",
    spo2IsEmpty: false,
    temperature: "",
    rbs: "",
    respirationRate: "",
    respiratonRateIsEmpty: false,

    patientCreateLoaderStatus: false,
    isAllFilledAreEntered: false,
    invalidWeight: false,
    invalidHeartRate: false,
    invalidSystolicBP: false,
    invalidDiastolicBP: false,
    invalidSpo2: false,
    invalidTemperature: false,
    invalidRBS: false,
    invalidWaist: false,
    invalidChangeWeight: false,
    invalidRespirationRate: false,
    invalidBmi: false,

    termsCheckBox1: "",
    termsCheckBox2: "",
  };

  fetchFormFields = async () => {
    const jwtToken = Cookies.get("jwt_token");
    const formId = Cookies.get("global_form_id");

    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/get_form_fields?form_id=${formId}`;
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${jwtToken}`);
    const options = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    const response = await fetch(url, options);
    const responseObj = await response.json();
    if (responseObj.status === "success") {
      console.log(responseObj, "responseObj");
    } else {
      alert(responseObj.error);
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchFormFields();
  }

  createUserValidation = async (event) => {
    event.preventDefault();
    const campId = Cookies.get("global_camp_id");

    const patientAttributesWithRiskScore = {
      camp_id: campId,
      uhid: this.state.patientId,
      name: this.state.name,
      phone: this.state.phone,
      gender: this.state.gender,
      age: this.state.age,
      pincode: this.state.pincode,
      location: this.state.location,
      dob: `${this.state.dobDay}-${this.state.dobMonth}-${this.state.dobYear}`,
      email: this.state.email,
      p_diabetes: this.state.patientDiabetes,
      p_heart_disease: this.state.patientHeartDisease,
      p_hypertension: this.state.patientHypertension,
      p_dyslipidemia: this.state.patientDyslipidemia,
      p_anemia: this.state.patientAnemia,
      p_cancer: this.state.patientCancer,
      p_covid_infection: this.state.covidInfection,
      date_of_rtpcr: this.state.dateOfRtpcr,
      covid_vaccinated: this.state.covidVaccinated,
      covid_vaccine_name: this.state.covidVaccineName,
      no_of_dose: this.state.noOfDose,
      date_of_last_dose: this.state.dateOfLastDose,
      f_diabetes: this.state.familyDiabetes,
      f_heart_disease: this.state.familyHeartDisease,
      f_hypertension: this.state.familyHypertension,
      f_dyslipidemia: this.state.familyDyslipidemia,
      f_anemia: this.state.familyAnemia,
      f_cancer: this.state.familyCancer,
      diet: this.state.diet,
      alcohol: this.state.alcohol,
      smoking: this.state.smoking,
      tobacco: this.state.tobacco,
      physical_activity: this.state.physicalActivity,
      psychological_stress: this.state.psychologicalStreee,
      height: this.state.height,
      weight: this.state.weight,
      bmi: this.state.bmi,
      heart_rate: this.state.heartRate,
      s_bp: this.state.systolicBP,
      d_bp: this.state.diastolicBP,
      spo2: this.state.spo2,
      temperature: this.state.temperature,
      rbs: this.state.rbs,
      chest_pain: this.state.chestPain,
      pulse_rhythm: this.state.pulseRhythm,
      rate_respiration: this.state.respirationRate,
      past_medical_history: this.state.pastMedicalHistory,
      family_history: this.state.familyHistory,
      waist: this.state.waist,
      change_in_weight: this.state.changeInWeight,
      termsCheckBox1: this.state.termsCheckBox1,
      termsCheckBox2: this.state.termsCheckBox2,
    };

    const nonMandataryList = [
      "dob",
      "pincode",
      "email",
      "temperature",
      "rbs",
      "waist",
      "change_in_weight",
      "p_anemia",
      "p_cancer",
      "f_anemia",
      "f_cancer",
      "p_covid_infection",
      "date_of_rtpcr",
      "covid_vaccinated",
      "covid_vaccine_name",
      "no_of_dose",
      "date_of_last_dose",
    ];

    let allInputAreFilled = true;
    for (let key in patientAttributesWithRiskScore) {
      if (
        patientAttributesWithRiskScore[key] === "" ||
        patientAttributesWithRiskScore[key] === "Select" ||
        patientAttributesWithRiskScore[key] === ""
      ) {
        if (nonMandataryList.includes(key)) {
          continue;
        }
        allInputAreFilled = false;
        break;
      }
    }
    if (
      patientAttributesWithRiskScore.covid_vaccinated.toLowerCase() === "no"
    ) {
      patientAttributesWithRiskScore.covid_vaccine_name = "None";
      patientAttributesWithRiskScore.no_of_dose = "None";
    }

    if (allInputAreFilled) {
      this.setState({
        patientCreateLoaderStatus: true,
        isAllFilledAreEntered: false,
      });
      const url =
        "https://apollo-totalhealth-camp-ai.azurewebsites.net/create-patient";
      //const url = "https://apollo-totalhealth-camp-ai.azurewebsites.net/create-patient";

      const jwt_token = Cookies.get("jwt_token");
      patientAttributesWithRiskScore.camp_id = Cookies.get("global_camp_id");

      let raw = JSON.stringify(patientAttributesWithRiskScore);

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${jwt_token}`);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(url, requestOptions);
      const responseObj = await response.json();

      const { history, updateNewPatientData } = this.props;

      if (responseObj.status === "success") {
        const { aicvd_data, prediebetes_data } = responseObj;

        patientAttributesWithRiskScore["aicvdData"] = aicvd_data;
        patientAttributesWithRiskScore["prediabetesData"] = prediebetes_data;

        updateNewPatientData(patientAttributesWithRiskScore);

        history.push("/report");
      } else {
        history.push("/error");
      }
    } else {
      this.setState({ isAllFilledAreEntered: true });
    }
  };

  genderUpdate = (event) => {
    const value = event.target.value;
    this.setState({
      gender: value,
    });
  };

  updateChestPain = (event) => {
    const value = event.target.value;
    this.setState({ chestPain: value });
  };

  updateCovidInfection = (event) => {
    const value = event.target.value;
    this.setState({
      covidInfection: value,
    });
  };

  updateCovidVaccinated = (event) => {
    const value = event.target.value;
    this.setState({ covidVaccinated: value });
  };

  updatePulseRhythmDropDown = () => {
    const element = document.getElementById("pulseRhythmDropDown");
    const arrow = document.getElementById("pulseRhythmIcon");

    element.style.display = "block";
    arrow.style.transform = "rotate(540deg)";
  };

  updatePulseRhythm = (event) => {
    const value = event.target.value;
    const element = document.getElementById("pulseRhythmDropDown");
    const arrow = document.getElementById("pulseRhythmIcon");

    element.style.display = "none";
    arrow.style.transform = "rotate(0deg)";

    this.setState({ pulseRhythm: value });
  };

  updatePsychologicalStreeeDropDown = () => {
    const element = document.getElementById("psychologicalStreeeDropDown");
    const arrow = document.getElementById("psychologicalStreeeIcon");

    element.style.display = "block";
    arrow.style.transform = "rotate(540deg)";
  };

  updatePsychologicalStreee = (event) => {
    const value = event.target.value;
    const element = document.getElementById("psychologicalStreeeDropDown");
    const arrow = document.getElementById("psychologicalStreeeIcon");

    element.style.display = "none";
    arrow.style.transform = "rotate(0deg)";

    this.setState({ psychologicalStreee: value });
  };

  updatePhysicalActivityDropDown = () => {
    const element = document.getElementById("physicalActivityDropDown");
    const arrow = document.getElementById("physicalActivityIcon");

    element.style.display = "block";
    arrow.style.transform = "rotate(540deg)";
  };

  updatePhysicalActivity = (event) => {
    const value = event.target.value;
    const element = document.getElementById("physicalActivityDropDown");
    const arrow = document.getElementById("physicalActivityIcon");

    element.style.display = "none";
    arrow.style.transform = "rotate(0deg)";

    this.setState({ physicalActivity: value });
  };

  updateNoOfVaccineDose = () => {
    const element = document.getElementById("noOfDoseDropDown");
    const arrow = document.getElementById("noOfDoseIcon");

    element.style.display = "block";
    arrow.style.transform = "rotate(540deg)";
  };

  updateVaccineDose = (event) => {
    const value = event.target.value;
    const element = document.getElementById("noOfDoseDropDown");
    const arrow = document.getElementById("noOfDoseIcon");

    element.style.display = "none";
    arrow.style.transform = "rotate(0deg)";

    this.setState({ noOfDose: value });
  };

  updateCovidVaccineList = () => {
    const element = document.getElementById("covidVaccineDropDown");
    const arrow = document.getElementById("covidVaccineIcon");

    element.style.display = "block";
    arrow.style.transform = "rotate(540deg)";
  };

  updateCovidVaccineName = (event) => {
    const value = event.target.value;

    const element = document.getElementById("covidVaccineDropDown");
    const arrow = document.getElementById("covidVaccineIcon");

    element.style.display = "none";
    arrow.style.transform = "rotate(0deg)";

    this.setState({ covidVaccineName: value });
  };

  pushToUserList = () => {
    const { history } = this.props;

    history.replace("/participant-list");
  };

  addHeight = (event) => {
    const { weight } = this.state;
    const inputValue = parseInt(event.target.value);
    if (weight !== "" && inputValue !== "") {
      let bmi = weight / Math.pow(inputValue * 0.01, 2);
      let decimal = bmi - Math.floor(bmi);
      bmi = Math.floor(bmi) + Math.floor(decimal * 100) / 100;
      this.handleBmiValue(bmi, inputValue);
      this.setState({ bmi: bmi });
    }
    this.setState({
      height: inputValue,
    });
  };

  addWeight = (event) => {
    const { height } = this.state;
    const inputValue = parseInt(event.target.value);
    if (height !== "" && inputValue !== "") {
      let bmi = inputValue / Math.pow(height * 0.01, 2);
      let decimal = bmi - Math.floor(bmi);
      bmi = Math.floor(bmi) + Math.floor(decimal * 100) / 100;
      this.handleBmiValue(bmi, inputValue);
      this.setState({ bmi: bmi });
    }
    this.setState({
      weight: inputValue,
    });
  };

  handleBmiValue = (bmi, value) => {
    if (bmi > 50) {
      this.setState({ invalidBmi: true, bmi: "" });
    } else this.setState({ invalidBmi: false });
  };

  updateInput = (event) => {
    const value = event.target.value;
    const elementName = event.target.name;
    const isChecked = event.target.checked;

    if (elementName === "participant-id") {
      this.setState({ patientId: value });
    } else if (elementName === "name") {
      this.setState({ name: value });
    } else if (elementName === "phone") {
      this.setState({ phone: value });
    } else if (elementName === "age") {
      this.setState({ age: value });
    } else if (elementName === "pincode") {
      this.setState({ pincode: value });
    } else if (elementName === "location") {
      this.setState({ location: value });
    } else if (elementName === "email") {
      this.setState({ email: value });
    } else if (elementName === "date-of-birth") {
      const enteredDate = new Date(value);
      const currentDate = new Date();

      if (enteredDate.getFullYear() <= currentDate.getFullYear() - 20) {
        this.setState({
          dateOfBirth: value,
          dateOfBirthIsEmpty: false,
        });
      } else {
        event.target.value = "";
        this.setState({
          dateOfBirthIsEmpty: true,
        });
      }
    } else if (elementName === "participant-diabetes") {
      this.setState({
        patientDiabetes: value,
      });
    } else if (elementName === "participant-heart-disease") {
      this.setState({ patientHeartDisease: value });
    } else if (elementName === "participant-hypertension") {
      this.setState({ patientHypertension: value });
    } else if (elementName === "participant-dyslipidemia") {
      this.setState({ patientDyslipidemia: value });
    } else if (elementName === "participant-anemia") {
      this.setState({ patientAnemia: value });
    } else if (elementName === "participant-cancer") {
      this.setState({ patientCancer: value });
    } else if (elementName === "date-of-rtpcr") {
      const enteredDate = new Date(value);
      const currentDate = new Date();

      if (enteredDate < currentDate) {
        this.setState({
          dateOfRtpcr: value,
          dateOfRtpcrIsEmpty: false,
        });
      } else {
        event.target.value = "";
        this.setState({
          dateOfRtpcrIsEmpty: true,
        });
      }
    } else if (elementName === "date-of-last-dose") {
      const enteredDate = new Date(value);
      const currentDate = new Date();

      if (enteredDate < currentDate) {
        this.setState({
          dateOfLastDose: value,
          dateOfLastDoseIsEmpty: false,
        });
      } else {
        event.target.value = "";
        this.setState({
          dateOfLastDoseIsEmpty: true,
        });
      }
    } else if (elementName === "family-diabetes") {
      this.setState({
        familyDiabetes: value,
        familyHistory: value,
      });
    } else if (elementName === "family-heart-disease") {
      this.setState({ familyHeartDisease: value });
    } else if (elementName === "family-hypertension") {
      this.setState({ familyHypertension: value });
    } else if (elementName === "family-dyslipidemia") {
      this.setState({ familyDyslipidemia: value });
    } else if (elementName === "family-anemia") {
      this.setState({ familyAnemia: value });
    } else if (elementName === "family-cancer") {
      this.setState({ familyCancer: value });
    } else if (elementName === "diet") {
      this.setState({ diet: value });
    } else if (elementName === "smoking") {
      this.setState({ smoking: value });
    } else if (elementName === "alcohol") {
      this.setState({ alcohol: value });
    } else if (elementName === "tobacco") {
      this.setState({ tobacco: value });
    } else if (elementName === "past-medical-history") {
      this.setState({ pastMedicalHistory: value });
    } else if (elementName === "systolic-BP") {
      this.setState({ systolicBP: value });
    } else if (elementName === "heart-rate") {
      this.setState({ heartRate: value });
    } else if (elementName === "diastolic-BP") {
      this.setState({ diastolicBP: value });
    } else if (elementName === "spo2") {
      this.setState({ spo2: value });
    } else if (elementName === "temperature") {
      this.setState({ temperature: value });
    } else if (elementName === "rbs") {
      this.setState({ rbs: value });
    } else if (elementName === "waist") {
      this.setState({ waist: value });
    } else if (elementName === "change-weight") {
      this.setState({ changeInWeight: value });
    } else if (elementName === "pulse-rhythm") {
      this.setState({ pulseRhythm: value });
    } else if (elementName === "check1") {
      this.setState({ termsCheckBox1: isChecked ? "check1" : "" });
    } else if (elementName === "check2") {
      this.setState({ termsCheckBox2: isChecked ? "check2" : "" });
    } else {
      this.setState({ respirationRate: value });
    }
  };

  phoneNumberVaidation = (mobileNumber) => {
    var expr = /^(0|91)?[6-9][0-9]{9}$/;
    return expr.test(mobileNumber);
  };

  isInputEmpty = (event) => {
    const value = event.target.value;
    const elementName = event.target.name;

    if (value === "") {
      if (elementName === "participant-id") {
        this.setState({ patientIdIsEmpty: true });
      } else if (elementName === "name") {
        this.setState({ nameIsEmpty: true });
      } else if (elementName === "phone") {
        this.setState({ phoneIsEmpty: true });
      } else if (elementName === "age") {
        this.setState({ ageIsEmpty: true });
      } else if (elementName === "location") {
        this.setState({ locationIsEmpty: true });
      } else if (elementName === "date-of-birth") {
        this.setState({ dateOfBirthIsEmpty: true });
      } else if (elementName === "date-of-rtpcr") {
        this.setState({ dateOfRtpcrIsEmpty: true });
      } else if (elementName === "date-of-last-dose") {
        this.setState({ dateOfLastDoseIsEmpty: true });
      } else if (elementName === "height") {
        this.setState({ heightIsEmpty: true });
      } else if (elementName === "weight") {
        this.setState({ weightIsEmpty: true });
      } else if (elementName === "systolic-BP") {
        this.setState({ systolicBPIsEmpty: true });
      } else if (elementName === "heart-rate") {
        this.setState({ heartRateIsEmpty: true });
      } else if (elementName === "diastolic-BP") {
        this.setState({ diastolicBPIsEmpty: true });
      } else if (elementName === "spo2") {
        this.setState({ spo2IsEmpty: true });
      } else if (elementName === "past-medical-history") {
        this.setState({ pastMedicalHistoryIsEmpty: true });
      } else if (elementName === "pulse-rhythm") {
        this.setState({ pulseRhythmIsEmpty: true });
      } else {
        this.setState({ respirationRateIsEmpty: true });
      }
    } else {
      if (elementName === "phone") {
        const isValidNumber = this.phoneNumberVaidation(value);
        if (!isValidNumber) event.target.value = "";
        this.setState({
          InValidphoneNumber: !isValidNumber,
        });
      } else if (elementName === "age") {
        const ageStatus = !(parseInt(value) >= 20 && parseInt(value) <= 100);
        if (ageStatus) event.target.value = "";
        this.setState({
          InvalidAge: ageStatus,
        });
      } else if (elementName === "height") {
        const heightstatus = !(
          parseInt(value) >= 100 && parseInt(value) <= 220
        );
        if (heightstatus) event.target.value = "";
        this.setState({
          InvalidHeight: heightstatus,
        });
      } else if (elementName === "weight") {
        const status = !(parseInt(value) >= 30 && parseInt(value) <= 200);
        if (status) event.target.value = "";
        this.setState({
          invalidWeight: status,
        });
      } else if (elementName === "heart-rate") {
        const status = !(parseInt(value) >= 30 && parseInt(value) <= 120);
        if (status) event.target.value = "";
        this.setState({
          invalidHeartRate: status,
        });
      } else if (elementName === "systolic-BP") {
        const status = !(parseInt(value) >= 80 && parseInt(value) <= 220);
        if (status) event.target.value = "";
        this.setState({
          invalidSystolicBP: status,
        });
      } else if (elementName === "diastolic-BP") {
        const status = !(parseInt(value) >= 40 && parseInt(value) <= 140);
        if (status) event.target.value = "";
        this.setState({
          invalidDiastolicBP: status,
        });
      } else if (elementName === "spo2") {
        const status = !(parseInt(value) >= 80 && parseInt(value) <= 100);
        if (status) event.target.value = "";
        this.setState({
          invalidSpo2: status,
        });
      } else if (elementName === "temperature") {
        const status = !(parseInt(value) >= 90 && parseInt(value) <= 105);
        if (status) event.target.value = "";
        this.setState({
          invalidTemperature: status,
        });
      } else if (elementName === "rbs") {
        const status = !(parseInt(value) >= 50 && parseInt(value) <= 400);
        if (status) event.target.value = "";
        this.setState({
          invalidRBS: status,
        });
      } else if (elementName === "waist") {
        const status = !(parseInt(value) >= 20 && parseInt(value) <= 45);
        if (status) event.target.value = "";
        this.setState({
          invalidWaist: status,
        });
      } else if (elementName === "change-weight") {
        const status = !(parseInt(value) >= 0 && parseInt(value) <= 50);
        if (status) event.target.value = "";
        this.setState({
          invalidChangeWeight: status,
        });
      } else if (elementName === "respiration-rate") {
        const status = !(parseInt(value) >= 10 && parseInt(value) <= 30);
        if (status) event.target.value = "";
        this.setState({
          invalidRespirationRate: status,
        });
      }
    }
  };

  removeError = (event) => {
    const elementName = event.target.name;

    if (elementName === "participant-id") {
      this.setState({ patientIdIsEmpty: false });
    } else if (elementName === "name") {
      this.setState({ nameIsEmpty: false });
    } else if (elementName === "phone") {
      this.setState({ phoneIsEmpty: false, InValidphoneNumber: false });
    } else if (elementName === "age") {
      this.setState({ ageIsEmpty: false, InvalidAge: false });
    } else if (elementName === "location") {
      this.setState({ locationIsEmpty: false });
    } else if (elementName === "date-of-birth") {
      this.setState({ dateOfBirthIsEmpty: false });
    } else if (elementName === "date-of-rtpcr") {
      this.setState({ dateOfRtpcrIsEmpty: false });
    } else if (elementName === "date-of-last-dose") {
      this.setState({ dateOfLastDoseIsEmpty: false });
    } else if (elementName === "height") {
      this.setState({ heightIsEmpty: false, InvalidHeight: false });
    } else if (elementName === "weight") {
      this.setState({ weightIsEmpty: false, invalidWeight: false });
    } else if (elementName === "systolic-BP") {
      this.setState({ systolicBPIsEmpty: false, invalidSystolicBP: false });
    } else if (elementName === "heart-rate") {
      this.setState({ heartRateIsEmpty: false, invalidHeartRate: false });
    } else if (elementName === "temperature") {
      this.setState({ invalidTemperature: false });
    } else if (elementName === "rbs") {
      this.setState({ invalidRBS: false });
    } else if (elementName === "waist") {
      this.setState({ invalidWaist: false });
    } else if (elementName === "change-weight") {
      this.setState({ invalidChangeWeight: false });
    } else if (elementName === "diastolic-BP") {
      this.setState({ diastolicBPIsEmpty: false, invalidDiastolicBP: false });
    } else if (elementName === "spo2") {
      this.setState({ spo2IsEmpty: false, invalidSpo2: false });
    } else if (elementName === "past-medical-history") {
      this.setState({ pastMedicalHistoryIsEmpty: false });
    } else if (elementName === "pulse-rhythm") {
      this.setState({ pulseRhythmIsEmpty: false });
    } else {
      this.setState({
        respirationRateIsEmpty: false,
        invalidRespirationRate: false,
      });
    }
  };

  renderLoader = () => (
    <div className="common-report-loader-container">
      <Loader type="Oval" color="#FF883E" height={50} width={50} />
    </div>
  );

  updateDateOfBirth = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "day") {
      this.setState({ dobDay: value });
    } else if (name === "month") {
      this.setState({ dobMonth: value });
    } else {
      this.setState({ dobYear: value });
    }
  };

  renderNewPatientForm = () => {
    const limitationOfVitals = {
      height: "100-200",
      weight: "30-200",
      heartRate: "30-120",
      systolicBP: "80-220",
      diastolicBP: "40-140",
      spo2: "80-100",
      temperature: "90-105",
      rbs: "50-400",
      waist: "20-45",
      respirationRate: "10-30",
    };
    const jwt_token = Cookies.get("jwt_token");
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        console.log(e.message);
        return null;
      }
    };

    const parseJwtObj = parseJwt(jwt_token);
    const role = parseJwtObj.uRole;
    if (role !== "executive") {
      const { history } = this.props;
      history.goBack();
      return null;
    }

    const {
      covidInfection,
      gender,
      covidVaccineName,
      noOfDose,
      covidVaccinated,
      physicalActivity,
      psychologicalStreee,
      chestPain,
      pulseRhythm,

      patientIdIsEmpty,
      nameIsEmpty,
      phoneIsEmpty,
      ageIsEmpty,
      dateOfBirthIsEmpty,
      locationIsEmpty,
      dateOfRtpcrIsEmpty,
      dateOfLastDoseIsEmpty,
      heightIsEmpty,
      weightIsEmpty,
      systolicBPIsEmpty,
      heartRateIsEmpty,
      diastolicBPIsEmpty,
      spo2IsEmpty,
      respirationRateIsEmpty,
      pulseRhythmIsEmpty,
      isAllFilledAreEntered,
      InValidphoneNumber,
      InvalidAge,
      bmi,
      InvalidHeight,
      invalidWeight,
      invalidHeartRate,
      invalidSystolicBP,
      invalidDiastolicBP,
      invalidSpo2,
      invalidTemperature,
      invalidRBS,
      invalidWaist,
      invalidChangeWeight,
      invalidRespirationRate,
      invalidBmi,
      pastMedicalHistoryIsEmpty,
    } = this.state;

    const covidVaccinatedYesColor =
      covidVaccinated === "yes" ? "button-hightlight-bg" : "";
    const covidVaccinatedNoColor =
      covidVaccinated === "no" ? "button-hightlight-bg" : "";

    const genderMaleButtonColor =
      gender === "male" ? "button-hightlight-bg" : "";
    const genderFemaleButtonColor =
      gender === "female" ? "button-hightlight-bg" : "";

    const covidYesButtonColor =
      covidInfection === "yes" ? "button-hightlight-bg" : "";
    const covidNoButtonColor =
      covidInfection === "no" ? "button-hightlight-bg" : "";

    const chestPainYesColor = chestPain === "yes" ? "button-hightlight-bg" : "";
    const chestPainNoColor = chestPain === "no" ? "button-hightlight-bg" : "";

    return (
      <form
        className="user-create-page-container"
        onSubmit={this.createUserValidation}
      >
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="large-back-button-container">
            <button
              className="button back-button"
              onClick={this.pushToUserList}
            >
              <img src="./back-arrow.svg" alt="back-arrow.svg" />
            </button>
            <p className="large-back-button-text">Back</p>
          </div>
          <div className="health-camp-heading-container">
            <p className="apollo-hospitals-text">Apollo Hospitals</p>
            <h1 className="heading text-bold">Health Camps</h1>
            <div className="heading-style-element-container">
              <div className="style-line"></div>
              <div className="style-dot"></div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-40">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Participant Info</h1>
            <img
              className="sub-heading-icon"
              src="./accessibility-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Participant ID
            </label>
            <div className="input-with-logo">
              <input
                type="text"
                className="login-input"
                placeholder="Apj.10000789"
                name="participant-id"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
              <img
                className="icon"
                src="./finger-print-outline.svg"
                alt="apollo.png"
              />
            </div>
            {patientIdIsEmpty && (
              <p className="error-text">Please enter the Participant ID</p>
            )}
          </div>
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Name
            </label>
            <div className="input-with-logo">
              <input
                type="text"
                className="login-input"
                placeholder="John Doe"
                name="name"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
              <img
                className="icon"
                src="./person-outline.svg"
                alt="apollo.png"
              />
            </div>
            {nameIsEmpty && <p className="error-text">Please enter the Name</p>}
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Phone
            </label>
            <div className="input-with-logo">
              <input
                type="text"
                className="login-input no-increment"
                placeholder="9999988888"
                name="phone"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
              <img className="icon" src="./call-outline.svg" alt="apollo.png" />
            </div>
            {phoneIsEmpty && (
              <p className="error-text">Please enter the Phone Number</p>
            )}
            {InValidphoneNumber && (
              <p className="error-text">Please enter the valid Phone number</p>
            )}
          </div>
          <div className="user-create-input d-flex flex-row justify-content-between">
            <div className="d-flex flex-column justify-content-center width-48">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Gender
              </label>
              <div className="gender-button-container">
                <button
                  className={`button gender-button ${genderMaleButtonColor}`}
                  type="button"
                  value="male"
                  onClick={this.genderUpdate}
                >
                  Male
                </button>
                <button
                  className={`button gender-button ${genderFemaleButtonColor}`}
                  type="button"
                  value="female"
                  onClick={this.genderUpdate}
                >
                  Female
                </button>
              </div>
            </div>
            <div className="input-container user-create-input width-48">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Age
              </label>
              <div className="input-with-logo">
                <input
                  type="number"
                  className="login-input no-increment"
                  placeholder="25"
                  name="age"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.updateInput}
                />
                <img
                  className="icon"
                  src="./glasses-outline.svg"
                  alt="apollo.png"
                />
              </div>
              {ageIsEmpty && <p className="error-text">Please enter the Age</p>}
              {InvalidAge && (
                <p className="error-text">Please enter the valid Age</p>
              )}
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="user-create-input d-flex flex-row justify-content-between">
            <div className="input-container user-create-input width-48">
              <label className="label">Date of Birth</label>
              <div className="input-with-logo">
                <input
                  type="date"
                  className="login-input date-font-size padding-0 width-100"
                  placeholder=""
                  id="dateOfBirth"
                  name="date-of-birth"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.updateInput}
                />
              </div>
              {dateOfBirthIsEmpty && (
                <p className="error-text">Please enter the valid End Date</p>
              )}
            </div>
            <div className="input-container user-create-input width-48">
              <label className="label">Pincode</label>
              <div className="input-with-logo">
                <input
                  type="tell"
                  className="login-input"
                  placeholder="500003"
                  name="pincode"
                  onChange={this.updateInput}
                />
                <img
                  className="icon"
                  src="./pin-outline.svg"
                  alt="apollo.png"
                />
              </div>
            </div>
          </div>
          <div className="user-create-input">
            <div className="input-container user-create-input">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Location
              </label>
              <div className="input-with-logo">
                <input
                  type="text"
                  className="login-input"
                  placeholder="Chennai"
                  name="location"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.updateInput}
                />
                <img
                  className="icon"
                  src="./location-outline.svg"
                  alt="apollo.png"
                />
              </div>
              {locationIsEmpty && (
                <p className="error-text">Please enter the Location</p>
              )}
            </div>
          </div>
        </div>
        <div className=".large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="user-create-input">
            <div className="input-container user-create-input">
              <label className="label">Email</label>
              <div className="input-with-logo">
                <input
                  type="text"
                  className="login-input"
                  placeholder="johndoe@apollohospitals.com"
                  name="email"
                  onChange={this.updateInput}
                />
                <img className="icon" src="./at-outline.svg" alt="apollo.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-40 margin-top-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Participant Vitals</h1>
            <img
              className="sub-heading-icon"
              src="./receipt-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="user-create-input d-flex flex-row justify-content-between">
            <div className="input-container user-create-input width-48">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Height<span className="label-info"> in cm</span>
              </label>
              <div className="input-with-logo">
                <input
                  type="tell"
                  className="login-input"
                  placeholder="100 - 220 cm"
                  name="height"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.addHeight}
                />
                <img
                  className="icon"
                  src="./man-outline.svg"
                  alt="apollo.png"
                />
              </div>
              {heightIsEmpty && (
                <p className="error-text">Please enter the Height</p>
              )}
              {InvalidHeight && (
                <p className="error-text">
                  Please enter the valid Height {limitationOfVitals.height}
                </p>
              )}
            </div>
            <div className="input-container user-create-input width-48">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Weight<span className="label-info"> in kg</span>
              </label>
              <div className="input-with-logo">
                <input
                  type="tell"
                  className="login-input"
                  placeholder="30 - 200 kg"
                  name="weight"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.addWeight}
                />
                <img
                  className="icon"
                  src="./scale-outline.svg"
                  alt="apollo.png"
                />
              </div>
              {weightIsEmpty && (
                <p className="error-text">Please enter the Weight</p>
              )}
              {invalidWeight && (
                <p className="error-text">
                  Please enter the valid Weight {limitationOfVitals.weight}
                </p>
              )}
            </div>
          </div>
          <div className="user-create-input d-flex flex-row justify-content-between">
            <div className="input-container user-create-input width-48">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                BMI
              </label>
              <div className="input-with-logo">
                <input
                  type="tell"
                  className="login-input"
                  placeholder="Auto filled"
                  name="bmi"
                  value={bmi}
                  readOnly
                />
                <img
                  className="icon"
                  src="./scale-outline.svg"
                  alt="apollo.png"
                />
              </div>
              {invalidBmi && (
                <p className="error-text">
                  Please adjust your height or weight to get the valid BMI
                </p>
              )}
              {/* {fullNameIsEmpty && <p className="error-text">Please enter the Full Name</p>} */}
            </div>
            <div className="input-container user-create-input width-48">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Heart Rate<span className="label-info"> / min</span>
              </label>
              <div className="input-with-logo">
                <input
                  type="tell"
                  className="login-input"
                  placeholder="30 - 120"
                  name="heart-rate"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.updateInput}
                />
                <img
                  className="icon"
                  src="./pin-outline.svg"
                  alt="apollo.png"
                />
              </div>
              {heartRateIsEmpty && (
                <p className="error-text">Please enter the Heart Rate</p>
              )}
              {invalidHeartRate && (
                <p className="error-text">
                  Please enter the valid Heart Rate{" "}
                  {limitationOfVitals.heartRate}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="user-create-input d-flex flex-row justify-content-between">
            <div className="input-container user-create-input width-48">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Systolic BP
              </label>
              <div className="input-with-logo">
                <input
                  type="tell"
                  className="login-input"
                  placeholder="80 - 220"
                  name="systolic-BP"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.updateInput}
                />
                <img
                  className="icon"
                  src="./thermometer-outline.svg"
                  alt="apollo.png"
                />
              </div>
              {systolicBPIsEmpty && (
                <p className="error-text">Please enter the Systolic BP</p>
              )}
              {invalidSystolicBP && (
                <p className="error-text">
                  Please enter the valid Systolic BP{" "}
                  {limitationOfVitals.systolicBP}
                </p>
              )}
            </div>
            <div className="input-container user-create-input width-48">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Diastolic BP
              </label>
              <div className="input-with-logo">
                <input
                  type="tell"
                  className="login-input"
                  placeholder="40 - 140"
                  name="diastolic-BP"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.updateInput}
                />
                <img
                  className="icon"
                  src="./thermometer-outline.svg"
                  alt="apollo.png"
                />
              </div>
              {diastolicBPIsEmpty && (
                <p className="error-text">Please enter the Diastolic BP</p>
              )}
              {invalidDiastolicBP && (
                <p className="error-text">
                  Please enter the valid Diastolic BP{" "}
                  {limitationOfVitals.diastolicBP}
                </p>
              )}
            </div>
          </div>
          <div className="user-create-input d-flex flex-row justify-content-between">
            <div className="input-container user-create-input width-48">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                SpO2
              </label>
              <div className="input-with-logo">
                <input
                  type="tell"
                  className="login-input"
                  placeholder="80 - 100"
                  name="spo2"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.updateInput}
                />
                <img
                  className="icon"
                  src="./leaf-outline.svg"
                  alt="apollo.png"
                />
              </div>
              {spo2IsEmpty && (
                <p className="error-text">Please enter the SpO2</p>
              )}
              {invalidSpo2 && (
                <p className="error-text">
                  Please enter the valid SpO2 {limitationOfVitals.spo2}
                </p>
              )}
            </div>
            <div className="input-container user-create-input width-48">
              <label className="label">Temperature</label>
              <div className="input-with-logo">
                <input
                  type="tell"
                  className="login-input"
                  placeholder="90 - 105"
                  name="temperature"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.updateInput}
                />
                <img
                  className="icon"
                  src="./thermometer-outline.svg"
                  alt="apollo.png"
                />
              </div>
              {invalidTemperature && (
                <p className="error-text">
                  Please enter the valid Temperature{" "}
                  {limitationOfVitals.temperature}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="input-container user-create-input width-48">
            <label className="label">
              RBS<span className="label-info"> in mg/dL</span>
            </label>
            <div className="input-with-logo">
              <input
                type="tell"
                className="login-input"
                placeholder="50 - 400"
                name="rbs"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
              <img
                className="icon"
                src="./water-outline.svg"
                alt="apollo.png"
              />
            </div>
            {invalidRBS && (
              <p className="error-text">
                Please enter the valid RBS {limitationOfVitals.rbs}
              </p>
            )}
          </div>
          <div className="input-container user-create-input width-48">
            <label className="label">Waist</label>
            <div className="input-with-logo">
              <input
                type="tell"
                className="login-input"
                placeholder="20 - 45"
                name="waist"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
              <img
                className="icon"
                src="./swap-vertical-outline.svg"
                alt="apollo.png"
              />
            </div>
            {invalidWaist && (
              <p className="error-text">
                Please enter the valid Waist {limitationOfVitals.waist}
              </p>
            )}
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="user-create-input d-flex flex-row justify-content-between">
            <div className="input-container user-create-input">
              <label className="label">Change in Weight last 6 months</label>
              <div className="input-with-logo">
                <input
                  type="tell"
                  className="login-input"
                  placeholder="0 - 50"
                  name="change-weight"
                  onBlur={this.isInputEmpty}
                  onFocus={this.removeError}
                  onChange={this.updateInput}
                />
                <img
                  className="icon"
                  src="./scale-outline.svg"
                  alt="apollo.png"
                />
              </div>
              {invalidChangeWeight && (
                <p className="error-text">Please enter the valid Weight</p>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-40 margin-top-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Participant History</h1>
            <img
              className="sub-heading-icon"
              src="./receipt-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <div className="label-width-tooltip-container width-55 padding-0">
              <label className="label padding-0">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Diabetes
              </label>
              <span className="label-tooltip-icon">?</span>
              <span className="label-tooltip-content">
                The American Diabetes Association (ADA) defines Diabetes
                Mellitus as follows:
                <br />
                1. A fasting plasma glucose (FPG) ≥126 mg/dl. Fasting is defined
                as no caloric intake for at least 8 hr.
                <br />
                2. Symptoms of hyperglycaemia and a casual (random) plasma
                glucose ≥200 mg/dl. Classic symptoms of hyperglycaemia include
                polyuria, polydipsia, and unexplained weight loss. (At the time
                of diagnosis as a diabetic, B cell function is at 25% to 30%.)
                3. An oral glucose tolerance test (OGTT) with a plasma glucose
                ≥200 mg/dl 2 hr after a 75 g (100 g for pregnant women) glucose
                load.
                <br />
                4. A haemoglobin A1c (HbA1c) value ≥6.5%.
                <br />
                Source - David Domenichini M.D. : Ferri's Clinical Advisor 2019,
                424-433.e2
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="patientDiabetesYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="participant-diabetes"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientDiabetesYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="patientDiabetesNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="participant-diabetes"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientDiabetesNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <div className="label-width-tooltip-container width-55 padding-0">
              <label className="label padding-0">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Heart Disease
              </label>
              <span className="label-tooltip-icon">?</span>
              <span className="label-tooltip-content text-center">
                Arrhythmias
                <br />
                Structural Heart Disease
                <br />
                Cardiomyopathy
                <br />
                Heart Failure
                <br />
                CAD - Previously treated
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="patientHeartDiseaseYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="participant-heart-disease"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientHeartDiseaseYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="patientHeartDiseaseNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="participant-heart-disease"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientHeartDiseaseNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <div className="label-width-tooltip-container width-55 padding-0">
              <label className="label padding-0">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Hypertension
              </label>
              <span className="label-tooltip-icon">?</span>
              <span className="label-tooltip-content">
                1. Two hypertension diagnoses (≥14 days apart)
                <br />
                2. A hypertension diagnosis and a hypertension medication
                prescription
                <br />
                a) angiotensin-converting enzyme inhibitors (ACE),
                <br />
                b) angiotensin II receptor blockers (ARB),
                <br />
                c) beta blockers,
                <br />
                d) calcium channel blocks, and/or
                <br />
                e) diuretics
                <br />
                3. A hypertension diagnosis and
                <br />
                a) systolic blood pressure average ≥ 140 (if at least two
                results ≥ 14 days apart), or
                <br />
                b) diastolic blood pressure average ≥ 90 (if at least two
                results ≥ 14 days apart)
                <br />
                Source : Tania B. Babar M.D. : Ferri's Clinical Advisor 2019,
                729-735.e5
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="patientHypertensionYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="participant-hypertension"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientHypertensionYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="patientHypertensionNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="participant-hypertension"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientHypertensionNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <div className="label-width-tooltip-container width-55 padding-0">
              <label className="label padding-0">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Dyslipidemia
              </label>
              <span className="label-tooltip-icon">?</span>
              <span className="label-tooltip-content">
                An elevated lipids diagnosis
                <br />
                1. An elevated lipids diagnosis
                <br />
                2. A prescription for elevated lipids medication
                <br />
                a) statins or statin combinations
                <br />
                b) fibrates
                <br />
                c) niacin
                <br />
                d) bile acid sequestrates, and/or
                <br />
                e) other lipid-modifying agents
                <br />
                3. Lab results
                <br />
                a) triglyceride level ≥250 mg/dL
                <br />
                b) HDL 40 mg/dL for males and 50 mg/dL for females.
                <br />
                c)non-HDL value ≥ 160 mg/dL
                <br />
                Source : National Cholesterol Education Program (NCEP) Expert
                Panel on Cholesterol Levels Preventive Cardiology: Companion to
                Braunwald's Heart Disease
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="patientDyslipidemiaYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="participant-dyslipidemia"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientDyslipidemiaYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="patientDyslipidemiaNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="participant-dyslipidemia"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientDyslipidemiaNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <p className="label width-55 padding-0">Anemia</p>
            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="patientAnemiaYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="participant-anemia"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientAnemiaYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  defaultChecked
                  id="patientAnemiaNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="participant-anemia"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientAnemiaNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <p className="label width-55 padding-0">Cancer</p>
            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="patientCancerYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="participant-cancer"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientCancerYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  defaultChecked
                  id="patientCancerNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="participant-cancer"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="patientCancerNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="user-create-input">
            <label className="label padding-0">
              Did you have a COVID-19 Infection?
            </label>
            <div className="d-flex flex-row margin-top-10 margin-bottom-30">
              <button
                className={`button yes-no-button ${covidYesButtonColor}`}
                value="yes"
                type="button"
                onClick={this.updateCovidInfection}
              >
                Yes
              </button>
              <button
                className={`button yes-no-button ${covidNoButtonColor}`}
                value="no"
                type="button"
                onClick={this.updateCovidInfection}
              >
                No
              </button>
            </div>
            {covidInfection === "yes" && (
              <div className="input-container user-create-input max-width-200">
                <label className="label">Date of RT PCR</label>
                <div className="input-with-logo">
                  <input
                    type="date"
                    className="login-input input-100"
                    name="date-of-rtpcr"
                    onBlur={this.isInputEmpty}
                    onFocus={this.removeError}
                    onChange={this.updateInput}
                  />
                </div>
                {dateOfRtpcrIsEmpty && (
                  <p className="error-text">Please enter the Date of RT PCR</p>
                )}
              </div>
            )}
          </div>
          <div className="user-create-input">
            <label className="label padding-0">
              Have you been vaccinated against COVID-19?
            </label>
            <div className="d-flex flex-row margin-top-10 margin-bottom-30">
              <button
                className={`button yes-no-button ${covidVaccinatedYesColor}`}
                value="yes"
                type="button"
                onClick={this.updateCovidVaccinated}
              >
                Yes
              </button>
              <button
                className={`button yes-no-button ${covidVaccinatedNoColor}`}
                value="no"
                type="button"
                onClick={this.updateCovidVaccinated}
              >
                No
              </button>
            </div>
            {covidVaccinated === "yes" && (
              <>
                <div className="user-create-input d-flex flex-row justify-content-between">
                  <div className="input-container user-create-input width-48">
                    <label className="label">Vaccine Name</label>
                    <div
                      className="input-with-logo cursor-pointer"
                      onClick={this.updateCovidVaccineList}
                    >
                      <input
                        type="text"
                        className="login-input pointer-event-none"
                        name="hospital"
                        value={covidVaccineName}
                        readOnly
                      />
                      <img
                        id="covidVaccineIcon"
                        className="icon"
                        src="./chevron-down-outline.svg"
                        alt="apollo.png"
                      />
                    </div>
                    <ul
                      id="covidVaccineDropDown"
                      className="drop-down-option-container"
                    >
                      <li className="drop-down-option">
                        <button
                          className="drop-down-option-button"
                          type="button"
                          onClick={this.updateCovidVaccineName}
                          value="Covaxin"
                        >
                          Covaxin
                        </button>
                      </li>
                      <li className="drop-down-option">
                        <button
                          className="drop-down-option-button"
                          type="button"
                          onClick={this.updateCovidVaccineName}
                          value="Covishield"
                        >
                          Covishield
                        </button>
                      </li>
                      <li className="drop-down-option">
                        <button
                          className="drop-down-option-button"
                          type="button"
                          onClick={this.updateCovidVaccineName}
                          value="Sputnik"
                        >
                          Sputnik
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="input-container user-create-input width-48">
                    <label className="label">No.of Doses</label>
                    <div
                      className="input-with-logo cursor-pointer"
                      onClick={this.updateNoOfVaccineDose}
                    >
                      <input
                        type="text"
                        className="login-input pointer-event-none"
                        name="hospital"
                        value={noOfDose}
                        readOnly
                      />
                      <img
                        id="noOfDoseIcon"
                        className="icon"
                        src="./chevron-down-outline.svg"
                        alt="apollo.png"
                      />
                    </div>
                    <ul
                      id="noOfDoseDropDown"
                      className="drop-down-option-container"
                    >
                      <li className="drop-down-option">
                        <button
                          className="drop-down-option-button"
                          type="button"
                          onClick={this.updateVaccineDose}
                          value="1"
                        >
                          1
                        </button>
                      </li>
                      <li className="drop-down-option">
                        <button
                          className="drop-down-option-button"
                          type="button"
                          onClick={this.updateVaccineDose}
                          value="2"
                        >
                          2
                        </button>
                      </li>
                      <li className="drop-down-option">
                        <button
                          className="drop-down-option-button"
                          type="button"
                          onClick={this.updateVaccineDose}
                          value="3"
                        >
                          3
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="input-container user-create-input max-width-200">
                  <label className="label">Date of last dose</label>
                  <div className="input-with-logo">
                    <input
                      type="date"
                      className="login-input input-100"
                      name="date-of-last-dose"
                      onBlur={this.isInputEmpty}
                      onFocus={this.removeError}
                      onChange={this.updateInput}
                    />
                  </div>
                  {dateOfLastDoseIsEmpty && (
                    <p className="error-text">
                      Please enter the Date of Last dose
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-40 margin-top-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Family History</h1>
            <img
              className="sub-heading-icon"
              src="./people-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <div className="label-width-tooltip-container width-55 padding-0">
              <label className="label padding-0">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Diabetes
              </label>
              <span className="label-tooltip-icon">?</span>
              <span className="label-tooltip-content">
                1. A diagnosis of family history of diabetes, or <br />
                2. A record in the Medical Record denoting family history of
                diabetes
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="familyDiabetesYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="family-diabetes"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyDiabetesYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  defaultChecked
                  id="familyDiabetesNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="family-diabetes"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyDiabetesNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <div className="label-width-tooltip-container width-55 padding-0">
              <label className="label padding-0">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Heart Disease
              </label>
              <span className="label-tooltip-icon">?</span>
              <span className="label-tooltip-content text-center">
                Arrhythmias
                <br />
                Structural Heart Disease
                <br />
                Cardiomyopathy
                <br />
                Heart Failure
                <br />
                CAD - Previously treated
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="familyHeartDiseaseYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="family-heart-disease"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyHeartDiseaseYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  defaultChecked
                  id="familyHeartDiseaseNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="family-heart-disease"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyHeartDiseaseNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <p className="label width-55 padding-0">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Hypertension
            </p>
            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="familyHypertensionYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="family-hypertension"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyHypertensionYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  defaultChecked
                  id="familyHypertensionNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="family-hypertension"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyHypertensionNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <p className="label width-55 padding-0">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Dyslipidemia
            </p>
            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="familyDyslipidemiaYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="family-dyslipidemia"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyDyslipidemiaYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  defaultChecked
                  id="familyDyslipidemiaNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="family-dyslipidemia"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyDyslipidemiaNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <p className="label width-55 padding-0">Anemia</p>
            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="familyAnemiaYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="family-anemia"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyAnemiaYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  defaultChecked
                  id="familyAnemiaNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="family-anemia"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyAnemiaNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <p className="label width-55 padding-0">Cancer</p>
            <div className="d-flex flex-row align-items-center justify-content-between width-45">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  id="familyCancerYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="family-cancer"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyCancerYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                <input
                  defaultChecked
                  id="familyCancerNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="family-cancer"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="familyCancerNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-40 margin-top-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Lifestyle</h1>
            <img
              className="sub-heading-icon"
              src="./people-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between align-items-center">
            <div className="label-width-tooltip-container width-35 padding-0">
              <label className="label padding-0">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Diet
              </label>
              <span className="label-tooltip-icon">?</span>
              <span className="label-tooltip-content text-center">
                If person eats Vegetarian, Non Vegetarian, Mixed (both
                vegetarian and non-vegetarian)
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between width-65">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30">
                <input
                  id="dietVeg"
                  value="veg"
                  className="radio-button"
                  type="radio"
                  name="diet"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="dietVeg">
                  Veg
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30">
                <input
                  id="dietNonVeg"
                  value="non-veg"
                  className="radio-button custom-radio"
                  type="radio"
                  name="diet"
                  onClick={this.updateInput}
                />
                <label className="radio-label c-position" htmlFor="dietNonVeg">
                  Non Veg
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30">
                <input
                  defaultChecked
                  id="dietMix"
                  value="Mix"
                  className="radio-button"
                  type="radio"
                  name="diet"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="dietMix">
                  Mix
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <div className="label-width-tooltip-container width-35 padding-0">
              <label className="label padding-0">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Alcohol
              </label>
              <span className="label-tooltip-icon">?</span>
              <span className="label-tooltip-content text-center">
                If a person is currently drinking Alcohol or in past or do not
                drink.
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between width-65">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30">
                <input
                  id="alcoholNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="alcohol"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="alcoholNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30">
                <input
                  id="alcoholPast"
                  value="past"
                  className="radio-button"
                  type="radio"
                  name="alcohol"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="alcoholPast">
                  Past
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30">
                <input
                  id="alcoholCurrent"
                  value="no"
                  className="radio-button custom-radio"
                  type="radio"
                  name="alcohol"
                  onClick={this.updateInput}
                />
                <label
                  className="radio-label c-position"
                  htmlFor="alcoholCurrent"
                >
                  Current
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between align-items-center">
            <div className="label-width-tooltip-container width-35 padding-0">
              <label className="label padding-0">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Smoking
              </label>
              <span className="label-tooltip-icon">?</span>
              <span className="label-tooltip-content text-center">
                If a person is smoking currently or in past or do not smoke.
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between width-65">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30">
                <input
                  id="smokingNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="smoking"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="smokingNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30">
                <input
                  id="smokingPast"
                  value="past smoker"
                  className="radio-button"
                  type="radio"
                  name="smoking"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="smokingPast">
                  Past
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30">
                <input
                  id="smokingCurrent"
                  value="current smoker"
                  className="radio-button custom-radio"
                  type="radio"
                  name="smoking"
                  onClick={this.updateInput}
                />
                <label
                  className="radio-label c-position"
                  htmlFor="smokingCurrent"
                >
                  Current
                </label>
                <span className="c-radio-button"></span>
              </div>
            </div>
          </div>
          <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
            <div className="label-width-tooltip-container width-35 padding-0">
              <label className="label padding-0">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Tobacco
              </label>
              <span className="label-tooltip-icon">?</span>
              <span className="label-tooltip-content text-center">
                If a person chews tobacco currently or in past or do not chew.
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between width-65">
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30">
                <input
                  defaultChecked
                  id="tobaccoNo"
                  value="no"
                  className="radio-button"
                  type="radio"
                  name="tobacco"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="tobaccoNo">
                  No
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30">
                <input
                  id="tobaccoYes"
                  value="yes"
                  className="radio-button"
                  type="radio"
                  name="tobacco"
                  onClick={this.updateInput}
                />
                <label className="radio-label" htmlFor="tobaccoYes">
                  Yes
                </label>
                <span className="c-radio-button"></span>
              </div>
              <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-30"></div>
            </div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="input-container user-create-input">
            <div className="label-width-tooltip-container">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Physical Activity
              </label>
              <span className="label-tooltip-icon">?</span>
              <span className="label-tooltip-content text-center">
                If a person is Physically Active in following categories: Mild,
                Moderate, Active, Not active.
              </span>
            </div>
            <div
              className="input-with-logo cursor-pointer"
              onClick={this.updatePhysicalActivityDropDown}
            >
              <input
                type="text"
                className="login-input pointer-event-none"
                name="physical-activity"
                value={physicalActivity}
                readOnly
              />
              <img
                id="physicalActivityIcon"
                className="icon"
                src="./chevron-down-outline.svg"
                alt="apollo.png"
              />
            </div>
            <ul
              id="physicalActivityDropDown"
              className="drop-down-option-container"
            >
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePhysicalActivity}
                  value="Mild"
                >
                  Mild
                </button>
              </li>
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePhysicalActivity}
                  value="Sedentary"
                >
                  Sedentary
                </button>
              </li>
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePhysicalActivity}
                  value="Moderate"
                >
                  Moderate
                </button>
              </li>
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePhysicalActivity}
                  value="Active"
                >
                  Active
                </button>
              </li>
            </ul>
          </div>
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Psychological stress
            </label>
            <div
              className="input-with-logo cursor-pointer"
              onClick={this.updatePsychologicalStreeeDropDown}
            >
              <input
                type="text"
                className="login-input pointer-event-none"
                name="psychological-streee"
                value={psychologicalStreee}
                readOnly
              />
              <img
                id="psychologicalStreeeIcon"
                className="icon"
                src="./chevron-down-outline.svg"
                alt="apollo.png"
              />
            </div>
            <ul
              id="psychologicalStreeeDropDown"
              className="drop-down-option-container"
            >
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePsychologicalStreee}
                  value="No Stress"
                >
                  No Stress
                </button>
              </li>
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePsychologicalStreee}
                  value="Negative Thought Patterns And Emotions"
                >
                  Negative Thought Patterns And Emotions
                </button>
              </li>
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePsychologicalStreee}
                  value="Depressive Syndromes"
                >
                  Depressive Syndromes
                </button>
              </li>
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePsychologicalStreee}
                  value="Anxiety Syndromes"
                >
                  Anxiety Syndromes
                </button>
              </li>
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePsychologicalStreee}
                  value="Chronic Stress"
                >
                  Chronic Stress
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          {/* <div className="margin-bottom-30 user-create-input d-flex flew-row justify-content-between">
                        <div className="label-width-tooltip-container width-55 padding-0">
                            <label className="label padding-0"><img className="asterisk-icon" src="./asterisk.svg" alt="asterisk.png" />Past Medical History</label>
                            <span className="label-tooltip-icon">?</span>
                            <span className="label-tooltip-content">
                                a. Chronic Illness like Cardiovascular / Renal / Liver / Thyroid Disease / Stroke or Transient Ischemic Attack <br />
                                b. Hypertension <br />
                                c. Frequent Infection                         
                            </span>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-between width-45">
                            <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                                <input id="pastMedicalHistoryYes" value="yes" className="radio-button" type="radio" name="past-medical-history" onClick={this.updateInput} />
                                <label className="radio-label" htmlFor="pastMedicalHistoryYes">Yes</label>
                                <span className="c-radio-button"></span>
                            </div>
                            <div className="radio-with-lable position-relative d-flex flex-row align-items-center width-45">
                                <input defaultChecked id="pastMedicalHistoryNo" value="no" className="radio-button" type="radio" name="past-medical-history" onClick={this.updateInput} />
                                <label className="radio-label" htmlFor="pastMedicalHistoryNo" >No</label>
                                <span className="c-radio-button"></span>
                            </div>
                        </div>
                        <label className="label"><img className="asterisk-icon" src="./asterisk.svg" alt="asterisk.png" />Pulse Rhythm</label>
                        <div className="input-with-logo cursor-pointer">
                            <input 
                                type="text" 
                                className="login-input" 
                                name="pulse-rhythm"
                                placeholder="Enter the input"
                                value={pulseRhythm} 
                                onChange={this.updateInput}
                            />
                        </div>
                    </div> */}
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Past Medical History
            </label>
            <div className="input-with-logo">
              <input
                type="text"
                className="login-input"
                name="past-medical-history"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
            </div>
            {pastMedicalHistoryIsEmpty && (
              <p className="error-text">
                Please enter the Past Medical History
              </p>
            )}
          </div>
        </div>
        <div className="d-flex flex-row align-items-center margin-bottom-40 margin-top-30">
          <div className="style-element-container bottom-0">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
          <div className="sub-heading-with-logo margin-left-right-8">
            <h1 className="sub-heading">Heart Health Attributes</h1>
            <img
              className="sub-heading-icon"
              src="./fitness-outline.svg"
              alt="list.svg"
            />
          </div>
          <div className="style-element-container bottom-0 rotate-180">
            <div className="style-line"></div>
            <div className="style-dot"></div>
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
          <div className="user-create-input">
            <div className="label-width-tooltip-container">
              <label className="label">
                <img
                  className="asterisk-icon"
                  src="./asterisk.svg"
                  alt="asterisk.png"
                />
                Chest Pain / Shortness Of Breath / Other Symptoms ?
              </label>
              <span className="label-tooltip-icon c-label-tooltip">?</span>
              <span className="label-tooltip-content text-center">
                Symptoms Chest pain or chest discomfort Dyspnoea or shortness of
                breath Swelling of legs (Peripheral Edema) Palpitations and / or
                Syncope Others History (As per NYHA) Underlying Etiology &#45;
                Congenital, Hypertensive, Ischemic or Inflammatory Anatomic
                abnormalities &#45; Heart Chambers; Hypertrophy or Dilated or
                both; Valvular disease &#45; stenosis or regurgitation
                Physiological &#45; Arrhythmia, Congestive Heart Failure,
                Coronary Artery Disease etc Functional Disability &#45; NYHA
                Class I &#45; IV Family History of Sudden Cardiac Death Joseph
                Loscalzo M.D. Harrison&#39;s Principles of Internal Medicine
                20th Edition : The Criteria Committee of New York Heart
                Association
              </span>
            </div>
            <div className="d-flex flex-row margin-top-10 margin-bottom-30">
              <button
                className={`button yes-no-button ${chestPainYesColor}`}
                value="yes"
                type="button"
                onClick={this.updateChestPain}
              >
                Yes
              </button>
              <button
                className={`button yes-no-button ${chestPainNoColor}`}
                value="no"
                type="button"
                onClick={this.updateChestPain}
              >
                No
              </button>
            </div>
          </div>
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Pulse Rhythm
            </label>
            <div
              className="input-with-logo cursor-pointer"
              onClick={this.updatePulseRhythmDropDown}
            >
              <input
                type="text"
                className="login-input"
                name="pulse-rhythm"
                value={pulseRhythm}
                readOnly
              />
              <img
                id="pulseRhythmIcon"
                className="icon"
                src="./chevron-down-outline.svg"
                alt="apollo.png"
              />
            </div>
            <ul id="pulseRhythmDropDown" className="drop-down-option-container">
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePulseRhythm}
                  value="Regularly Regular"
                >
                  Regularly Regular
                </button>
              </li>
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePulseRhythm}
                  value="Regularly Irregular"
                >
                  Regularly Irregular
                </button>
              </li>
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePulseRhythm}
                  value="Irregularly Regular"
                >
                  Irregularly Regular
                </button>
              </li>
              <li className="drop-down-option">
                <button
                  className="drop-down-option-button"
                  type="button"
                  onClick={this.updatePulseRhythm}
                  value="Irregularly Irregular"
                >
                  Irregularly Irregular
                </button>
              </li>
            </ul>
            {pulseRhythmIsEmpty && (
              <p className="error-text">Please enter the Pulse Rhythm</p>
            )}
          </div>
        </div>
        <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850 margin-bottom-40">
          <div className="input-container user-create-input">
            <label className="label">
              <img
                className="asterisk-icon"
                src="./asterisk.svg"
                alt="asterisk.png"
              />
              Respiration Rate<span className="label-info"> / min</span>
            </label>
            <div className="input-with-logo">
              <input
                type="tell"
                className="login-input"
                placeholder="10 - 30"
                name="respiration-rate"
                onBlur={this.isInputEmpty}
                onFocus={this.removeError}
                onChange={this.updateInput}
              />
              <img
                className="icon"
                src="./stopwatch-outline.svg"
                alt="apollo.png"
              />
            </div>
            {respirationRateIsEmpty && (
              <p className="error-text">Please enter the Respiration Rate</p>
            )}
            {invalidRespirationRate && (
              <p className="error-text">
                Please enter valid Respiration Rate{" "}
                {limitationOfVitals.respirationRate}
              </p>
            )}
          </div>
        </div>
        <div className="display-flex justify-content-center flex-column align-items-center margin-bottom-30 max-width-850 margin-auto">
          <ScrollDialog />
          <div className="d-flex flex-column mt-20">
            <div className="check-box-container">
              <input
                id="check1"
                className="checkbox-input"
                type="checkbox"
                value="check1"
                name="check1"
                onChange={this.updateInput}
              />
              <span className="custom-checkbox mr-10">
                <img
                  src="checkmark-outline-white.svg"
                  alt="checkmark-outline-white"
                />
              </span>
              <label
                htmlFor="check1"
                className="cursor-pointer instruction-checkbox light-color padding-top-bottom-none ml-10"
              >
                Patient has confirmed that he/she has read and understood the
                information sheet for the above Risk Scores and have had the
                opportunity to ask questions
              </label>
            </div>
            <div className="check-box-container">
              <input
                id="check2"
                className="checkbox-input"
                type="checkbox"
                value="check2"
                name="check2"
                onChange={this.updateInput}
              />
              <span className="custom-checkbox ">
                <img
                  src="checkmark-outline-white.svg"
                  alt="checkmark-outline-white"
                />
              </span>
              <label
                htmlFor="check2"
                className="cursor-pointer instruction-checkbox light-color padding-top-bottom-none ml-10"
              >
                Patient has agreed to take part in the above NCD Risk Scores in
                Apollo Hospitals.
              </label>
            </div>
          </div>
          <p id="checkError" className="error-text"></p>
        </div>
        {isAllFilledAreEntered && (
          <p className="submit-error-text">
            Some mandatary filelds are missing...! Please do verify {}
          </p>
        )}
        <button className="button next-button margin-top-30" type="submit">
          <p className="button-text">Submit</p>
        </button>
        <div className="end-line"></div>
        <p className="copy-rights-text">
          <span className="font-weight-700">
            Copyright @{new Date().getFullYear()} Powered By Apollo Hospitals.
          </span>
          <br /> All rights reserved.
        </p>
      </form>
    );
  };

  render() {
    const { patientCreateLoaderStatus } = this.state;
    return (
      <>
        {patientCreateLoaderStatus
          ? this.renderLoader()
          : this.renderNewPatientForm()}
      </>
    );
  }
}

export default withRouter(NewPatientForm);
