import React from "react";
import { withRouter } from "react-router";

class LiverForm extends React.Component {
    state = {
        bilirubin: '',
        bilirubinIsEmpty: false,
        alkphosAlkalinePhosphotase: '',
        alkphosAlkalinePhosphotaseIsEmpty: false,
        sgpt: '',
        sgptIsEmpty: false,
        sgot: '',
        sgotIsEmpty: false,
        totalProtiens: '',
        totalProtiensIsEmpty: false,
        albumin: '',
        albuminIsEmpty: false,
        cholesterol: '',
        cholesterolIsEmpty: false,
        hdlcCholesterol: '',
        hdlCholesterolIsEmpty: false,
        plateletCount: '',
        plateletCountIsEmpty: false,
        inr: '',
        inrIsEmpty: false,


    }

    updateInput = (event) => {
        const elementName = event.target.name;
        const value = event.target.value;

        if (elementName === 'total-bilirubin') {
            this.setState({bilirubin: value});
        } else if (elementName === 'alkphos-alkaline-phosphotase') {
            this.setState({alkphosAlkalinePhosphotase: value});
        } else if (elementName === 'SGPT') {
            this.setState({sgpt: value});
        } else if (elementName === 'SGOT') {
            this.setState({sgot: value});
        } else if (elementName === 'total-protiens') {
            this.setState({totalProtiens: value});
        } else if (elementName === 'albumin') {
            this.setState({albumin: value});
        } else if (elementName === 'cholesterol') {
            this.setState({cholesterol: value});
        } else if (elementName === 'hdl-cholesterol') {
            this.setState({hdlcCholesterol: value});
        } else if (elementName === 'platelet-count') {
            this.setState({plateletCount: value});
        } else {
            this.setState({inr: value});
        }
    }

    isInputEmpty = (event) => {
        const elementName = event.target.name;
        const value = event.target.value;

        if (value === '') {
            if (elementName === 'total-bilirubin') {
                this.setState({bilirubinIsEmpty: true});
            } else if (elementName === 'alkphos-alkaline-phosphotase') {
                this.setState({alkphosAlkalinePhosphotaseIsEmpty: true});
            } else if (elementName === 'SGPT') {
                this.setState({sgptIsEmpty: true});
            } else if (elementName === 'SGOT') {
                this.setState({sgotIsEmpty: true});
            } else if (elementName === 'total-protiens') {
                this.setState({totalProtiensIsEmpty: true});
            } else if (elementName === 'albumin') {
                this.setState({albuminIsEmpty: true});
            } else if (elementName === 'cholesterol') {
                this.setState({cholesterolIsEmpty: true});
            } else if (elementName === 'hdl-cholesterol') {
                this.setState({hdlCholesterolIsEmpty: true});
            } else if (elementName === 'platelet-count') {
                this.setState({plateletCountIsEmpty: true});
            } else {
                this.setState({inrIsEmpty: true});
            }
        }
    }

    removeError = (event) => {
        const elementName = event.target.name;

        if (elementName === 'total-bilirubin') {
            this.setState({bilirubinIsEmpty: false});
        } else if (elementName === 'alkphos-alkaline-phosphotase') {
            this.setState({alkphosAlkalinePhosphotaseIsEmpty: false});
        } else if (elementName === 'SGPT') {
            this.setState({sgptIsEmpty: false});
        } else if (elementName === 'SGOT') {
            this.setState({sgotIsEmpty: false});
        } else if (elementName === 'total-protiens') {
            this.setState({totalProtiensIsEmpty: false});
        } else if (elementName === 'albumin') {
            this.setState({albuminIsEmpty: false});
        } else if (elementName === 'cholesterol') {
            this.setState({cholesterolIsEmpty: false});
        } else if (elementName === 'hdl-cholesterol') {
            this.setState({hdlCholesterolIsEmpty: false});
        } else if (elementName === 'platelet-count') {
            this.setState({plateletCountIsEmpty: false});
        } else {
            this.setState({inrIsEmpty: false});
        }
    }


    render() {
        const {
            bilirubinIsEmpty,
            alkphosAlkalinePhosphotaseIsEmpty,
            sgptIsEmpty,
            sgotIsEmpty,
            totalProtiensIsEmpty,
            albuminIsEmpty,
            cholesterolIsEmpty,
            hdlCholesterolIsEmpty,
            plateletCountIsEmpty,
            inrIsEmpty


        } = this.state;


        return (
            <form className="user-create-page-container">
                <div className="menu-icon-with-apollo-logo">
                    <button className="button back-button" onClick={this.pushToUserList}>
                        <img src="./back-arrow.svg" alt="back-arrow.svg" />
                    </button>
                    <img className="small-apollo-logo" src="./logo.png" alt="apollo.png" />
                </div>
                <div className="large-back-button-container">
                    <button className="button back-button" onClick={this.pushToUserList}>
                        <img src="./back-arrow.svg" alt="back-arrow.svg" />
                    </button>
                    <p className="large-back-button-text">Back</p>
                </div>
                <div className="health-camp-heading-container">
                    <p className="apollo-hospitals-text">Apollo Hospitals</p>
                    <h1 className="heading text-bold">Health Camps</h1>
                    <div className="heading-style-element-container">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center margin-bottom-40">
                    <div className="style-element-container bottom-0">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                    <div className="sub-heading-with-logo margin-left-right-8">
                        <h1 className="sub-heading">Lab History</h1>
                        <img className="sub-heading-icon" src="./receipt-outline.svg" alt="list.svg" />
                    </div>
                    <div className="style-element-container bottom-0 rotate-180">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
                    <div className="input-container user-create-input">
                        <label className="label">Total Bilirubin</label>
                        <div className="input-with-logo">
                            <input 
                                type="text" 
                                className="login-input" 
                                placeholder="" 
                                name="total-bilirubin"
                                onBlur={this.isInputEmpty}
                                onFocus={this.removeError}
                                onChange={this.updateInput}
                            />
                            <img className="icon" src="./eyedrop-outline.svg" alt="apollo.png" />
                        </div>
                        {bilirubinIsEmpty && <p className="error-text">Please enter the Total Bilirubin</p>}
                    </div>
                    <div className="input-container user-create-input">
                        <label className="label">Alkphos Alkaline Phosphotase</label>
                        <div className="input-with-logo">
                            <input 
                                type="text" 
                                className="login-input" 
                                placeholder="" 
                                name="alkphos-alkaline-phosphotase"
                                onBlur={this.isInputEmpty}
                                onFocus={this.removeError}
                                onChange={this.updateInput}
                            />
                            <img className="icon" src="./eyedrop-outline.svg" alt="apollo.png" />
                        </div>
                        {alkphosAlkalinePhosphotaseIsEmpty && <p className="error-text">Please enter the Alkphos Alkaline Phosphotase</p>}
                    </div>
                </div>
                <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
                    <div className="input-container user-create-input">
                        <label className="label">SGPT</label>
                        <div className="input-with-logo">
                            <input 
                                type="text" 
                                className="login-input" 
                                placeholder="" 
                                name="SGPT"
                                onBlur={this.isInputEmpty}
                                onFocus={this.removeError}
                                onChange={this.updateInput}
                            />
                            <img className="icon" src="./eyedrop-outline.svg" alt="apollo.png" />
                        </div>
                        {sgptIsEmpty && <p className="error-text">Please enter the SGPT</p>}
                    </div>
                    <div className="input-container user-create-input">
                        <label className="label">SGOT</label>
                        <div className="input-with-logo">
                            <input 
                                type="text" 
                                className="login-input" 
                                placeholder="" 
                                name="SGOT"
                                onBlur={this.isInputEmpty}
                                onFocus={this.removeError}
                                onChange={this.updateInput}
                            />
                            <img className="icon" src="./eyedrop-outline.svg" alt="apollo.png" />
                        </div>
                        {sgotIsEmpty && <p className="error-text">Please enter the SGOT</p>}
                    </div>
                </div>
                <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
                    <div className="input-container user-create-input">
                        <label className="label">Total Protiens</label>
                        <div className="input-with-logo">
                            <input 
                                type="text" 
                                className="login-input" 
                                placeholder="" 
                                name="total-protiens"
                                onBlur={this.isInputEmpty}
                                onFocus={this.removeError}
                                onChange={this.updateInput}
                            />
                            <img className="icon" src="./eyedrop-outline.svg" alt="apollo.png" />
                        </div>
                        {totalProtiensIsEmpty && <p className="error-text">Please enter the Total Protiens</p>}
                    </div>
                    <div className="input-container user-create-input">
                        <label className="label">Albumin</label>
                        <div className="input-with-logo">
                            <input 
                                type="text" 
                                className="login-input" 
                                placeholder="" 
                                name="albumin"
                                onBlur={this.isInputEmpty}
                                onFocus={this.removeError}
                                onChange={this.updateInput}
                            />
                            <img className="icon" src="./eyedrop-outline.svg" alt="apollo.png" />
                        </div>
                        {albuminIsEmpty && <p className="error-text">Please enter the Albumin</p>}
                    </div>
                </div>
                <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
                    <div className="input-container user-create-input">
                        <label className="label">CHOLESTEROL</label>
                        <div className="input-with-logo">
                            <input 
                                type="text" 
                                className="login-input" 
                                placeholder="" 
                                name="cholesterol"
                                onBlur={this.isInputEmpty}
                                onFocus={this.removeError}
                                onChange={this.updateInput}
                            />
                            <img className="icon" src="./eyedrop-outline.svg" alt="apollo.png" />
                        </div>
                        {cholesterolIsEmpty && <p className="error-text">Please enter the CHOLESTEROL</p>}
                    </div>
                    <div className="input-container user-create-input">
                        <label className="label">HDL CHOLESTEROL</label>
                        <div className="input-with-logo">
                            <input 
                                type="text" 
                                className="login-input" 
                                placeholder="" 
                                name="hdl-cholesterol"
                                onBlur={this.isInputEmpty}
                                onFocus={this.removeError}
                                onChange={this.updateInput}
                            />
                            <img className="icon" src="./eyedrop-outline.svg" alt="apollo.png" />
                        </div>
                        {hdlCholesterolIsEmpty && <p className="error-text">Please enter the HDL CHOLESTEROL</p>}
                    </div>
                </div>
                <div className="large-d-m-b-25 align-self-center d-flex flex-row flex-wrap justify-content-between max-width-850">
                    <div className="input-container user-create-input">
                        <label className="label">Platelet Count</label>
                        <div className="input-with-logo">
                            <input 
                                type="text" 
                                className="login-input" 
                                placeholder="" 
                                name="platelet-count"
                                onBlur={this.isInputEmpty}
                                onFocus={this.removeError}
                                onChange={this.updateInput}
                            />
                            <img className="icon" src="./eyedrop-outline.svg" alt="apollo.png" />
                        </div>
                        {plateletCountIsEmpty && <p className="error-text">Please enter the Platelet Count</p>}
                    </div>
                    <div className="input-container user-create-input">
                        <label className="label">INR</label>
                        <div className="input-with-logo">
                            <input 
                                type="text" 
                                className="login-input" 
                                placeholder="" 
                                name="inr"
                                onBlur={this.isInputEmpty}
                                onFocus={this.removeError}
                                onChange={this.updateInput}
                            />
                            <img className="icon" src="./eyedrop-outline.svg" alt="apollo.png" />
                        </div>
                        {inrIsEmpty && <p className="error-text">Please enter the INR</p>}
                    </div>
                </div>
                <button className="button next-button">
                    <p className="button-text">Submit</p>
                </button>
                <div className="end-line"></div>
                <p className="copy-rights-text">
                    <span className="font-weight-700">Copyright @{new Date().getFullYear()} Powered By Apollo Hospitals.</span><br /> All rights reserved.
                </p>
            </form>
        )
    }
}

export default withRouter(LiverForm);