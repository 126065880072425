// RadioList.js
import React from "react";
import GenericList from "../common/OptionList";
import RadioOption from "./radioOptions";

const RadioList = (props) => {
  return <GenericList {...props} OptionComponent={RadioOption} />;
};

export default RadioList;
