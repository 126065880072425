import React from "react";
import "./index.css";

function DraggableItem({ item, onClick, id }) {
  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(item));
  };

  const handleClick = () => {
    onClick(item);
  };

  return (
    <div
      draggable
      onDragStart={handleDragStart}
      onClick={handleClick}
      className="each-element"
      key={id}
    >
      <div className="form-element-icon">{item.icon}</div>
      <h3 className="form-element-title">{item.title}</h3>
    </div>
  );
}

export default DraggableItem;
