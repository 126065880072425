// GenericList.js
import React from "react";
import ButtonOption from "./ButtonOptions";

const ButtonList = ({ placeholder, id, name, options, onChange, value }) => {
  return (
    <div className="yes-no-list">
      <input
        type="text"
        id={id}
        placeholder={placeholder}
        className="input-field"
        value={value}
        onChange={(e) => onChange(name, e.target.value)}
      />
      <ButtonOption options={options} />
    </div>
  );
};

export default ButtonList;
