import React, { useCallback, useEffect, useState, useMemo } from "react";
import Cookies from "js-cookie";
import "./index.css";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import ImageUpload from "../FormBuilder/components/ImageUpload";
import FileUpload from "../FormBuilder/components/FileUpload";
import { IoIosArrowDown } from "react-icons/io";
import ScrollDialog from "../termsform/terms-modal";
import Loader from "react-loader-spinner";

const DisplayForm = ({ updateNewPatientData }) => {
  const location = useLocation();
  const formDetails = location.state;
  const history = useHistory();

  const [formFields, setFormFields] = useState([]);
  const formId = useMemo(() => location.state?.formId || {}, [location.state]);
  const [formData, setFormData] = useState({});
  const [selectedValue, setSelectedValue] = useState([]);
  const [form_title, setFormTitle] = useState(null);
  const [itemDetails, setItemDetails] = useState({});
  const [filteredForms, setFilteredForms] = useState([]);
  const [previewStatus, setPreviewEnable] = useState(false);
  const [patientId, setPatientID] = useState("");
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [createPatientLoader, setCreatePatientLoader] = useState(false);

  const fetchFormFields = useCallback(async () => {
    const jwtToken = Cookies.get("jwt_token");
    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/get_form_fields?form_id=${formId}`;
    console.log(url);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${jwtToken}`);
    const options = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    const response = await fetch(url, options);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      setFormFields(responseObj.fields);
      setFilteredForms(responseObj.fields);
      setFormTitle(responseObj.form_title);
      setLoaderStatus(false);
    } else {
      alert(responseObj.error);
    }
  }, [formId]);

  useEffect(() => {
    if (formDetails.editPatientDetails) {
      const editDetails = {};
      for (const key in formDetails.editPatientDetails.sections) {
        if (formDetails.editPatientDetails.sections.hasOwnProperty(key)) {
          const section = formDetails.editPatientDetails.sections[key];
          for (const subKey in section) {
            if (section.hasOwnProperty(subKey)) {
              editDetails[subKey] = section[subKey];
            }
          }
        }
      }
      setFormData(editDetails);
      setItemDetails(editDetails);
      setPatientID(formDetails.patient_id);
    }
  }, [formDetails.editPatientDetails, formDetails]);

  useEffect(() => {
    if (formDetails.sections) {
      setFormFields(formDetails.sections);
      setFilteredForms(formDetails.sections);
      setFormTitle(formDetails.form_title);
      setPreviewEnable(true);
      setLoaderStatus(false);
    } else {
      fetchFormFields();
    }
  }, [formDetails, fetchFormFields]);

  const handleFileUpload = (id, file) => {
    const fileUrl = URL.createObjectURL(file);
    setFormData({ ...formData, fileUrl: fileUrl, fileName: file.name });
  };

  const handleImageUpload = (id, image) => {
    const fileUrl = URL.createObjectURL(image);
    setFormData({ ...formData, ImageUrl: fileUrl, ImageName: image.name });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => {
        const prevArray = prevData[name] || [];
        if (checked) {
          return {
            ...prevData,
            [name]: [...prevArray, value],
          };
        } else {
          return {
            ...prevData,
            [name]: prevArray.filter((item) => item !== value),
          };
        }
      });
      setItemDetails((prevData) => {
        const prevArray = prevData[name] || [];
        if (checked) {
          return {
            ...prevData,
            [name]: [...prevArray, value],
          };
        } else {
          return {
            ...prevData,
            [name]: prevArray.filter((item) => item !== value),
          };
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      setItemDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    }
  };

  const handleButtonClick = (field, value) => {
    setSelectedValue((prevValues) => ({
      ...prevValues,
      [field.api_key]: value,
    }));
    setFormData({ ...formData, [field.api_key]: value });
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      [field.api_key]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setCreatePatientLoader(true);
    const campId = Cookies.get("global_camp_id");
    const formId = Cookies.get("global_form_id");
    const url =
      "https://apollo-totalhealth-camp-ai.azurewebsites.net/create-patient";
    const jwt_token = Cookies.get("jwt_token");

    const structuredData = {};
    formData.height = parseInt(formData.height);
    formData.weight = parseInt(formData.weight);
    formData.bmi = parseInt(formData.bmi);
    formData.p_diabetes =
      formData.p_diabetes === "yes" || formData.p_diabetes === "Yes"
        ? "yes"
        : "no";
    formData.f_diabetes =
      formData.f_diabetes === "yes" || formData.f_diabetes === "Yes"
        ? "yes"
        : "no";
    formData.formId = formId;
    formData.camp_id = campId;

    let sections = {};
    formFields.forEach((section) => {
      sections[section.name] = {};
      section.items.forEach((field) => {
        sections[section.name][field.api_key] = formData[field.api_key];
      });
    });

    structuredData.sections = sections;
    structuredData.formId = formId;
    structuredData.camp_id = campId;
    structuredData.patient_id = patientId;
    let raw = JSON.stringify(structuredData);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      const { aicvd_data, prediebetes_data } = responseObj;
      formData["aicvdData"] = aicvd_data;
      formData["prediabetesData"] = prediebetes_data;
      updateNewPatientData(formData);
      setFormData(formData);
      history.push("/report");
    } else {
      history.push("/error");
    }
  };

  const pushToUserList = () => {
    const jwt_token = Cookies.get("jwt_token");
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        console.log(e.message);
        return null;
      }
    };

    const parseJwtObj = parseJwt(jwt_token);
    const role = parseJwtObj.uRole;
    if (role !== "executive") {
      if (previewStatus)
        try {
          const state = {
            sections: JSON.parse(JSON.stringify(formFields)),
            form_title: form_title,
          };

          history.push({
            pathname: "/create-form",
            state: state,
          });
        } catch (error) {
          console.error("Error serializing state:", error);
        }
      else {
        console.log("function called");
        history.replace("/form-list");
        // history.goBack();
        return null;
      }
    } else history.replace("/participant-list");
  };

  const renderFields = (field) => {
    switch (field.formtype) {
      case "text":
        return (
          <div className="each-form-field" key={field.id}>
            <label className="field-label">{field.label}</label>
            <div className="field-info-container">
              <input
                type="text"
                className="input-element-field"
                placeholder={field.label}
                id={field.id}
                name={field.api_key}
                value={itemDetails[field.api_key]}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        );
      case "email":
        return (
          <div className="each-form-field" key={field.id}>
            <label className="field-label">{field.label}</label>
            <div className="field-info-container">
              <input
                type="email"
                className="input-element-field"
                placeholder={field.label}
                id={field.id}
                name={field.api_key}
                value={itemDetails[field.api_key]}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        );

      case "number":
        return (
          <div className="each-form-field" key={field.id}>
            <label className="field-label">{field.label}</label>
            <div className="field-info-container">
              <input
                type="number"
                className="input-element-field"
                placeholder={field.label}
                id={field.id}
                key={field.api_key}
                name={field.api_key}
                value={itemDetails[field.api_key]}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        );
      case "date":
        return (
          <div className="each-form-field" key={field.id}>
            <label className="field-label">{field.label}</label>
            <div className="field-info-container">
              <input
                type="date"
                className="input-element-field"
                placeholder={field.label}
                id={field.id}
                name={field.api_key}
                key={field.api_key}
                value={itemDetails[field.api_key]}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        );
      case "text-area":
        return (
          <div className="each-form-field" key={field.id}>
            <label className="field-label">{field.label}</label>
            <div className="field-info-container">
              <input
                type={field.formType}
                className="input-element-field"
                placeholder={field.label}
                id={field.id}
                key={field.api_key}
                name={field.api_key}
                value={itemDetails[field.api_key]}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        );
      case "checkbox":
        return (
          <div className="each-form-field" key={field.id}>
            <label className="field-label">{field.label}</label>
            <div className="checkbox-option-list-info">
              {field.options &&
                field.options.map((option, index) => (
                  <div key={option.id} className="field-checkbox-group">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      name={field.api_key}
                      value={option.label}
                      key={field.api_key}
                      id={option.id}
                      checked={itemDetails[field.api_key]?.includes(
                        option.label
                      )}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <span className="custom-checkbox ">
                      <img
                        src="checkmark-outline-white.svg"
                        alt="checkmark-outline-white"
                      />
                    </span>
                    <label className="checkbox-label" htmlFor={option.id}>
                      {option.label}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        );
      case "radio":
        return (
          <div className="each-form-field" key={field.id}>
            <label className="field-label">{field.label}</label>
            <div
              className={`${
                field.options.length > 2
                  ? "option-list-info-morethan-two"
                  : "option-list-info"
              }`}
            >
              {field.options &&
                field.options.map((option, index) => (
                  <div key={option.id} className="radio-label-container">
                    <input
                      type="radio"
                      name={field.api_key}
                      id={option.id}
                      key={field.api_key}
                      className="radio-button"
                      value={option.label}
                      checked={
                        itemDetails[field.api_key]?.toLowerCase() ===
                        option.label?.toLowerCase()
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <span className="c-radio"></span>
                    <label className="checkbox-label" htmlFor={option.id}>
                      {option.label}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        );
      case "select":
        return (
          <div className="each-form-field" key={field.id}>
            <label className="field-label">{field.label}</label>
            <div className="select-container">
              <select
                id={field.id}
                name={field.api_key}
                // required={field.required}
                defaultValue=""
                key={field.api_key}
                value={itemDetails[field.api_key]}
                onChange={(e) => handleInputChange(e)}
                className="fields-select-tag"
              >
                <option value="" disabled>
                  Select
                </option>
                {field.options &&
                  field.options.map((option, index) => (
                    <option key={option.id} value={option.label}>
                      {option.label}
                    </option>
                  ))}
              </select>
              <IoIosArrowDown className="dropdown-icon" />
            </div>
          </div>
        );
      case "button":
        return (
          <div className="each-form-field" key={field.id}>
            <label className="field-label">{field.label}</label>
            <div className="button-group">
              {field.options &&
                field.options.map((option) => (
                  <button
                    className={`button-yesorno ${
                      selectedValue[field.api_key] === option.label
                        ? "selected"
                        : ""
                    } ${
                      itemDetails[field.api_key] === option.label
                        ? "selected"
                        : ""
                    }`}
                    key={option.id}
                    type="button"
                    onClick={() => handleButtonClick(field, option.label)}
                  >
                    {option.label}
                  </button>
                ))}
            </div>
          </div>
        );
      case "image-upload":
        return (
          <div className="each-form-field" key={field.id}>
            <label className="field-label">{field.label}</label>
            <div className="field-info-container text-area">
              <ImageUpload
                id={field.id}
                onChange={handleImageUpload}
                value={itemDetails[field.api_key]}
              />
            </div>
          </div>
        );
      case "file-upload":
        return (
          <div className="each-form-field" key={field.id}>
            <label className="field-label">{field.label}</label>
            <div className="field-info-container text-area">
              <FileUpload
                id={field.id}
                onChange={handleFileUpload}
                value={itemDetails[field.api_key]}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const filteredFileds = (event) => {
    const value = event.target.value;
    if (value === "mandatory")
      setFilteredForms(
        formFields.map((section) => ({
          ...section,
          items: section.items.filter((item) => {
            return item.mandatory === true;
          }),
        }))
      );
    else setFilteredForms(formFields);
  };

  const jwt_token = Cookies.get("jwt_token");
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      console.log(e.message);
      return null;
    }
  };

  const parseJwtObj = parseJwt(jwt_token);
  const role = parseJwtObj.uRole;

  return (
    <>
      {loaderStatus ? (
        <div className="main-loader-container">
          <div className="loader-spinner"></div>
          <p>Loading...!</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="form-fields-page-container">
          <div className="header-form-bar">
            <div className="large-back-button-container">
              <button className="button back-button" onClick={pushToUserList}>
                <img src="./back-arrow.svg" alt="back-arrow.svg" />
              </button>
              <p className="large-back-button-text">Back</p>
            </div>
            <div className="filtered_selector">
              <select onChange={filteredFileds} className="select-tag">
                <option value="all">All</option>
                <option value="mandatory">Mandatory</option>
              </select>
              <IoIosArrowDown className="dropdown-icon" />
            </div>
          </div>
          <div className="inside-fields-form-container">
            <div className="title-container">
              <h1>{form_title}</h1>
            </div>
            <div className="fields-element-conatiner">
              {filteredForms.map((section, index) => {
                return (
                  <div key={index}>
                    <h1 className="form-section-name-title">{section.name}</h1>
                    <div className="section-form-fields-container">
                      {section.items.map((field) => (
                        <div
                          key={field.field_id}
                          className="section-form-fields"
                        >
                          {renderFields(field)}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="display-flex justify-content-center flex-column align-items-center margin-bottom-30 max-width-850 margin-auto">
              <ScrollDialog />
              <div className="d-flex flex-column mt-20">
                <div className="check-box-container">
                  <input
                    id="check1"
                    className="checkbox-input"
                    type="checkbox"
                    value="check1"
                    name="check1"
                    // onChange={this.updateInput}
                  />
                  <span className="custom-checkbox mr-10">
                    <img
                      src="checkmark-outline-white.svg"
                      alt="checkmark-outline-white"
                    />
                  </span>
                  <label
                    htmlFor="check1"
                    className="cursor-pointer instruction-checkbox light-color padding-top-bottom-none ml-10"
                  >
                    Patient has confirmed that he/she has read and understood
                    the information sheet for the above Risk Scores and have had
                    the opportunity to ask questions
                  </label>
                </div>
                <div className="check-box-container">
                  <input
                    id="check2"
                    className="checkbox-input"
                    type="checkbox"
                    value="check2"
                    name="check2"
                    // onChange={this.updateInput}
                  />
                  <span className="custom-checkbox ">
                    <img
                      src="checkmark-outline-white.svg"
                      alt="checkmark-outline-white"
                    />
                  </span>
                  <label
                    htmlFor="check2"
                    className="cursor-pointer instruction-checkbox light-color padding-top-bottom-none ml-10"
                  >
                    Patient has agreed to take part in the above NCD Risk Scores
                    in Apollo Hospitals.
                  </label>
                </div>
              </div>
              <p id="checkError" className="error-text"></p>
            </div>

            <div className="submit-conatiner">
              <button
                type="submit"
                className="form-submit-button"
                disabled={previewStatus || role === "admin"}
              >
                {createPatientLoader ? (
                  <div className="patient-submit-loader">
                    <Loader type="Oval" color="white" height={30} width={30} />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default withRouter(DisplayForm);
