import React from 'react';
import { withRouter } from 'react-router';

class AntibioticForm extends React.Component {
    render() {
        return (
            <div className='antibiotic-form-container'>
                hello
            </div>
        )
    }
}

export default withRouter(AntibioticForm);