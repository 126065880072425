import Cookies from "js-cookie";
import React from "react";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";

class Dashboard extends React.Component {
  state = {
    currentCampLoader: true,
    currentCampCount: "",
    complectedCampsCount: "",
  };

  componentDidMount = async () => {
    const jwt_token = Cookies.get("jwt_token");

    const url =
      "https://apollo-totalhealth-camp-ai.azurewebsites.net/camps-status";

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      this.setState({
        currentCampCount: responseObj.camps_status.current_camps,
        complectedCampsCount: responseObj.camps_status.complected_camps,
        currentCampLoader: false,
      });
    }
  };

  pushToDashboard = () => {
    const { history } = this.props;
    history.push("/dashboard");
  };

  pushToUserList = () => {
    const { history } = this.props;
    history.push("/user-list");
  };

  pushToCurrentCamps = () => {
    const { history } = this.props;
    history.push("/camps-list");
  };

  logout = () => {
    const { history } = this.props;
    history.replace("/login");
    Cookies.remove("jwt_token");
  };

  renderHoverMenu = () => {
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };
    const jwt_token = Cookies.get("jwt_token");

    const parseJwtObj = parseJwt(jwt_token);
    const userName = parseJwtObj.name;
    const role = parseJwtObj.uRole;
    return (
      <div id="hoverMenu" className="hover-menu-container">
        <div className="back-button-container">
          <button
            className="button back-button"
            type="button"
            onClick={this.closeMenu}
          >
            <img src="./back-arrow.svg" alt="back-arrow.svg" />
          </button>
          <p className="button-text">Back</p>
        </div>
        <div className="nav-option-container" onClick={this.pushToDashboard}>
          <img
            className="nav-option-icon"
            src="./clipboard-outline.svg"
            alt="clipboard-2.svg"
          />
          <p className="nav-option-text">Dashboard</p>
        </div>
        <div className="nav-option-container" onClick={this.pushToCurrentCamps}>
          <img
            className="nav-option-icon"
            src="./clipboard-outline.svg"
            alt="clipboard-2.svg"
          />
          <p className="nav-option-text">Camps</p>
        </div>
        <div className="nav-option-container" onClick={this.pushToUserList}>
          <img
            className="nav-option-icon"
            src="./people-outline.svg"
            alt="users.svg"
          />
          <p className="nav-option-text">Users</p>
        </div>
        <div className="user-details-container align-self-center">
          <div className="user-image-cirlce">
            <img className="icon" src="person-outline.svg" alt="" />
          </div>
          <div>
            <p className="user-name">{userName}</p>
            <p className="user-role">{role}</p>
          </div>
        </div>
        <button
          className="button logout-button maring-top-auto margin-bottom-40"
          type="button"
          onClick={this.logout}
        >
          <p className="button-text">Logout</p>
          {/* <img className="nav-option-icon" src="./log-out-outline.svg" alt="logout.svg" /> */}
        </button>
      </div>
    );
  };

  openMenu = () => {
    const element = document.getElementById("hoverMenu");
    element.style.transform = "translateX(0%)";
    element.style.boxShadow = "8px 0px 10px rgba(0, 0, 0, 0.19)";
  };

  closeMenu = () => {
    const element = document.getElementById("hoverMenu");
    element.style.transform = "translateX(-100%)";
    element.style.boxShadow = "none";
  };

  render() {
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };
    const jwt_token = Cookies.get("jwt_token");
    const parseJwtObj = parseJwt(jwt_token);

    if (parseJwtObj.uRole !== "admin") {
      const { history } = this.props;
      history.goBack();
      return null;
    }
    const { currentCampCount, currentCampLoader, complectedCampsCount } =
      this.state;
    return (
      <div className="dashboard-option-container">
        <div className="menu-icon-with-apollo-logo">
          <div className="menu-container" onClick={this.openMenu}>
            <span className="box"></span>
            <span className="box"></span>
            <span className="box"></span>
            <span className="box"></span>
          </div>
          <img
            className="small-apollo-logo"
            src="./logo.png"
            alt="apollo.png"
          />
        </div>
        <div className="heading-with-icon-container">
          <h1 className="heading">
            Dashboard
            <img
              className="heading-icon"
              src="./clipboard-outline.svg"
              alt="clipboard-2.svg"
            />
          </h1>
          <div className="userinfo-app-title-conainer">
            <div className="large-userinfo-container">
              <div className="large-userinfo">
                <p className="larg-user-name">{parseJwtObj.name}</p>
                <p className="large-user-role">{parseJwtObj.uRole}</p>
              </div>
              <div className="user-image-cirlce">
                <img
                  className="icon"
                  src="person-outline.svg"
                  alt="person-outline"
                />
              </div>
            </div>
            <div className="camp-heading">
              <p className="apollo-hospitals-text">Apollo Hospitals</p>
              <h1 className="heading text-bold">Health Camps</h1>
              <div className="heading-style-element-container">
                <div className="style-line"></div>
                <div className="style-dot"></div>
              </div>
            </div>
          </div>
        </div>
        {this.renderHoverMenu()}
        <div className="card-container">
          <div className="card card-1" onClick={this.pushToCurrentCamps}>
            {currentCampLoader ? (
              <Loader type="TailSpin" color="#ffffff" height={30} width={30} />
            ) : (
              <>
                <h1 className="card-value">{currentCampCount}</h1>
                <p className="card-text">Current Camps</p>
              </>
            )}
          </div>
          <div className="card card-2">
            <h1 className="card-value">{complectedCampsCount}</h1>
            <p className="card-text">Completed Camps</p>
          </div>
          <div className="card card-3">
            <p className="card-text">Region Wise Analysis Coming Soon</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
