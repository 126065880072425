import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

const UploadComponent = ({ placeholder, type, value, id, name, onChange }) => {
  return (
    <div key={id} className="input-field-container">
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        className="input-field"
        onChange={(e) => onChange(name, e.target.value)}
      />
      <div className="upload-component-disabled-input" disabled>
        <AiOutlineCloudUpload className="placeholder-upload-image" />
        <label>{placeholder}</label>
      </div>
    </div>
  );
};

export default UploadComponent;
