import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

const FormsList = () => {
  const [formList, setFormList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const jwt_token = Cookies.get("jwt_token");
        const url =
          "https://apollo-totalhealth-camp-ai.azurewebsites.net/get_forms_list";
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${jwt_token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(url, requestOptions);
        const responseObj = await response.json();

        if (responseObj.status === "success") {
          localStorage.setItem("formlist", JSON.stringify(responseObj.forms));
          setFormList(responseObj.forms); // Assuming responseObj.data contains the list of forms
        } else {
          setError("Failed to fetch forms");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFormData();
  }, []);

  const handleEditClick = async (formId) => {
    const jwt_token = Cookies.get("jwt_token");
    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/get_form_details?form_id=${formId}`; // Use backticks for string interpolation
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const responseObj = await response.json();

      if (responseObj.status === "success") {
        history.push({
          pathname: "/create-form",
          state: { formDetails: responseObj.data, formList: formList },
        });
      } else {
        console.error("Error fetching form details:", responseObj.msg);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteClick = async (formId) => {
    const jwt_token = Cookies.get("jwt_token");
    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/delete_form?form_id=${formId}`; // Use backticks for string interpolation
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const responseObj = await response.json();

      if (responseObj.status === "success") {
        setFormList((prevState) =>
          prevState.filter((option) => option.form_id !== formId)
        );
      } else {
        console.error("Error fetching form details:", responseObj.msg);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewClick = async (formId) => {
    history.push({
      pathname: "/form-fields",
      state: { formId: formId },
    });
  };

  const closeMenu = () => {
    const element = document.getElementById("hoverMenu");
    element.style.transform = "translateX(-100%)";
    element.style.boxShadow = "none";
  };

  const pushToDashboard = () => {
    history.push("/dashboard");
  };

  const pushToFormsList = () => {
    history.push("/form-list");
  };

  const pushToCreateForm = () => {
    history.push({
      pathname: "/create-form",
      state: { formList: formList },
    });
  };

  const renderFormsCard = (data, index) => {
    return (
      <div className="details-card-container" key={index}>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Name</p>
            <p className="content">{data.form_name}</p>
          </div>
          <div className="title-with-content-container">
            <p className="title text-right">Created on</p>
            <p className="content text-right">{data.created_date}</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container width-65">
            <p className="title">No of Fields</p>
            <p className="content overflow-x-auto text-lower">
              {data.totalItemCount}
            </p>
          </div>
          <div className="title-with-content-container width-35">
            <p className="title text-right">Created By</p>
            <p className="content text-right overflow-x-auto">
              {data.created_by}
            </p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Email</p>
            <p className="content overflow-x-auto">{data.created_email}</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="title-with-content-container">
            <p className="title">City</p>
            <p className="content overflow-x-auto highlight-content role-content">
              {data.city}
            </p>
          </div>
          <div className="form-card-buttons-container  width-60">
            <button
              className="button form-edit-button width-30"
              value={data.email}
              onClick={() => handleEditClick(data.form_id)}
            >
              Edit
            </button>
            <button
              className="button form-edit-button width-30"
              type="button"
              value={data.email}
              onClick={() => handleViewClick(data.form_id)}
            >
              View
            </button>
            <button
              className="button form-delete-button width-30"
              type="button"
              value={data.email}
              onClick={() => handleDeleteClick(data.form_id)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderHoverMenu = () => (
    <div id="hoverMenu" className="hover-menu-container">
      <div className="back-button-container">
        <button
          className="button back-button"
          type="button"
          onClick={closeMenu}
        >
          <img src="./back-arrow.svg" alt="back-arrow.svg" />
        </button>
        <p className="button-text">Back</p>
      </div>

      <div className="nav-option-container" onClick={pushToDashboard}>
        <p className="nav-option-text">Dashboard</p>
        <img
          className="nav-option-icon"
          src="./clipboard-outline.svg"
          alt="clipboard-2.svg"
        />
      </div>
      <div className="nav-option-container" onClick={pushToFormsList}>
        <p className="nav-option-text">Users</p>
        <img
          className="nav-option-icon"
          src="./people-outline.svg"
          alt="users.svg"
        />
      </div>
      <button
        className="button logout-button maring-top-auto margin-bottom-40"
        type="button"
        onClick={() => {
          /* Add logout functionality here */
        }}
      >
        <p className="button-text">Logout</p>
        <img
          className="nav-option-icon"
          src="./log-out-outline.svg"
          alt="logout.svg"
        />
      </button>
    </div>
  );
  return (
    <div className="user-list-container">
      <div className="menu-icon-with-apollo-logo">
        <div className="menu-container" onClick={() => {}}>
          <span className="box"></span>
          <span className="box"></span>
          <span className="box"></span>
          <span className="box"></span>
        </div>
        <img className="small-apollo-logo" src="./logo.png" alt="apollo.png" />
      </div>
      {renderHoverMenu()}
      <div className="sub-heading-with-button-container">
        <div className="sub-heading-with-logo width-50">
          <h1 className="heading">Forms List</h1>
          <img
            className="sub-heading-icon"
            src="./layers-outline.svg"
            alt="list.svg"
          />
        </div>
        <button className="button add-button" onClick={pushToCreateForm}>
          Add Form
        </button>
      </div>
      <div className="details-cards-container">
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : formList.length > 0 ? (
          formList.map((each, index) => renderFormsCard(each, index))
        ) : (
          <div className="d-flex flex-row justify-content-center w-100">
            <p className="not-found-text">Oops..! User Not Found</p>
            <img
              className="sub-heading-icon color-gray"
              src="./sad-outline.svg"
              alt="sad.svg"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FormsList;
