import Cookies from "js-cookie";
import React from "react";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";

class UsersList extends React.Component {
  state = {
    mainUserList: [],
    userList: [],
    deleteUserEmailId: "",
    editUserEmailId: "",
    newPassword: "",
    confirmPassword: "",
    isAnyErrorInEditFields: false,
    editContainerRanderStatus: "form",
    searchByValue: "Name",
    inActiveLoader: false,
    userInactiveStatus: false,
  };

  returnDate = (date) => {
    const dateFormat = new Date(date);
    return `${dateFormat.getDate()}-${
      dateFormat.getMonth() + 1
    }-${dateFormat.getFullYear()}`;
  };

  fetchUserslist = async () => {
    const jwt_token = Cookies.get("jwt_token");

    const url =
      "https://apollo-totalhealth-camp-ai.azurewebsites.net/users-list";

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      let userList = responseObj.users_list.map((each) => ({
        name: each.name,
        userId: each.user_id,
        role: each.role,
        phone: each.phone,
        hospital: each.hospital,
        email: each.email,
        createdDate: this.returnDate(each.created_date),
        userStatus: each.user_status,
      }));

      this.setState({
        mainUserList: userList,
        userList: userList,
      });
    }
  };

  componentDidMount() {
    this.fetchUserslist();
  }

  pushToDashboard = () => {
    const { history } = this.props;
    history.push("/dashboard");
  };

  pushToUserList = () => {
    const { history } = this.props;
    history.push("/user-list");
  };

  pushToCreateUser = () => {
    const { history } = this.props;
    history.push("/create-user");
  };

  filterUserList = (event) => {
    const userEnterWord = event.target.value.toLowerCase();
    const { searchByValue } = this.state;

    let filterList;

    if (searchByValue === "Name") {
      filterList = this.state.mainUserList.filter((each) =>
        each.name.toLowerCase().includes(userEnterWord)
      );
    } else if (searchByValue === "Hospital") {
      filterList = this.state.mainUserList.filter((each) =>
        each.hospital.toLowerCase().includes(userEnterWord)
      );
    } else if (searchByValue === "Phone") {
      filterList = this.state.mainUserList.filter((each) =>
        each.phone.toLowerCase().includes(userEnterWord)
      );
    } else {
      filterList = this.state.mainUserList.filter((each) =>
        each.email.toLowerCase().includes(userEnterWord)
      );
    }
    this.setState({
      userList: filterList,
    });
  };

  renderHoverMenu = () => (
    <div id="hoverMenu" className="hover-menu-container">
      <div className="back-button-container">
        <button
          className="button back-button"
          type="button"
          onClick={this.closeMenu}
        >
          <img src="./back-arrow.svg" alt="back-arrow.svg" />
        </button>
        <p className="button-text">Back</p>
      </div>

      <div className="nav-option-container" onClick={this.pushToDashboard}>
        <p className="nav-option-text">Dashboard</p>
        <img
          className="nav-option-icon"
          src="./clipboard-outline.svg"
          alt="clipboard-2.svg"
        />
      </div>
      <div className="nav-option-container" onClick={this.pushToUserList}>
        <p className="nav-option-text">Users</p>
        <img
          className="nav-option-icon"
          src="./people-outline.svg"
          alt="users.svg"
        />
      </div>
      <button
        className="button logout-button maring-top-auto margin-bottom-40"
        type="button"
        onClick={this.logout}
      >
        <p className="button-text">Logout</p>
        <img
          className="nav-option-icon"
          src="./log-out-outline.svg"
          alt="logout.svg"
        />
      </button>
    </div>
  );

  openMenu = () => {
    const element = document.getElementById("hoverMenu");
    element.style.transform = "translateX(0%)";
    element.style.boxShadow = "8px 0px 10px rgba(0, 0, 0, 0.19)";
  };

  closeMenu = () => {
    const element = document.getElementById("hoverMenu");
    element.style.transform = "translateX(-100%)";
    element.style.boxShadow = "none";
  };

  renderUsersCard = (data, index) => {
    return (
      <div className="details-card-container" key={index}>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Name</p>
            <p className="content">{data.name}</p>
          </div>
          <div className="title-with-content-container">
            <p className="title text-right">Created on</p>
            <p className="content text-right">{data.createdDate}</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container width-65">
            <p className="title">Email</p>
            <p className="content overflow-x-auto text-lower">{data.email}</p>
          </div>
          <div className="title-with-content-container width-35">
            <p className="title text-right">Phone</p>
            <p className="content text-right overflow-x-auto">{data.phone}</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Hospital</p>
            <p className="content overflow-x-auto">{data.hospital}</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="title-with-content-container width-50">
            <p className="title">Role</p>
            <p className="content overflow-x-auto highlight-content role-content">
              {data.role}
            </p>
          </div>
          {data.userStatus ? (
            <div className="card-buttons-container">
              <button
                className="button edit-button"
                value={data.email}
                onClick={this.editButtonClicked}
              >
                Edit
              </button>
              <button
                className="button delete-button"
                type="button"
                value={data.email}
                onClick={this.confirmToDeleteUser}
              >
                Inactive
              </button>
            </div>
          ) : (
            <div className="title-with-content-container width-50">
              <p className="title">User Status</p>
              <p className="content overflow-x-auto highlight-content role-content">
                Inactive
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  confirmToDeleteUser = (event) => {
    const email = event.target.value;
    const element = document.getElementById("deleteContainer");
    const body = document.querySelector("body");

    element.classList.remove("d-none");
    body.style.overflow = "hidden";

    this.setState({
      deleteUserEmailId: email,
    });
  };

  editButtonClicked = (event) => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";

    const editUserEle = document.getElementById("editContainer");
    editUserEle.classList.remove("d-none");

    const email = event.target.value;
    this.setState({
      editUserEmailId: email,
    });
  };

  confirmButtonCliked = () => {
    this.setState({
      inActiveLoader: true,
    });
    this.deleteUser();
  };

  deleteUser = async () => {
    const { deleteUserEmailId } = this.state;

    const url =
      "https://apollo-totalhealth-camp-ai.azurewebsites.net/inactivate-user";
    const jwt_token = Cookies.get("jwt_token");

    const raw = JSON.stringify({
      email: deleteUserEmailId,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();
    if (responseObj.status === "success") {
      this.setState({
        userInactiveStatus: true,
      });
    }
  };

  removePopup = () => {
    this.fetchUserslist();

    const element = document.getElementById("deleteContainer");
    const body = document.querySelector("body");

    element.classList.add("d-none");
    body.style.overflow = "scroll";
  };

  logout = () => {
    const { history } = this.props;
    history.replace("/login");
    Cookies.remove("jwt_token");
  };

  cancelButtonClicked = () => {
    const element = document.getElementById("deleteContainer");
    const body = document.querySelector("body");

    element.classList.add("d-none");
    body.style.overflow = "scroll";
  };

  editUserPassword = async (event) => {
    event.preventDefault();

    const { newPassword, confirmPassword } = this.state;
    if (newPassword !== "" && confirmPassword !== "") {
      if (newPassword === confirmPassword) {
        const url =
          "https://apollo-totalhealth-camp-ai.azurewebsites.net/edit-user";
        const jwt_token = Cookies.get("jwt_token");

        const raw = JSON.stringify({
          email: this.state.editUserEmailId,
          password: newPassword,
        });

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${jwt_token}`);

        let requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(url, requestOptions);
        const responseObj = await response.json();

        if (responseObj.status === "success") {
          this.setState({
            editContainerRanderStatus: "result",
            isAnyErrorInEditFields: false,
            errorText: "",
          });
        }
      } else {
        this.setState({
          isAnyErrorInEditFields: true,
          errorText: "Passowrd did not matched",
        });
      }
    } else {
      this.setState({
        isAnyErrorInEditFields: true,
        errorText: "Please fill the input fileds",
      });
    }
  };

  removeUserEditForm = () => {
    const body = document.querySelector("body");
    body.style.overflow = "scroll";

    const editUserEle = document.getElementById("editContainer");
    editUserEle.classList.add("d-none");

    this.setState({
      editContainerRanderStatus: "form",
    });
  };

  updateEditFileds = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "new-password") {
      this.setState({
        newPassword: value,
      });
    } else {
      this.setState({
        confirmPassword: value,
      });
    }
  };

  updateSearchBy = (event) => {
    const value = event.target.value;
    this.setState({ searchByValue: value });
  };

  renderEditContainerContent = () => {
    const { editContainerRanderStatus } = this.state;

    if (editContainerRanderStatus === "form") {
      const { isAnyErrorInEditFields, errorText } = this.state;
      return (
        <>
          <h1 className="edit-form-heading">Change Password</h1>
          <div className="input-container">
            <label className="label">New Password</label>
            <div className="input-with-logo">
              <input
                type="password"
                className="login-input"
                placeholder="*********"
                name="new-password"
                onChange={this.updateEditFileds}
              />
              <img className="icon" src="./key-outline.svg" alt="apollo.png" />
            </div>
          </div>
          <div className="input-container">
            <label className="label">Confirm Password</label>
            <div className="input-with-logo">
              <input
                type="password"
                className="login-input"
                placeholder="*********"
                name="confire-password"
                onChange={this.updateEditFileds}
              />
              <img className="icon" src="./key-outline.svg" alt="apollo.png" />
            </div>
          </div>
          {isAnyErrorInEditFields && (
            <p className="edit-error-text">{errorText}</p>
          )}
          <div className="edit-button-container">
            <button
              className="button change-password-button"
              type="button"
              onClick={this.removeUserEditForm}
            >
              Cancel
            </button>
            <button className="button change-password-button" type="submit">
              Confirm
            </button>
          </div>
        </>
      );
    } else if (editContainerRanderStatus === "result") {
      return (
        <>
          <h1 className="edit-form-heading">Password changed successfully</h1>
          <button
            className="button change-password-button"
            type="button"
            onClick={this.removeUserEditForm}
          >
            Done
          </button>
        </>
      );
    } else {
      return <Loader type="Oval" color="#FF883E" height={50} width={50} />;
    }
  };

  render() {
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };
    const jwt_token = Cookies.get("jwt_token");
    const parseJwtObj = parseJwt(jwt_token);

    if (parseJwtObj.uRole !== "admin") {
      const { history } = this.props;
      history.goBack();
      return null;
    }
    const { userList, inActiveLoader, userInactiveStatus } = this.state;
    return (
      <div className="user-list-container">
        <div id="deleteContainer" className="pop-up-container d-none">
          {inActiveLoader ? (
            <div className="pop-up-box">
              {userInactiveStatus ? (
                <div className="success-container">
                  <div className="tickmark-container">
                    <img
                      className="tickmark-icon"
                      src="./checkmark-outline.svg"
                      alt="checkmark-outline"
                    />
                  </div>
                  <p className="success-text">User Inactivated Successfully</p>
                  <button
                    className="button done-button"
                    onClick={this.removePopup}
                  >
                    Done
                  </button>
                </div>
              ) : (
                <Loader type="Oval" color="#FF883E" height={50} width={50} />
              )}
            </div>
          ) : (
            <div className="delete-container">
              <p className="delete-text">
                Please <span className="confirm-text">CONFIRM</span> to Inactive
                the user
              </p>
              <div className="delete-button-container">
                <button
                  className="button cancel-button"
                  type="button"
                  onClick={this.cancelButtonClicked}
                >
                  Cancel
                </button>
                <button
                  className="button confirm-button"
                  type="button"
                  onClick={this.confirmButtonCliked}
                >
                  Confirm
                </button>
              </div>
            </div>
          )}
        </div>
        <div id="editContainer" className="pop-up-container d-none">
          <form className="edit-user-form" onSubmit={this.editUserPassword}>
            {this.renderEditContainerContent()}
          </form>
        </div>
        <div className="menu-icon-with-apollo-logo">
          <div className="menu-container" onClick={this.openMenu}>
            <span className="box"></span>
            <span className="box"></span>
            <span className="box"></span>
            <span className="box"></span>
          </div>
          <img
            className="small-apollo-logo"
            src="./logo.png"
            alt="apollo.png"
          />
        </div>
        {this.renderHoverMenu()}
        <div className="sub-heading-with-button-container">
          <div className="sub-heading-with-logo width-50">
            <h1 className="heading">User List</h1>
            <img
              className="sub-heading-icon"
              src="./layers-outline.svg"
              alt="list.svg"
            />
          </div>
          <button className="button add-button" onClick={this.pushToCreateUser}>
            Add User
          </button>
        </div>
        <div className="input-container max-width-400 margin-none search-with-select">
          <div className="input-with-logo">
            <input
              type="text"
              className="login-input"
              placeholder="Search"
              autoComplete="off"
              name="search"
              onChange={this.filterUserList}
            />
            <img className="icon" src="./search.svg" alt="apollo.png" />
          </div>
          <select className="select-bar" onChange={this.updateSearchBy}>
            <option>Name</option>
            <option>Hospital</option>
            <option>Phone</option>
            <option>Email</option>
          </select>
        </div>
        <div className="details-cards-container">
          {userList.length > 0 ? (
            userList.map((each, index) => this.renderUsersCard(each, index))
          ) : (
            <div className="d-flex flex-row justify-content-center w-100">
              <p className="not-found-text">Oops..! User Not Found</p>
              <img
                className="sub-heading-icon color-gray"
                src="./sad-outline.svg"
                alt="sad.svg"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(UsersList);
