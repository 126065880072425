import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import "./doctor.css";
import ViewGraph from "../DoctorViewGraph";
import Loader from "react-loader-spinner";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

const PatientReviewPage = () => {
  const [heartRate, setHeartRate] = useState([]);
  const [sbp, setsbp] = useState([]);
  const [dbp, setdbp] = useState([]);
  const [spo2, setspo2] = useState([]);
  const [patient_data, setPatientData] = useState([]);
  const [completepatientData, setcompleteData] = useState([]);
  const [lastVisitData, setLastVisitData] = useState(null);
  const [lastvisitVitals, setlastVitals] = useState({});
  const [datapoints, setDataPoints] = useState([]);
  const [listofGraph, setListOfGraph] = useState([]);
  const [selectedData, setselectedData] = useState([]);
  const [visitArray, setVisitArray] = useState([]);
  const [filteredSection, setFilteredSection] = useState([]);
  const [visitedValue, setVisitedValue] = useState("");

  const [aicvdLabTest, setAicvdLabTest] = useState([]);
  const [aicvdDiaImg, setAicvdDiaImg] = useState([]);
  const [doctorSelectedAcivdLabTests, setDoctorSelectedAcivdLabTests] =
    useState([]);
  const [doctorSelectedDiaImg, setDoctorSelectedDiaImg] = useState([]);
  const [prediabetesLabTest, setPrediabetesLabTest] = useState([]);
  const [
    doctorSelectedPrediabetesLabTest,
    setDoctorSelectedPrediabetesLabTest,
  ] = useState([]);

  const [doctorReview, setDoctorReview] = useState("");
  const [confirmStatus, setConfirmStatus] = useState(true);
  const [reviewLoader, setReviewLoader] = useState("");

  const history = useHistory();

  useEffect(() => {
    const jwt_token = Cookies.get("jwt_token");
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };

    const parseJwtObj = parseJwt(jwt_token);
    const role = parseJwtObj.uRole;

    if (role === "doctor") {
      fetchPatientData();
    } else {
      const patient_info = JSON.parse(localStorage.getItem("patientReport"));
      setcompleteData(patient_info);
      setPatientData(patient_info.visits);
    }
  }, []);

  const fetchPatientData = async () => {
    const id = Cookies.get("patient_id");
    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/patient-data?id=${id}`;
    const jwt_token = Cookies.get("jwt_token");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();
    if (responseObj.status === "success") {
      console.log(responseObj);
      setcompleteData(responseObj.patient_data);
      setPatientData(responseObj.patient_data.visits);
      setDoctorReview(responseObj.patient_data.review);
    }
  };

  useEffect(() => {
    const processData = (data) => {
      let heartRates = [];
      let sbps = [];
      let dbps = [];
      let spo2s = [];
      let numericValues = [];
      let visits = [];

      if (!data || data.length === 0) {
        return { heartRates, sbps, dbps, spo2s };
      }

      const isNumeric = (value) => {
        return !isNaN(parseFloat(value)) && isFinite(value);
      };

      const list_visit = data[data.length - 1];

      data.forEach((visit, dataindex) => {
        const { sections, visit_date } = visit;
        visits.push(visit_date);

        let heart_rate, sbp, dbp, spo2;

        if (sections) {
          const sectionKeys = Object.keys(sections);

          sectionKeys.forEach((section, sectionIndex) => {
            if (sections[section].heart_rate) {
              heart_rate = parseFloat(sections[section].heart_rate);
            }
            if (sections[section].systolic_bp) {
              sbp = parseFloat(sections[section].systolic_bp);
            }
            if (sections[section].diastolic_bp) {
              dbp = parseFloat(sections[section].diastolic_bp);
            }
            if (sections[section].spo2) {
              spo2 = parseFloat(sections[section].spo2);
            }
          });
        }

        const visitDate = new Date(visit_date);
        const formattedDate = visitDate.toLocaleDateString();
        const formattedTime = visitDate.toLocaleTimeString();

        heartRates.push({
          x: `${formattedDate} ${formattedTime}`,
          y: heart_rate,
        });
        sbps.push({ x: `${formattedDate} ${formattedTime}`, y: sbp });
        dbps.push({ x: `${formattedDate} ${formattedTime}`, y: dbp });
        spo2s.push({ x: `${formattedDate} ${formattedTime}`, y: spo2 });

        if (dataindex === data.length - 1) {
          setlastVitals({
            heart_rate: heart_rate,
            sbp: sbp,
            dbp: dbp,
            spo2: spo2,
          });
        }
      });

      if (list_visit && list_visit.sections) {
        for (const key in list_visit.sections) {
          setLastVisitData(list_visit);
          setFilteredSection(list_visit.sections);
          setVisitedValue(list_visit.visit_date);
          if (list_visit.sections.hasOwnProperty(key)) {
            const section = list_visit.sections[key];
            for (const subKey in section) {
              if (
                isNumeric(section[subKey]) &&
                subKey !== "heart_rate" &&
                subKey !== "systolic_bp" &&
                subKey !== "diastolic_bp" &&
                subKey !== "spo2" &&
                subKey !== "uhid" &&
                subKey !== "age"
              ) {
                numericValues.push(subKey);
              }
            }
          }
        }
      }
      setselectedData(numericValues[0]);
      setListOfGraph(numericValues);
      setVisitArray(visits);

      return { heartRates, sbps, dbps, spo2s };
    };

    if (patient_data) {
      const { heartRates, sbps, dbps, spo2s } = processData(patient_data);
      setHeartRate(heartRates);
      setsbp(sbps);
      setdbp(dbps);
      setspo2(spo2s);
    }
  }, [patient_data]);


  const extractgraphData = useCallback(() => {
    const dataset = [];
    patient_data.forEach((visit) => {
      const { sections, visit_date } = visit;
      const visitDate = new Date(visit_date);
      const formattedDate = visitDate.toLocaleDateString();
      if (sections) {
        const sectionKeys = Object.keys(sections);
        sectionKeys.forEach((section) => {
          const sectionValues = sections[section];
          Object.keys(sectionValues).forEach((each_value) => {
            if (each_value === selectedData) {
              dataset.push({
                x: `${formattedDate}`,
                y: parseFloat(sectionValues[selectedData]),
              });
            }
          });
        });
      }
    });

    setDataPoints(dataset);
  }, [patient_data,selectedData]);

  useEffect(() => {
    extractgraphData(selectedData);
  }, [selectedData,extractgraphData]);
  const findValue = useCallback((data, key) => {
    if (data) {
      if (data.hasOwnProperty(key)) {
        return data[key];
      }
      for (let k in data) {
        if (typeof data[k] === "object" && data[k] !== null) {
          const found = findValue(data[k], key);
          if (found !== undefined) {
            return found;
          }
        }
      }
      return undefined;
    }
  }, []);

  useEffect(() => {
    if (lastVisitData) {
      let aicvdLabTest = [];
      let lowerCaseAicvdLabTest = [];
      let aicvdDiaImg = [];
      const aicvdLabReport = JSON.parse(lastVisitData.aicvdlabreport);
      const allLabInvestigation = aicvdLabReport.LabInvestigationRecommended;
      for (let key in allLabInvestigation) {
        if (allLabInvestigation[key] === "Yes") {
          aicvdLabTest.push(key);
          lowerCaseAicvdLabTest.push(key.toLowerCase());
        }
      }
      const aicvdDiaImgReport = aicvdLabReport.DiagnosticsAndImagingRecommended;
      for (let key in aicvdDiaImgReport) {
        if (aicvdDiaImgReport[key] === "Yes") {
          aicvdDiaImg.push(key);
        }
      }

      let prediabetesLabTest = [];

      if (findValue(lastVisitData?.sections, "p_diabetes") === "no") {
        const prediabteLabReport = JSON.parse(
          lastVisitData.prediabeteslabreport
        );
        const reportPrediabetesLabTests =
          prediabteLabReport.data.lab_investigation;

        for (let item of reportPrediabetesLabTests.split(",")) {
          item = item.trim();
          if (!lowerCaseAicvdLabTest.includes(item.toLowerCase())) {
            prediabetesLabTest.push(item);
          }
          if (
            item.includes("Fasting Blood Sugar") &&
            lowerCaseAicvdLabTest.includes("fasting blood sugar")
          ) {
            prediabetesLabTest.pop();
          }
        }
      }
      setAicvdLabTest(aicvdLabTest);
      setDoctorSelectedAcivdLabTests([...aicvdLabTest]);
      setAicvdDiaImg(aicvdDiaImg);
      setDoctorSelectedDiaImg([...aicvdDiaImg]);
      setPrediabetesLabTest(prediabetesLabTest);
      setDoctorSelectedPrediabetesLabTest([...prediabetesLabTest]);
    }
  }, [lastVisitData, findValue]);



  const updategraphData = (event) => {
    const selected_value = event.target.value;
    setselectedData(selected_value);
  };

  const ReportCard = (risk_value) => {
    let riskScore, riskStatus, riskProfile;
    if (lastVisitData) {
      if (risk_value === "AICVD") {
        riskStatus = lastVisitData.aicvdcat;
        riskScore = lastVisitData.aicvdscore;
        riskProfile = lastVisitData.aicvdoptimal;
      } else {
        riskStatus = lastVisitData.diarisk;
        riskScore = lastVisitData.diascore;
      }
    }
    if (lastVisitData) {
      let riskContainerColor = "";
      if (riskStatus.toLowerCase() === "high risk")
        riskContainerColor = "high-risk";
      else if (
        riskStatus.toLowerCase() === "moderate risk" ||
        riskStatus.toLowerCase() === "medium risk" ||
        riskStatus.toLowerCase() === "mid risk"
      )
        riskContainerColor = "medium-risk";
      else riskContainerColor = "low-risk";
      return (
        <div id="card" className="aicvd-risk-block" key="">
          <div className="d-flex flex-row justify-content-center align-items-center card-content-gap">
            <img
              className="risk-card-img"
              src={`${
                risk_value === "AICVD" ? "./heart.png" : "./diabetes.png"
              }`}
              alt={`aicvd.png`}
            />
          </div>
          <div className="d-flex align-items-end direction-of-flex">
            <div className="risk-catagory-container">
              <p className="catagory-title">Risk Status</p>
              <p className={`catagory-value ${riskContainerColor}`}>
                {riskStatus}
              </p>
            </div>
            <div className="risk-catagory-container">
              <p className="catagory-title">Risk Score</p>
              <p className="catagory-value">{riskScore}</p>
            </div>
            {risk_value === "AICVD" && (
              <div className="risk-catagory-container">
                <p className="catagory-title">Acceptable Score</p>
                <p className="catagory-value">{riskProfile}</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  const getVisitData = useCallback(() => {
    if (!patient_data || patient_data.length === 0) {
      setFilteredSection([]);
    }
    if (patient_data) {
      patient_data.forEach((visit) => {
        const { sections, visit_date } = visit;
        if (visitedValue === visit_date) {
          setFilteredSection(sections);
        }
      });
    }
  }, [patient_data, visitedValue]);

  useEffect(() => {
    getVisitData(visitedValue);
  }, [visitedValue, getVisitData]);

  const updateVisitedValue = (event) => {
    setVisitedValue(event.target.value);
  };

  const aicvdLabTestsTrigger = (event) => {
    const value = event.target.value;

    if (doctorSelectedAcivdLabTests.includes(value)) {
      let index = -1;

      for (let item in doctorSelectedAcivdLabTests) {
        if (doctorSelectedAcivdLabTests[item] === value) index = item;
      }

      doctorSelectedAcivdLabTests.splice(index, 1);
      setDoctorSelectedAcivdLabTests(doctorSelectedAcivdLabTests);
    } else {
      doctorSelectedAcivdLabTests.push(value);
      setDoctorSelectedAcivdLabTests(doctorSelectedAcivdLabTests);
    }
  };

  const prediabetesLabTestTrigger = (event) => {
    const value = event.target.value;

    if (doctorSelectedPrediabetesLabTest.includes(value)) {
      let index = -1;

      for (let item in doctorSelectedPrediabetesLabTest) {
        if (doctorSelectedPrediabetesLabTest[item] === value) index = item;
      }

      doctorSelectedPrediabetesLabTest.splice(index, 1);
      setDoctorSelectedPrediabetesLabTest(doctorSelectedPrediabetesLabTest);
    } else {
      doctorSelectedPrediabetesLabTest.push(value);
      setDoctorSelectedPrediabetesLabTest(doctorSelectedPrediabetesLabTest);
    }
  };

  const aicvdDiaImgTrigger = (event) => {
    const value = event.target.value;

    if (doctorSelectedDiaImg.includes(value)) {
      let index = -1;

      for (let item in doctorSelectedDiaImg) {
        if (doctorSelectedDiaImg[item] === value) index = item;
      }

      doctorSelectedDiaImg.splice(index, 1);
      setDoctorSelectedDiaImg(doctorSelectedDiaImg);
    } else {
      doctorSelectedDiaImg.push(value);
      setDoctorSelectedDiaImg(doctorSelectedDiaImg);
    }
  };

  const updateNewLabTest = () => {
    const value = document.getElementById("aiLabTestInput").value;
    if (value !== "") {
      setAicvdLabTest((prev) => [...prev, value]);
      setDoctorSelectedAcivdLabTests((prev) => [...prev, value]);
      document.getElementById("aiLabTestInput").value = "";
    }
  };

  const updateNewDiaImgTest = () => {
    const value = document.getElementById("diaImgTestInput").value;
    if (value !== "") {
      setAicvdDiaImg((prev) => [...prev, value]);
      setDoctorSelectedDiaImg((prev) => [...prev, value]);
      document.getElementById("diaImgTestInput").value = "";
    }
  };

  const updateDoctorReview = (event) => {
    setDoctorReview(event.target.value);
  };

  const addReviewPopUp = () => {
    const reviewPopupEl = document.getElementById("reviewPopup");
    const body = document.querySelector("body");

    reviewPopupEl.classList.remove("d-none");
    body.style.overflow = "hidden";
  };

  const removeReviewPopUp = () => {
    const reviewPopupEl = document.getElementById("reviewPopup");
    const body = document.querySelector("body");

    reviewPopupEl.classList.add("d-none");
    body.style.overflow = "scroll";

    history.push("/participant-list");
  };

  const confirmButtonClicked = () => {
    setConfirmStatus(false);
    submitReview();
  };

  const submitReview = async () => {
    if (doctorReview === "") {
      setDoctorReview(completepatientData.review);
    }

    let aicvdLabTest = JSON.parse(lastVisitData.aicvdlabreport);

    for (let key in aicvdLabTest.LabInvestigationRecommended) {
      if (!doctorSelectedAcivdLabTests.includes(key)) {
        aicvdLabTest.LabInvestigationRecommended[key] = "No";
      }
    }

    for (let key of doctorSelectedAcivdLabTests) {
      aicvdLabTest.LabInvestigationRecommended[key] = "Yes";
    }

    for (let key in aicvdLabTest.DiagnosticsAndImagingRecommended) {
      if (!doctorSelectedDiaImg.includes(key)) {
        aicvdLabTest.DiagnosticsAndImagingRecommended[key] = "No";
      }
    }

    for (let key of doctorSelectedDiaImg) {
      aicvdLabTest.DiagnosticsAndImagingRecommended[key] = "Yes";
    }

    let prediabetesLabTest = {};

    if (findValue(lastVisitData?.sections, "p_diabetes") === "no") {
      prediabetesLabTest = JSON.parse(lastVisitData.prediabeteslabreport).data;
      prediabetesLabTest.lab_investigation =
        doctorSelectedPrediabetesLabTest.join(", ");
    }

    const url =
      "https://apollo-totalhealth-camp-ai.azurewebsites.net/doctor-review";
    const jwt_token = Cookies.get("jwt_token");
    const id = completepatientData.patient_id;

    const raw = {
      id,
      doctorReview,
      aicvd_updated_report: JSON.stringify(aicvdLabTest),
      prediabetes_updated_report: JSON.stringify({
        data: prediabetesLabTest,
      }),
    };

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      setReviewLoader(false);
    }
  };

  const cancelButtonClicked = () => {
    const element = document.getElementById("reviewPopup");
    const body = document.querySelector("body");

    element.classList.add("d-none");
    body.style.overflow = "scroll";
  };

  const renderSections = () => {
    const renderObject = {
      f_diabetes: "Diabetes Mellites",
      f_dyslipidemia: "Dyslipidemia",
      f_hypertension: "Hypertension",
      f_heart_disease: "Heart Disease",
      p_diabetes: "Diabetes Mellites",
      p_dyslipidemia: "Dyslipidemia",
      p_hypertension: "Hypertension",
      p_heart_disease: "Heart Disease",
    };
    if (filteredSection) {
      return Object.keys(filteredSection).map((sectionName) => {
        const excludedKeys = ["dob", "age", "gender", "uhid"];
        const filteredKeys = Object.keys(filteredSection[sectionName]).filter(
          (key) => !excludedKeys.includes(key)
        );
        if (filteredKeys.length > 0) {
          return (
            <div key={sectionName} className="">
              <h2 className="vitals-title">{sectionName}</h2>
              <div className="attributes-block">
                {filteredKeys.map((key) => {
                  const validValues = ["yes", "Yes", "no", "No"];
                  return (
                    <div className="each-attribute" key={key}>
                      <div className="field-container width-100">
                        <p className="atribute-field-text">
                          {renderObject.hasOwnProperty(key)
                            ? renderObject[key]
                            : key
                                .replace(/[_-]/g, " ")
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                        </p>
                        {!validValues.includes(
                          filteredSection[sectionName][key]
                        ) ? (
                          <p className="attribute-data-field">
                            {filteredSection[sectionName][key]}
                          </p>
                        ) : (
                          <div className="yes-no-container">
                            <p>Yes</p>
                            <div
                              className={`attribute-tik-container ${
                                (filteredSection[sectionName][key] === "yes" ||
                                  filteredSection[sectionName][key] ===
                                    "Yes") &&
                                "attribute-solid-bg"
                              }`}
                            >
                              <img
                                className="pdf-check-mark"
                                src="checkmark-outline-white.svg"
                                alt="checkmark.png"
                              />
                            </div>
                            <p className="ml-10">No</p>
                            <div
                              className={`attribute-tik-container ${
                                (filteredSection[sectionName][key] === "No" ||
                                  filteredSection[sectionName][key] === "no") &&
                                "attribute-solid-bg"
                              }`}
                            >
                              <img
                                className="pdf-check-mark"
                                src="checkmark-outline-white.svg"
                                alt="checkmark.png"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        } else {
          return null;
        }
      });
    }
    return null;
  };

  const getWidth = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 350) {
      return 150;
    } else if (screenWidth <= 600) {
      return 250;
    } else if (screenWidth <= 1024) {
      return 500;
    } else {
      return 1000;
    }
  };

  const getVitalsWidth = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 600) {
      return 220;
    } else {
      return 150;
    }
  };

  const pushtoPDFReport = () => {
    const state = {
      lastVisitData: lastVisitData,
      completepatientData: completepatientData,
    };

    history.push({
      pathname: "/pdf-report",
      state: state,
    });
  };

  const jwt_token = Cookies.get("jwt_token");
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const parseJwtObj = parseJwt(jwt_token);
  const role = parseJwtObj.uRole;

  return (
    <div className="review-page-container">
      <button className="button download-button" onClick={pushtoPDFReport}>
        <img
          className="icon"
          src="./cloud-download-outline.svg"
          alt="download.svg"
        />
      </button>
      <div className="block-1">
        <div className="patient-details-block">
          <div className="profile-image-block">
            <img className="doctor-review-profile-image" alt="" />
            <h1 className="doctor-review-patient-name">
              {completepatientData.name}
            </h1>
            <div className="patient-age-gender-container">
              <p className="doctor-review-patient-age">26 Years</p>
              <p className="doctor-review-patient-gender">
                | {completepatientData.gender}
              </p>
            </div>
          </div>
          <div className="patient-info-block">
            <div className="each-field-patient-info">
              <p className="each-field-patient-info-title">Patient ID </p>
              <p className="each-field-patient-info-value">
                {completepatientData.uhid}
              </p>
            </div>
            <div className="each-field-patient-info">
              <p className="each-field-patient-info-title">Height</p>
              <p className="each-field-patient-info-value">156cm</p>
            </div>
            <div className="each-field-patient-info">
              <p className="each-field-patient-info-title">Weight</p>
              <p className="each-field-patient-info-value">56kg</p>
            </div>
            <div className="each-field-patient-info">
              <p className="each-field-patient-info-title">BMI</p>
              <p className="each-field-patient-info-value">24.56</p>
            </div>
          </div>
        </div>
        <div className="patient-vitals-block">
          <h1 className="vitals-title">Vitals Sign's</h1>
          <div className="vitals-blocks">
            <div className="vital-block heart-rate-block">
              <div className="vital-image-title-container">
                <div className="vital-image-container heart-rate-image">
                  <img
                    src="heart_rate.svg"
                    className="each-vital-image"
                    alt="heart_rate"
                  />
                </div>
                <h2 className="vital-name">Heart Rate</h2>
              </div>
              <div className="vitals-value-container">
                <p className="vital-value">{lastvisitVitals.heart_rate} BPM</p>
                <p className="vital-value-status">Normal</p>
              </div>
              <div className="vitals-graph">
                <ViewGraph
                  color="#B36161"
                  data={heartRate}
                  width={getVitalsWidth()}
                  height={90}
                />
              </div>
            </div>
            <div className="vital-block sbp-block">
              <div className="vital-image-title-container">
                <div className="vital-image-container sbp-image">
                  <img src="bp.svg" className="each-vital-image" alt="bp" />
                </div>
                <h2 className="vital-name">Systolic Bp</h2>
              </div>
              <div className="vitals-value-container ">
                <p className="vital-value">{lastvisitVitals.sbp} BPM</p>
                <p className="vital-value-status">Normal</p>
              </div>
              <div className="vitals-graph">
                <ViewGraph
                  color="#006699"
                  data={sbp}
                  width={getVitalsWidth()}
                  height={90}
                />
              </div>
            </div>
            <div className="vital-block dbp-block">
              <div className="vital-image-title-container">
                <div className="vital-image-container dbp-image">
                  <img src="dbp.svg" className="each-vital-image" alt="dbp" />
                </div>
                <h2 className="vital-name">Diastolic Bp</h2>
              </div>
              <div className="vitals-value-container">
                <p className="vital-value">{lastvisitVitals.dbp} BPM</p>
                <p className="vital-value-status">Normal</p>
              </div>
              <div className="vitals-graph">
                <ViewGraph
                  color="#E18C0C"
                  data={dbp}
                  width={getVitalsWidth()}
                  height={90}
                />
              </div>
            </div>
            <div className="vital-block spo2-block">
              <div className="vital-image-title-container">
                <div className="vital-image-container spo2-image">
                  <img src="spo2.svg" className="each-vital-image" alt="spo2" />
                </div>
                <h2 className="vital-name">Spo2</h2>
              </div>
              <div className="vitals-value-container">
                <p className="vital-value">{lastvisitVitals.spo2}</p>
                <p className="vital-value-status">Normal</p>
              </div>
              <div className="vitals-graph">
                <ViewGraph
                  color="#17B556"
                  data={spo2}
                  width={getVitalsWidth()}
                  height={90}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block-2">
        <div className="patient-aicvd-score-block">
          <h1 className="vitals-title">AICVD Risk Score</h1>
          {ReportCard("AICVD")}
        </div>
        <div className="patient-prediabetes-score-block">
          <h1 className="vitals-title">Prediabetes Risk Score</h1>

          {findValue(lastVisitData?.sections, "p_diabetes") === "yes" ? (
            <div id="card" className="aicvd-risk-block">
              <div className="d-flex flex-row justify-content-between align-items-center card-content-gap">
                <p className="risk-card-heading">Prediabetes</p>
                <img
                  className="risk-card-img"
                  src="./diabetes.png"
                  alt="prediabetes.png"
                />
              </div>
              <div className="d-flex align-items-end">
                <p className="diabetes-note-text">
                  <span className="diabetes-note-title">Note: </span>
                  Participants with a confirmed diagnosis of Diabetes shall not
                  have Prediabetes Risk.
                </p>
              </div>
            </div>
          ) : (
            ReportCard("prediabetes")
          )}
        </div>
      </div>
      <div className="block-3">
        <div className="patient-complete-details-block">
          <h1 className="vitals-title">Statistics</h1>
          <div className="graph-select">
            <select className="graph-select-tag" onChange={updategraphData}>
              <option value="" disabled>
                Select
              </option>
              {listofGraph?.map((data,index) => (
                <option className="graph-select-tag" value={data} key={index}>
                  {data
                    .replace(/[_-]/g, " ")
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </option>
              ))}
            </select>
          </div>
          <ViewGraph
            color="#00545E"
            data={datapoints}
            width={getWidth()}
            height={220}
          />
        </div>
      </div>
      <div className="block-4">
        <div className="patient-total-attributes">
          <div className="graph-select">
            <select
              className="graph-select-tag"
              onChange={updateVisitedValue}
              value={visitedValue}
            >
              <option value="" disabled>
                Select
              </option>
              {visitArray?.map((data) => (
                <option className="graph-select-tag" value={data} key={data}>
                  {data}
                </option>
              ))}
            </select>
            <div className="">{renderSections()}</div>
          </div>
        </div>
      </div>
      {role === "doctor" ? (
        <>
          <div className="block-5">
            <div className="patient-report-review">
              <h1 className="vitals-title">Report Review</h1>
              <div className="participant-info-container doctor-selected-box">
                <div className="info-line-container d-flex flex-column align-items-start">
                  <p className="custom-lab-label d-flex flex-column">
                    AI Based Laboratory Investigations Recommendations
                    <span className="sub-text">
                      (This is based on your AI Scores for AICVD and Prediabetes
                      Scanner)
                    </span>
                  </p>
                  <ul className="custom-patient-report-test-container">
                    {aicvdLabTest.map((each, index) => (
                      <li className="report-test-item" key={index}>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`aicvdLab${index}`}
                          defaultChecked={true}
                          value={each}
                          onChange={aicvdLabTestsTrigger}
                        />
                        <span className="c-checkbox">
                          <img
                            className="icon"
                            src="checkmark-outline-white.svg"
                            alt="clipart.png"
                          />
                        </span>
                        <label
                          className="checkbox-label"
                          htmlFor={`aicvdLab${index}`}
                        >
                          {each}
                        </label>
                      </li>
                    ))}
                    {prediabetesLabTest.map((each, index) =>
                      each !== "" ? (
                        <li className="report-test-item" key={index}>
                          <input
                            type="checkbox"
                            className="checkbox"
                            id={`prediabetesLab${index}`}
                            value={each}
                            defaultChecked={true}
                            onChange={prediabetesLabTestTrigger}
                          />
                          <span className="c-checkbox">
                            <img
                              className="icon"
                              src="checkmark-outline-white.svg"
                              alt="clipart.png"
                            />
                          </span>
                          <label
                            className="checkbox-label"
                            htmlFor={`prediabetesLab${index}`}
                          >
                            {each}
                          </label>
                        </li>
                      ) : null
                    )}
                  </ul>
                  <div className="input-container user-create-input mt-auto">
                    <div className="input-with-logo">
                      <input
                        type="text"
                        className="login-input dark-placeholder"
                        placeholder="Add Further Tests (Physician's Discretion)"
                        autoComplete="off"
                        id="aiLabTestInput"
                      />
                      <button
                        className="test-add-button"
                        onClick={updateNewLabTest}
                      >
                        <img
                          className="icon"
                          src="./add-outline.svg"
                          alt="apollo.png"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="info-line-container d-flex flex-column align-items-start">
                  <div className="info-line-container d-flex flex-column align-items-start">
                    <p className="custom-lab-label">
                      AICVD Diagnostics And Imaging Recommended
                    </p>
                    <ul className="custom-patient-report-test-container">
                      {aicvdDiaImg.map((each, index) => (
                        <li className="report-test-item" key={index}>
                          <input
                            type="checkbox"
                            className="checkbox"
                            id={`aicvdDiaIma${index}`}
                            value={each}
                            defaultChecked={true}
                            onChange={aicvdDiaImgTrigger}
                          />
                          <span className="c-checkbox">
                            <img
                              className="icon"
                              src="checkmark-outline-white.svg"
                              alt="clipart.png"
                            />
                          </span>
                          <label
                            className="checkbox-label"
                            htmlFor={`aicvdDiaIma${index}`}
                          >
                            {each}
                          </label>
                        </li>
                      ))}
                    </ul>
                    <div className="input-container user-create-input mt-auto">
                      <div className="input-with-logo">
                        <input
                          type="text"
                          className="login-input dark-placeholder"
                          placeholder="Add Further Tests (Physician's Discretion)"
                          autoComplete="off"
                          id="diaImgTestInput"
                        />
                        <button
                          className="test-add-button"
                          onClick={updateNewDiaImgTest}
                        >
                          <img
                            className="icon"
                            src="./add-outline.svg"
                            alt="apollo.png"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="info-line-container d-flex flex-column align-items-start">
                  <textarea
                    id="doctorReview"
                    className="text-area"
                    value={doctorReview}
                    placeholder="Participant reivew here..."
                    onChange={updateDoctorReview}
                  ></textarea>
                  <input
                    type="file"
                    className="file-input mb-25"
                    disabled
                    title="Currently not working"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="block-6">
            <button
              className="button next-button"
              type="button"
              onClick={addReviewPopUp}
            >
              Submit
            </button>
          </div>
        </>
      ) : null}
      <div id="reviewPopup" className="pop-up-container d-none">
        {confirmStatus ? (
          <div className="delete-container">
            <p className="delete-text">
              Please <span className="confirm-text">CONFIRM</span> to save the
              Review.
            </p>
            <div className="delete-button-container">
              <button
                className="button cancel-button"
                type="button"
                onClick={cancelButtonClicked}
              >
                Cancel
              </button>
              <button
                className="button confirm-button"
                type="button"
                onClick={confirmButtonClicked}
              >
                Confirm
              </button>
            </div>
          </div>
        ) : (
          <div className="pop-up-box">
            {reviewLoader === true ? (
              <Loader type="Oval" color="#FF883E" height={50} width={50} />
            ) : (
              <div className="success-container">
                <div className="tickmark-container">
                  <img
                    className="tickmark-icon"
                    src="./checkmark-outline.svg"
                    alt="tickmark.png"
                  />
                </div>
                <p className="success-text">Review has Noted</p>
                <button
                  className="button done-button"
                  onClick={removeReviewPopUp}
                >
                  Done
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientReviewPage;
