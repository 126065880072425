import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";

import ProtectRouter from "./components/ProtectRouter";
import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Dashboard";
import UsersList from "./components/UsersList";
import CreateNewUser from "./components/CreateNewUser";
import CampsList from "./components/CampsList";
import NewPatientForm from "./components/NewPatientForm";
import CommonReportPage from "./components/CommonReportPage";
import CopdForm from "./components/CopdForm";
import LiverForm from "./components/LiverForm";
import AntibioticForm from "./components/AntibioticForm";
import Cookies from "js-cookie";
import CreateCampForm from "./components/CreateCampForm";
import PatientsList from "./components/PatientsList";
import AicvdReport from "./components/AicvdReport";
import ReviewPage from "./components/ReviewPage";
import Navigation from "./components/Navigation";
import PatientReport from "./components/PatientReport";
import FormBuilder from "./components/FormBuilder";
import FormsList from "./components/FormsList";
import Forms from "./components/Forms";
import TotalPatientList from "./components/TotalPatientList";
import PatientReviewPage from "./components/ExecutivePatientView";

class App extends React.Component {
  state = {
    newPatientObject: {
      uhid: "",
      name: "",
      phone: "",
      gender: "",
      age: "",
      dob: "",
      pincode: "",
      location: "",
      email: "",
      p_diabetes: "",
      p_heart_disease: "",
      p_hypertension: "",
      p_dyslipidemia: "",
      p_anemia: "",
      p_cancer: "",
      p_covid_infection: "",
      date_of_rtpcr: "",
      covid_vaccinated: "",
      covid_vaccine_name: "",
      no_of_dose: "",
      date_of_last_dose: "",
      f_diabetes: "",
      f_heart_disease: "",
      f_hypertension: "",
      f_dyslipidemia: "",
      f_anemia: "",
      f_cancer: "",
      diet: "",
      alcohol: "",
      smoking: "",
      tobacco: "",
      physical_activity: "",
      psychological_stress: "",
      height: "",
      weight: "",
      bmi: "",
      heart_rate: "",
      s_bp: "",
      d_bp: "",
      spo2: "",
      temperature: "",
      rbs: "",
      chest_pain: "",
      pulse_rhythm: "",
      rate_respiration: "",
      past_medical_history: "",
      family_history: "",
      waist: "",
      change_in_weight: "",
    },
    aicvdReport: {},
    prediabetesReport: {},
  };

  clearPrvPatinetData = () => {
    this.setState({
      newPatientObject: {
        uhid: "",
        name: "",
        phone: "",
        gender: "",
        age: "",
        dob: "",
        pincode: "",
        location: "",
        email: "",
        p_diabetes: "",
        p_heart_disease: "",
        p_hypertension: "",
        p_dyslipidemia: "",
        p_anemia: "",
        p_cancer: "",
        p_covid_infection: "",
        date_of_rtpcr: "",
        covid_vaccinated: "",
        covid_vaccine_name: "",
        no_of_dose: "",
        date_of_last_dose: "",
        f_diabetes: "",
        f_heart_disease: "",
        f_hypertension: "",
        f_dyslipidemia: "",
        f_anemia: "",
        f_cancer: "",
        diet: "",
        alcohol: "",
        smoking: "",
        tobacco: "",
        physical_activity: "",
        psychological_stress: "",
        height: "",
        weight: "",
        bmi: "",
        heart_rate: "",
        s_bp: "",
        d_bp: "",
        spo2: "",
        temperature: "",
        rbs: "",
        chest_pain: "",
        pulse_rhythm: "",
        rate_respiration: "",
        past_medical_history: "",
        family_history: "",
        waist: "",
        change_in_weight: "",
      },
      aicvdReport: {},
      prediabetesReport: {},
    });
  };

  updateNewPatientData = (props) => {
    this.setState({
      newPatientObject: {
        camp_id: props.camp_id,
        uhid: props.uhid,
        name: props.name,
        phone: props.phone,
        gender: props.gender,
        age: props.age,
        dob: props.dob,
        pincode: props.pincode,
        location: props.location,
        email: props.email,
        p_diabetes: props.p_diabetes,
        p_heart_disease: props.p_heart_disease,
        p_hypertension: props.p_hypertension,
        p_dyslipidemia: props.p_dyslipidemia,
        p_anemia: props.p_anemia,
        p_cancer: props.p_cancer,
        p_covid_infection: props.p_covid_infection,
        date_of_rtpcr: props.date_of_rtpcr,
        covid_vaccinated: props.covid_vaccinated,
        covid_vaccine_name: props.covid_vaccine_name,
        no_of_dose: props.no_of_dose,
        date_of_last_dose: props.date_of_last_dose,
        f_diabetes: props.f_diabetes,
        f_heart_disease: props.f_heart_disease,
        f_hypertension: props.f_hypertension,
        f_dyslipidemia: props.f_dyslipidemia,
        f_anemia: props.f_anemia,
        f_cancer: props.f_cancer,
        diet: props.diet,
        alcohol: props.alcohol,
        smoking: props.smoking,
        tobacco: props.tobacco,
        physical_activity: props.physical_activity,
        psychological_stress: props.psychological_stress,
        height: props.height,
        weight: props.weight,
        bmi: props.bmi,
        heart_rate: props.heart_rate,
        s_bp: props.s_bp,
        d_bp: props.d_bp,
        spo2: props.spo2,
        temperature: props.temperature,
        rbs: props.rbs,
        chest_pain: props.chest_pain,
        pulse_rhythm: props.pulse_rhythm,
        rate_respiration: props.rate_respiration,
        past_medical_history: props.past_medical_history,
        family_history: props.family_history,
        waist: props.waist,
        change_in_weight: props.change_in_weight,
      },
      aicvdReport: props.aicvdData,
      prediabetesReport: props.prediabetesData,
    });
  };

  updateGlobalCampId = (campId) => {
    this.setState({ globalCampId: campId });
  };

  aicvdReportUpdate = (props) => {
    this.setState({ aicvdReport: props });
  };

  predibetesReportUpdate = (props) => {
    this.setState({ prediabetesReport: props.prediabetes });
  };

  renderDefaultComponent = () => {
    const token = Cookies.get("jwt_token");

    if (token !== undefined) {
      const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
          return null;
        }
      };
      const jwt_token = Cookies.get("jwt_token");
      const parseJwtObj = parseJwt(jwt_token);
      if (parseJwtObj.uRole === "admin") {
        return <Redirect to="/dashboard" />;
      } else {
        return <Redirect to="/camps-list" />;
      }
    }
    return <Redirect to="/login" />;
  };

  logout = () => {
    Cookies.remove("jwt_token");
  };

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={() => <LoginPage />} />
          <Route
            exact
            path="/"
            render={() => {
              return this.renderDefaultComponent();
            }}
          />
          <>
            <div className="page-container">
              <Navigation cl />
              <div className="content-container">
                <ProtectRouter
                  exact
                  path="/dashboard"
                  component={() => (
                    <Dashboard data={this.state} updateRole={this.updateRole} />
                  )}
                />
                <ProtectRouter
                  exact
                  path="/user-list"
                  component={() => <UsersList data={this.state} />}
                />
                <ProtectRouter
                  exact
                  path="/create-user"
                  component={() => <CreateNewUser data={this.state} />}
                />
                <ProtectRouter
                  exact
                  path="/camps-list"
                  component={() => (
                    <CampsList
                      data={this.state}
                      updateGlobalCampId={this.updateGlobalCampId}
                    />
                  )}
                />
                <ProtectRouter
                  exact
                  path="/create-camp"
                  component={() => <CreateCampForm data={this.state} />}
                />
                <ProtectRouter
                  exact
                  path="/participant-list"
                  component={() => <PatientsList data={this.state} />}
                />

                <ProtectRouter
                  exact
                  path="/new-participant"
                  component={() => (
                    <NewPatientForm
                      data={this.state}
                      updateNewPatientData={this.updateNewPatientData}
                    />
                  )}
                />
                <ProtectRouter
                  exact
                  path="/copd-form"
                  component={() => <CopdForm data={this.state} />}
                />
                <ProtectRouter
                  exact
                  path="/liver-form"
                  component={() => <LiverForm data={this.state} />}
                />
                <ProtectRouter
                  exact
                  path="/antibiotic-form"
                  component={() => <AntibioticForm data={this.state} />}
                />
                <ProtectRouter
                  exact
                  path="/participant-review"
                  component={() => <ReviewPage data={this.state} />}
                />
                <ProtectRouter
                  exact
                  path="/aicvd-report"
                  component={() => <AicvdReport data={this.state} />}
                />
                <ProtectRouter
                  exact
                  path="/pdf-report"
                  component={() => <PatientReport data={this.state} />}
                />
                <ProtectRouter
                  exact
                  path="/report"
                  component={() => (
                    <CommonReportPage
                      data={this.state}
                      clearPrvPatinetData={this.clearPrvPatinetData}
                    />
                  )}
                />
                <ProtectRouter
                  exact
                  path="/create-form"
                  component={() => <FormBuilder />}
                />
                <ProtectRouter
                  exact
                  path="/form-list"
                  component={() => <FormsList />}
                />
                <ProtectRouter
                  exact
                  path="/form-fields"
                  component={() => (
                    <Forms updateNewPatientData={this.updateNewPatientData} />
                  )}
                />
                <ProtectRouter
                  exact
                  path="/total-patient-list"
                  component={() => <TotalPatientList data={this.state} />}
                />
                <ProtectRouter
                  exact
                  path="/patient-review-page"
                  component={() => <PatientReviewPage />}
                />
              </div>
            </div>
          </>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
