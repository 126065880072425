// YesNoOption.js
import React, { useState } from "react";
import "./index.css";

const YesNoOption = ({ options }) => {
  const [buttonValue, setButtonValue] = useState(null);
  const handleClick = (value) => {
    setButtonValue(value);
  };

  return (
    <div className="yes-no-option">
      <div className="button-group">
        {options.map((option) => (
          <button
            key={option.id}
            className={`button ${
              buttonValue === option.label ? "selected" : ""
            }`}
            onClick={() => handleClick(option.label)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default YesNoOption;
