import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Cookies from "js-cookie";

const ExportCSV = ({ campId }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const getPatientListAndConvertIntoExcel = async (campId) => {
    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/complect-patient-list?campId=${campId}`;
    const jwt_token = Cookies.get("jwt_token");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      const excelData = responseObj.patients_list.map((each) => ({
        Id: each.id,
        "Camp Id": each.camp_id,
        Email: each.email,
        Mobile: each.mobile,
        Uhid: each.uhid,
        Name: each.name,
        Gender: each.gender,
        Age: each.age,
        "Date Of Birth": each.dob,
        Pincode: each.pincode,
        Location: each.location,
        "Participant Diabetes": each.p_diabetes,
        "Participant Hypertension": each.p_hypertension,
        "Participant Heart disease": each.p_heart_disease,
        "Participant Dyslipidemia": each.p_dyslipidemia,
        "Participant Anemia": each.p_anemia,
        "Participant Cancer": each.p_cancer,
        "Family Diabetes": each.f_diabetes,
        "Family Hypertension": each.f_hypertension,
        "Family Heart disease": each.f_heart_disease,
        "Family Dyslipidemia": each.f_dyslipidemia,
        "Family Anemia": each.f_anemia,
        "Family Cancer": each.f_cancer,
        Diet: each.diet,
        Alcohol: each.alcohol,
        Tobacco: each.tobacco,
        Smoking: each.smoking,
        "Physical Activity": each.physical_activity,
        "Psychological Stress": each.psychological_stress,
        Height: each.height,
        Weight: each.weight,
        BMI: each.bmi,
        "Heart Rate": each.heart_rate,
        "Systolic BP": each.systolic_bp,
        "Dystolic BP": each.diastolic_bp,
        Temperature: each.temperature,
        "Chest Pain": each.chest_pain,
        "Respiration Rate": each.rate_of_respiration,
        Albumin: each.albumin,
        "Alkphos Alkaline Phosphotase": each.alkphos_alkaline_phosphotase,
        SGPT: each.sgpt,
        SGOT: each.sgot,
        "Total Bilirubin": each.total_bilirubin,
        Cholesterol: each.cholesterol,
        "HDL Cholesterol": each.hdl_cholesterol,
        "Platelet Count": each.platelet_count,
        "Total Protiens": each.total_protiens,
        INR: each.inr,
        "Created At": each.created_at,
        Breathlessness: each.breathlessness,
        Wheezes: each.wheezes,
        "Pulse Rate": each.pulserate,
        "Aicvd Score": each.aicvdscore,
        "Aicvd Category": each.aicvdcat,
        "Aicvd Optimal": each.aicvdoptimal,
        "Copd Score": each.copdscore,
        "Liver Score": each.liverscore,
        RBS: each.RBS,
        "Copd Category": each.copdcat,
        "Prediabetes Score": each.diascore,
        "Prediabetes Risk": each.diarisk,
        "COVID Infected": each.covid_infected,
        "Date Of RTPCR": each.date_of_rtpcr,
        "Covid Vaccinated": each.covid_vaccinated,
        "Covid Vaccine Name": each.covid_vaccine_name,
        "No Of Dose": each.no_of_dose,
        "Last Date Of Dose": each.date_of_last_dose,
        SPO2: each.spo2,
        Waist: each.waist,
        "Change In Body Weight": each.change_in_weight,
        "Pulse Rhythm": each.pulse_rhythm,
        "Doctor Review": each.review,
        "Doctor Name": each.doctor_name,
      }));

      const fileName = `Camp-${campId}-participant-data`;

      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };
  return (
    <button
      onClick={(e) => getPatientListAndConvertIntoExcel(campId)}
      type="button"
      className="button excel-button"
    >
      <img className="icon" src="./excel-outline.svg" alt="excel.svg" />
    </button>
  );
};

export default ExportCSV;
