import React, { useEffect, useState, useCallback, useMemo } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
import { IoIosArrowDown } from "react-icons/io";
import "./index.css";

const TotalPatientList = () => {
  const [completePatientList, setCompletePatientList] = useState([]);
  const [startsFrom, setStartsFrom] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [patientCompleteStatus, setPatientCompleteStatus] = useState();
  const history = useHistory();
  const [searchField, setsearchFeild] = useState("all");
  const [searhquery, setquery] = useState("");
  const [initialFetchComplete, setInitialFetchComplete] = useState(false); // New state variable


  const fetchPatientList = useCallback(async () => {
    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/complete_patient_list_db?starts_from=${startsFrom}`;
    const jwt_token = Cookies.get("jwt_token");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const responseObj = await response.json();

      let totalPatientCount = startsFrom;

      if (responseObj.status === "success") {
        let patientcount = totalPatientCount + responseObj.patients_list.length;
        setCompletePatientList((prevList) => [
          ...prevList,
          ...responseObj.patients_list,
        ]);
        setStartsFrom(patientcount);
        setLoaderStatus(false);
        setPatientCompleteStatus(responseObj.patient_list_complete_status);
        setInitialFetchComplete(true); // Mark initial fetch as complete
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [startsFrom]);

  useEffect(() => {
    fetchPatientList();
  }, [startsFrom, fetchPatientList]);

  const filterPatientList = useCallback(async () => {
    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/search-api-on-patient-data?query=${searhquery}&searchField=${searchField}`;
    const jwt_token = Cookies.get("jwt_token");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const responseObj = await response.json();

      if (responseObj.status === "success") {
        setCompletePatientList(responseObj.patients_list);
        setLoaderStatus(false);
        setPatientCompleteStatus(responseObj.patient_list_complete_status);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [searhquery, searchField]);

  const debouncedFilterPatientList = useMemo(
    () => debounce(filterPatientList, 100),
    [filterPatientList]
  );

  useEffect(() => {
    if (initialFetchComplete) {
      let isMounted = true;

      const wrappedDebouncedFilterPatientList = () => {
        if (isMounted) {
          debouncedFilterPatientList();
        }
      };

      wrappedDebouncedFilterPatientList();

      return () => {
        isMounted = false;
        debouncedFilterPatientList.cancel();
      };
    }
  }, [searchField, searhquery, debouncedFilterPatientList, initialFetchComplete]);




  const updateSearchfilter = (event) => {
    const field = event.target.value;
    setsearchFeild(field);
  };

  const searchquery = (event) => {
    const query = event.target.value;
    setquery(query);
  };

  const pushToPatientReport = (event) => {
    const id = event.target.value;
    Cookies.set("patient_id", id, { expires: 1, path: "/" });

    const paticularPatientData = completePatientList.filter(
      (each) => each.id === id
    );

    localStorage.setItem(
      "patientReport",
      JSON.stringify(paticularPatientData[0])
    );

    history.push("/patient-review-page");
  };

  const editPatientDetails = (patient_id) => {
    const editPatientDetails = completePatientList.filter(
      (each) => each.id === patient_id
    );
    history.push({
      pathname: "/form-fields",
      state: {
        editPatientDetails: editPatientDetails[0],
        formId: editPatientDetails[0].formId,
        patient_id: patient_id,
      },
    });
  };

  const renderPatientCard = (data, index) => {
    return (
      <div id="card" className="details-card-container" key={index}>
        <div className="d-flex flex-row justify-content-between align-items-center card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Patient ID</p>
            <p className="content">{data.uhid}</p>
          </div>
          <div className="title-with-content-container">
            <p className="title">Age</p>
            <p className="content ">{data.age}</p>
          </div>
          <div className="title-with-content-container">
            <p className="title">Gender</p>
            <p className="content ">{data.gender}</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Name</p>
            <p className="content overflow-x-auto">{data.name}</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Doctor Review</p>
            <p className="content overflow-x-auto">
              {data.doctorReview === null ||
              data.doctorReview === "Null" ||
              data.doctorReview === "NULL"
                ? "Doctor Review is Pending"
                : data.doctorReview}
            </p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-end">
          <button
            className="button edit-button"
            value={data.id}
            onClick={() => editPatientDetails(data.id)}
          >
            Edit
          </button>
          <button
            className="button camp-view-button"
            value={data.id}
            onClick={pushToPatientReport}
          >
            View
          </button>
        </div>
      </div>
    );
  };

  const jwt_token = Cookies.get("jwt_token");
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const parseJwtObj = parseJwt(jwt_token);
  const userName = parseJwtObj.name;
  const role = parseJwtObj.uRole;

  return (
    <div className="participant-list-conainer">
      <div className="sub-heading-with-button-container">
        <div className="sub-heading-with-logo">
          <h1 className="sub-heading">Patients List</h1>
          <img
            className="sub-heading-icon"
            src="./layers-outline.svg"
            alt="list.svg"
          />
        </div>
        <div className="userinfo-app-title-conainer">
          <div className="large-userinfo-container">
            <div className="large-userinfo">
              <p className="larg-user-name">{userName}</p>
              <p className="large-user-role">{role}</p>
            </div>
            <div className="user-image-cirlce">
              <img
                className="icon"
                src="person-outline.svg"
                alt="person-outline"
              />
            </div>
          </div>
          <div className="camp-heading">
            <p className="apollo-hospitals-text">Apollo Hospitals</p>
            <h1 className="heading text-bold">Health Camps</h1>
            <div className="heading-style-element-container">
              <div className="style-line"></div>
              <div className="style-dot"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="input-container max-width-400 margin-none search-with-select">
        <div className="input-with-logo">
          <input
            type="text"
            className="login-input"
            placeholder="Search by Name"
            name="search"
            onChange={searchquery}
          />
          <img className="icon" src="./search.svg" alt="apollo.png" />
        </div>
        <div className="search-select-container">
          <select className="select-bar" onChange={updateSearchfilter}>
            <option value="all">All</option>
            <option value="name">Name</option>
            <option value="uhid">ID</option>
            <option value="mob">Phone Number</option>
            <option value="age">Age</option>
            <option value="gender">Gender</option>
          </select>
          <IoIosArrowDown className="search-dropdown-icon" />
        </div>
      </div>
      <div className="details-cards-container">
        {loaderStatus ? (
          <div className="main-loader-container">
            <div className="loader-spinner"></div>
            <p>Loading...!</p>
          </div>
        ) : (
          <>
            {completePatientList.length > 0 ? (
              completePatientList.map((each, index) =>
                renderPatientCard(each, index)
              )
            ) : (
              <div className="d-flex flex-row justify-content-center">
                <p className="not-found-text">Oops..! Camps Not Found</p>
                <img
                  className="sub-heading-icon color-gray"
                  src="./sad-outline.svg"
                  alt="sad.svg"
                />
              </div>
            )}
          </>
        )}
        {patientCompleteStatus ? (
          <div className="show-more-button-container">
            <button className="button show-more" onClick={fetchPatientList}>
              View More
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TotalPatientList;
