import React from "react";
import { withRouter } from "react-router";
import ReactToPrint from "react-to-print";

class ComponentToPrint extends React.Component {
  renderCurrentDate = () => {
    const date = new Date();
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderSection = (title, data, color) => {
    const sectionClass = title.replace(/\s+/g, "-").toLowerCase(); // create a class name from the title
    return (
      <div
        className={`section-info-container ${sectionClass}-info-container`}
        style={{ borderColor: color }}
        key={title}
      >
        <p
          className={`pdf-box-heading ${sectionClass}-bg`}
          style={{ backgroundColor: color }}
        >
          {title.toUpperCase()}
        </p>
        <div className="three-field-container">
          {Object.entries(data).map(([key, value]) => (
            <div className="field-container" key={key}>
              <p className="field-text">{key}</p>
              <p
                className={`date-container ${sectionClass}-bg`}
                style={{ borderColor: color }}
              >
                {value}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  findValue = (data, key) => {
    if (data) {
      if (data.hasOwnProperty(key)) {
        return data[key];
      }
      for (let k in data) {
        if (typeof data[k] === "object" && data[k] !== null) {
          const found = this.findValue(data[k], key);
          if (found !== undefined) {
            return found;
          }
        }
      }
      return undefined;
    }
  };

  render() {
    const patientData = this.props.data;

    const patientInfo = this.props.patientInfo;
    console.log(patientInfo, "patientInfo in report pdf");

    const medicalProtocol = JSON.parse(patientData.aicvdlabreport);
    const prediabteLabReport = JSON.parse(patientData.prediabeteslabreport);

    console.log(prediabteLabReport);

    const allLabInvestigation = medicalProtocol.LabInvestigationRecommended;
    let filterLabInvestigation = "";
    for (let key in allLabInvestigation) {
      if (allLabInvestigation[key] === "Yes") {
        filterLabInvestigation += key + ", ";
      }
    }

    console.log(filterLabInvestigation, "filterLabInvestigation");

    const allDiagnosticsAndImagingRecommended =
      medicalProtocol.DiagnosticsAndImagingRecommended;
    let filterDiagnosticsAndImagingRecommended = "";
    for (let key in allDiagnosticsAndImagingRecommended) {
      if (allDiagnosticsAndImagingRecommended[key] === "Yes") {
        filterDiagnosticsAndImagingRecommended += key + ", ";
      }
    }

    console.log(
      filterDiagnosticsAndImagingRecommended,
      "filterDiagnosticsAndImagingRecommended"
    );

    let aicvdBgColor = "";
    if (patientData.aicvdcat.toLowerCase() === "high risk")
      aicvdBgColor = "high-risk";
    else if (
      patientData.aicvdcat.toLowerCase() === "medium risk" ||
      patientData.aicvdcat.toLowerCase() === "mid risk" ||
      patientData.aicvdcat.toLowerCase() === "moderate risk"
    )
      aicvdBgColor = "medium-risk";
    else aicvdBgColor = "low-risk";

    let prediabetesBgColor = "";
    if (this.findValue(patientData?.sections, "p_diabetes") !== "yes") {
      if (patientData.diarisk.toLowerCase() === "high risk")
        prediabetesBgColor = "high-risk";
      else if (
        patientData.diarisk.toLowerCase() === "medium risk" ||
        patientData.diarisk.toLowerCase() === "mid risk" ||
        patientData.diarisk.toLowerCase() === "moderate risk"
      )
        prediabetesBgColor = "medium-risk";
      else prediabetesBgColor = "low-risk";
    }

    const riskProfile = parseFloat(
      (patientData.aicvdscore / patientData.aicvdoptimal).toFixed(2)
    );

    return (
      <div className="pdf-master-container">
        <div className="pdf-container">
          <div className="page">
            <div className="page-header">
              <h1 className="header-heading">Participant Report</h1>
              <img
                className="header-log"
                src="./apollo-white-logo.png"
                alt="apollo-logo"
              />
            </div>
            <div className="pdf-content-container">
              <ul className="pdf-user-info-container">
                <li>
                  <p className="pdf-user-info-heading">UHID</p>:
                  <p className="pdf-user-info-value">{patientInfo.uhid}</p>
                </li>
                <li>
                  <p className="pdf-user-info-heading">Name</p>:
                  <p className="pdf-user-info-value">{patientInfo.name}</p>
                </li>
                <li>
                  <p className="pdf-user-info-heading">Age</p>:
                  <p className="pdf-user-info-value">{patientInfo.age}</p>
                </li>
                <li>
                  <p className="pdf-user-info-heading">Gender</p>:
                  <p className="pdf-user-info-value">{patientInfo.gender}</p>
                </li>
                <li>
                  <p className="pdf-user-info-heading">Location</p>:
                  <p className="pdf-user-info-value">{patientInfo.location}</p>
                </li>
                <li>
                  <p className="pdf-user-info-heading">Mobile</p>:
                  <p className="pdf-user-info-value">{patientInfo.mobile}</p>
                </li>
              </ul>
              <p className="line"></p>
              <h1 className="pdf-sub-heading">
                APOLLO HOSPITALS THANK YOU FOR YOUR CONSENT
              </h1>
              <p className="pdf-page-text">
                Based on current parameters, personal &#38; family history, AI
                enabled tools derive health risk scores to alert participant on
                his/her likely predisposition to a disease to aid early
                detection &#38; early medical intervention to mitigate the risk.
              </p>
              <h1 className="pdf-sub-heading">ABOUT CAMP :</h1>
              <div className="pdf-camp-detials-container">
                {/* <div className="pdf-each-camp-details-container width-48">
                  <p>Camp Name</p>
                  <p className="pdf-camp-details-var-container">
                    {campData.campTag}
                  </p>
                </div> */}
                {/* <div className="pdf-each-camp-details-container width-20">
                  <p>Date</p>
                  <p className="pdf-camp-details-var-container">
                    {campData.createdDate}
                  </p>
                </div> */}
                <div className="pdf-each-camp-details-container width-30">
                  <p>Doctor</p>
                  <p className="pdf-camp-details-var-container">
                    {patientInfo.doctor_name}
                  </p>
                </div>
              </div>

              <div className="history-info-main-container">
                {Object.keys(patientData.sections).map((sectionName) => {
                  const randomColor = this.getRandomColor();
                  return (
                    <div
                      key={sectionName}
                      className="lifestyle-info-container"
                      style={{ borderColor: randomColor, marginBottom: "20px" }}
                    >
                      <h2
                        className="pdf-box-heading life-style-bg"
                        style={{ backgroundColor: randomColor }}
                      >
                        {sectionName}
                      </h2>
                      <div className="three-field-container">
                        {Object.keys(patientData.sections[sectionName]).map(
                          (key) => (
                            <div className="col" key={key}>
                              <div className="field-container width-100">
                                <p className="field-text">
                                  {key.replace(/_/g, " ")}
                                </p>
                                <p className="date-container lifestyle-bg">
                                  {patientData.sections[sectionName][key]}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <footer className="footer-container">
              <div className="d-flex flex-row justify-space-between plr-25">
                <p className="footer-text">Apollo Health Camps</p>
                <div className="d-flex flex-column alingn-item-end">
                  <p className="footer-text mb-4">
                    This report is accessed by{" "}
                    <span className="text-capital">
                      {patientInfo.doctor_name}
                    </span>
                  </p>
                  <p className="footer-text">{this.renderCurrentDate()}</p>
                </div>
              </div>
              <div className="style-line-container">
                <div className="line-1"></div>
                <div className="line-2"></div>
              </div>
            </footer>
          </div>
          <div className="page">
            <div className="page-header">
              <h1 className="header-heading">Participant Report</h1>
              <img
                className="header-log"
                src="apollo-white-logo.png"
                alt="apollo-logo"
              />
            </div>
            <div className="pdf-content-container">
              <div className="second-page-container">
                <p className="pdf-box-heading aicvd-bg width-60">
                  AI CALCULATED RISK SCORE BASED ON YOUR INPUTS
                </p>
                <div className="three-field-container">
                  <div className="col">
                    <div className="field-container width-100">
                      <p className="field-text">Report Name</p>
                      <p className="date-container">Risk of Heart Disease</p>
                    </div>
                    <div className="field-container width-100">
                      <p className="field-text">Risk Status</p>
                      <p className={`date-container ${aicvdBgColor}`}>
                        {patientData.aicvdcat}
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="field-container width-100">
                      <p className="field-text">Risk Score</p>
                      <p className="date-container">{patientData.aicvdscore}</p>
                    </div>
                    <div className="field-container width-100">
                      <p className="field-text">Acceptable Score</p>
                      <p className="date-container">
                        {patientData.aicvdoptimal}
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="field-container width-100">
                      <p className="field-text">Risk Profile</p>
                      <p className="date-container">{riskProfile}x</p>
                    </div>
                  </div>
                  {patientData.aicvdcat === "Minimal Risk" ? (
                    <p className="risk-text">
                      Low Risk - Your likelihood to Cardiovascular disease is
                      within acceptable limit compared to other individuals of
                      your age &#38; gender in next 10 years
                    </p>
                  ) : (
                    <p className="risk-text">{`Your likelihood to Cardiovascular disease is ${
                      riskProfile === 1.0 ? "with in acceptable range" : ""
                    } ${riskProfile < 1.0 ? "lower" : ""} ${
                      riskProfile > 1.0 ? "higher" : ""
                    } ${
                      patientData.aicvdcat !== "Low Risk"
                        ? `(${riskProfile}x)`
                        : ""
                    } to other people of your age & gender in next 10 years`}</p>
                  )}
                </div>
                <p className="aicvd-note-text">
                  Note : The risk category is determined through the ratio
                  between patient score and acceptable score at multiple decimal
                  points. The output is shown in whole numbers.
                </p>
              </div>
              {this.findValue(patientData?.sections, "p_diabetes") !== "yes" ? (
                <div className="second-page-container diabetes-container">
                  <div className="d-flex flex-row justify-space-between">
                    <div className="d-flex flex-column width-30">
                      <p className="each-container-text text-white ">
                        Report Name
                      </p>
                      <p className="diabetes-var-container">Risk of Diabetes</p>
                    </div>
                    <div className="d-flex flex-column width-30">
                      <p className="each-container-text text-white ">
                        Risk Status
                      </p>
                      <p
                        className={`diabetes-var-container ${prediabetesBgColor}`}
                      >
                        {patientData.diarisk}
                      </p>
                    </div>
                    <div className="d-flex flex-column width-30">
                      <p className="each-container-text text-white ">
                        Risk Score
                      </p>
                      <p className="diabetes-var-container">
                        {patientData.diascore}
                      </p>
                    </div>
                  </div>
                  <p className="diabetes-text">
                    Note : The risk status is computed with the Machine Learning
                    Algorithm and categorised based on the individual's chances
                    of Prediabetes/Diabetes. It also provides a score based on
                    the probability of the risk status.
                  </p>
                </div>
              ) : (
                <p className="diabetes-note">
                  --- Note: Participants with a confirmed diagnosis of Diabetes
                  shall not have Prediabetes Risk. ---
                </p>
              )}
              <p className="certificate-text">
                The Clinical AI Models and APIs used at Apollo Hospitals are
                certified by ISO 13485 : 2016 vide certificate no. MD 763515
              </p>
              <div className="second-page-container aicvd-recommendations-border">
                <p className="pdf-box-heading aicvd-recommendations width-90">
                  HEART DISEASE PREVENTION - AI RECOMMENDED PROTOCOL (FOR DR
                  REVIEW &#38; ADVISE)
                </p>
                <ul className="aicvd-recommendation-list-container">
                  <li className="aicvd-recommendation-list-item">
                    <p className="list-item-heading">Lab Investigation</p>
                    <p className="list-item-value">
                      {filterLabInvestigation.trim().replace(/,+$/, "")}
                    </p>
                  </li>
                  <li className="aicvd-recommendation-list-item">
                    <p className="list-item-heading">Diagnostics and Imaging</p>
                    <p className="list-item-value">
                      {filterDiagnosticsAndImagingRecommended
                        .trim()
                        .replace(/,+$/, "")}
                    </p>
                  </li>
                  <li className="aicvd-recommendation-list-item">
                    <p className="list-item-heading">Referral</p>
                    <p className="list-item-value">{`${medicalProtocol.Referral.Department} (${medicalProtocol.Referral.Urgency})`}</p>
                  </li>
                  <li className="aicvd-recommendation-list-item">
                    <p className="list-item-heading">Advice</p>
                    <p className="list-item-value">
                      {medicalProtocol.Management.GeneralAdvice}
                    </p>
                  </li>
                  <li className="aicvd-recommendation-list-item">
                    <p className="list-item-heading">Repeat Visit</p>
                    <p className="list-item-value">
                      {medicalProtocol.Management.RepeatVisit.Comments}
                    </p>
                  </li>
                  <li className="aicvd-recommendation-list-item border-none">
                    <p className="list-item-heading">Treatment Protocol</p>
                    <p className="list-item-value">
                      {medicalProtocol.Medication.GeneralTreatment}
                    </p>
                  </li>
                </ul>
              </div>
              {this.findValue(patientData?.sections, "p_diabetes") !== "yes" && (
                <div className="second-page-container mb-8 diabetes-recommendations-border">
                  <p className="pdf-box-heading diabetes-recommendations width-85">
                    {" "}
                    DIABETES PREVENTION - AI RECOMMENDED PROTOCOL (FOR DR REVIEW
                    &#38; ADVISE)
                  </p>
                  <ul className="aicvd-recommendation-list-container">
                    <li className="aicvd-recommendation-list-item diabetes-list-bottom-border">
                      <p className="list-item-heading diabetes-list-right-border">
                        Lab Investigation
                      </p>
                      <p className="list-item-value">
                        {prediabteLabReport.data.lab_investigation === ""
                          ? "None"
                          : prediabteLabReport.data.lab_investigation
                              .trim()
                              .replace(/,+$/, "")}
                      </p>
                    </li>
                    <li className="aicvd-recommendation-list-item diabetes-list-bottom-border">
                      <p className="list-item-heading diabetes-list-right-border">
                        Activity
                      </p>
                      <p className="list-item-value">
                        {prediabteLabReport.data.activity}
                      </p>
                    </li>
                    <li className="aicvd-recommendation-list-item diabetes-list-bottom-border">
                      <p className="list-item-heading diabetes-list-right-border">
                        Referral to consulted
                      </p>
                      <p className="list-item-value">
                        {prediabteLabReport.data.referral_to_consultant}
                      </p>
                    </li>
                    <li className="aicvd-recommendation-list-item diabetes-list-bottom-border">
                      <p className="list-item-heading diabetes-list-right-border">
                        Nutrition
                      </p>
                      <p className="list-item-value">
                        {prediabteLabReport.data.nutrition}
                      </p>
                    </li>
                    <li className="aicvd-recommendation-list-item diabetes-list-bottom-border">
                      <p className="list-item-heading diabetes-list-right-border">
                        Tests follow up
                      </p>
                      <p className="list-item-value">
                        {prediabteLabReport.data.tests_followup}
                      </p>
                    </li>
                    <li className="aicvd-recommendation-list-item border-none">
                      <p className="list-item-heading diabetes-list-right-border">
                        Treatment Protocol
                      </p>
                      <p className="list-item-value">
                        {prediabteLabReport.data.treatment_goal}
                      </p>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <footer className="footer-container">
              <div className="d-flex flex-row justify-space-between plr-25">
                <p className="footer-text">Apollo Health Camps</p>
                <div className="d-flex flex-column alingn-item-end">
                  <p className="footer-text mb-4">
                    This report is accessed by{" "}
                    <span className="text-capital">
                      {patientInfo.doctorName}
                    </span>
                  </p>
                  <p className="footer-text">{this.renderCurrentDate()}</p>
                </div>
              </div>
              <div className="style-line-container">
                <div className="line-1"></div>
                <div className="line-2"></div>
              </div>
            </footer>
          </div>
          <div className="page">
            <div className="page-header">
              <h1 className="header-heading">Participant Report</h1>
              <img
                className="header-log"
                src="apollo-white-logo.png"
                alt="apollo-logo"
              />
            </div>
            <div className="pdf-content-container mt-20">
              <h1 className="pdf-sub-heading mb-20">DOCTOR REVIEW :</h1>
              <div className="review-container">
                <p className="review-heading">Doctor Name :</p>
                <p className="review-value">{patientInfo.doctor_name}</p>
              </div>
              <div className="review-container">
                <p className="review-heading">Review :</p>
                <p className="review-value">{patientInfo.review}</p>
              </div>
              <h1 className="pdf-sub-heading mb-20 mt-70">DISCLAIMER :</h1>
              <ul className="disclaimer-list-conatiner">
                <li className="disclaimer-list-item">
                  This test only derives the 'RISK SCORE' to alert the
                  participant of his/her likely risk predisposition to a
                  disease. It is not a diagnostic tool and does not guarantee
                  accuracy of the result &#38; cannot be independently acted
                  upon.
                </li>
                <li className="disclaimer-list-item">
                  This Risk score and Clinical Algorithm is a general guideline
                  for Physicians. Any additional laboratory investigations,
                  Diagnostic Imaging, Treatment or Patient Education related to
                  lifestyle management is under Physician's or Cardiologist's
                  discretion.
                </li>
                <li className="disclaimer-list-item">
                  To ensure the information in the report is up to date,
                  accurate and correct, Doctor shall be consulted for
                  interpretation of the report.
                </li>
                <li className="disclaimer-list-item">
                  Apollo Hospitals and its Staff does not offer any assurance on
                  the information made available or be liable for any loss or
                  damage as the said report is based on the AICVD Cardiac Risk
                  Score without any intervention from their side.
                </li>
                <li className="disclaimer-list-item">
                  It is deemed that by providing consent to derive the RISK
                  SCORE, the participant has done so at his/her own risk &#38;
                  further agrees with this disclaimer without any limitation to
                  any clauses or sub-clauses
                </li>
              </ul>
            </div>
            <div className="logo-container">
              <img
                className="branding-logo"
                src="apollo-247.png"
                alt="apollo-log"
              />
              <img
                className="branding-logo"
                src="askapollo.png"
                alt="apollo-log"
              />
            </div>
            <footer className="footer-container">
              <div className="d-flex flex-row justify-space-between plr-25">
                <p className="footer-text">Apollo Health Camps</p>
                <div className="d-flex flex-column alingn-item-end">
                  <p className="footer-text mb-4">
                    This report is accessed by{" "}
                    <span className="text-capital">
                      {patientInfo.doctor_name}
                    </span>
                  </p>
                  <p className="footer-text">{this.renderCurrentDate()}</p>
                </div>
              </div>
              <div className="style-line-container">
                <div className="line-1"></div>
                <div className="line-2"></div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

class PatientReport extends React.Component {
  pushToPatientList = () => {
    const { history } = this.props;
    history.replace("/participant-list");
  };
  render() {
    // const pdfReportData = JSON.parse(localStorage.getItem("pdfReportData"))[0];
    // const campData = JSON.parse(localStorage.getItem("camp_data"));

    const pdfReportData = this.props.location.state.lastVisitData;
    const completepatientData = this.props.location.state.completepatientData;

    return (
      <div>
        <div className="menu-icon-with-apollo-logo">
          <button
            className="button back-button"
            onClick={this.pushToPatientList}
          >
            <img src="./back-arrow.svg" alt="back-arrow.svg" />
          </button>
        </div>
        <div className="large-back-button-container">
          <button
            className="button back-button"
            onClick={this.pushToPatientList}
          >
            <img src="./back-arrow.svg" alt="back-arrow.svg" />
          </button>
          <p className="large-back-button-text">Back</p>
        </div>
        <ReactToPrint
          trigger={() => (
            <button className="button download-button">
              <img
                className="icon"
                src="./cloud-download-outline.svg"
                alt="download.svg"
              />
            </button>
          )}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          ref={(el) => (this.componentRef = el)}
          data={pdfReportData}
          patientInfo={completepatientData}
        />
      </div>
    );
  }
}

export default withRouter(PatientReport);
