import React from "react";
import { withRouter } from "react-router";

class AicvdReport extends React.Component {

    pushToCommonReportPage = () => {
        const {history} = this.props;
        history.push('/report')
    }

    render() {
        const {aicvdReport, newPatientObject} = this.props.data;
        return (
            <div className="report-container">
                <div className="menu-icon-with-apollo-logo">
                    <button className="button back-button" onClick={this.pushToCommonReportPage}>
                        <img src="./back-arrow.svg" alt="back-arrow.svg" />
                    </button>
                    <img className="small-apollo-logo" src="./logo.png" alt="apollo.png" />
                </div>
                <div className="large-back-button-container">
                    <button className="button back-button" onClick={this.pushToCommonReportPage}>
                        <img src="./back-arrow.svg" alt="back-arrow.svg" />
                    </button>
                    <p className="large-back-button-text">Back</p>
                </div>
                <div className="health-camp-heading-container">
                    <p className="apollo-hospitals-text">Apollo Hospitals</p>
                    <h1 className="heading text-bold">Health Camps</h1>
                    <div className="heading-style-element-container">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center margin-bottom-30">
                    <div className="style-element-container bottom-0">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                    <div className="sub-heading-with-logo margin-left-right-8">
                        <h1 className="sub-heading">Participant Info</h1>
                        <img className="sub-heading-icon" src="./person-outline.svg" alt="list.svg" />
                    </div>
                    <div className="style-element-container bottom-0 rotate-180">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <div className="participant-info-container">
                    <div className="info-line-container">
                        <p className="line-name">Participant ID</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.uhid}</p>
                    </div>
                    <div className="info-line-container">
                        <p className="line-name">Name</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.name}</p>
                    </div>
                    <div className="info-line-container">
                        <p className="line-name">Age</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.age}</p>
                    </div>
                    <div className="info-line-container">
                        <p className="line-name">Gender</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.gender}</p>
                    </div>
                    <div className="info-line-container">
                        <p className="line-name">Location</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.location}</p>
                    </div>
                    <div className="info-line-container">
                        <p className="line-name">Phone</p>
                        <p className="colon">:</p>
                        <p className="line-value">{newPatientObject.phone}</p>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center margin-bottom-30">
                    <div className="style-element-container bottom-0">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                    <div className="sub-heading-with-logo margin-left-right-8">
                        <h1 className="sub-heading">Cardiac Risk Score</h1>
                        <img className="sub-heading-icon" src="./receipt-outline.svg" alt="list.svg" />
                    </div>
                    <div className="style-element-container bottom-0 rotate-180">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                {/* <div className="sub-heading-with-logo margin-bottom-25">
                    <p className="report-sub-heading margin-bottom-none">INFORMED CONSENT OBTAINED</p>
                    <div className="tick-mark-container">
                        <img src="./checkmark-outline.svg" />
                    </div>
                </div> */}
                <p className="report-sub-heading">Risk Catagory</p>
                <div className="all-risk-catagory-container">
                    <div className="risk-catagory-container">
                        <p className="catagory-title">Risk Status</p>
                        <p className="catagory-value high-risk">{aicvdReport.riskStatus}</p>
                    </div>
                    <div className="risk-catagory-container">
                        <p className="catagory-title">Risk Score</p>
                        <p className="catagory-value">{aicvdReport.riskScore}</p>
                    </div>
                    <div className="risk-catagory-container">
                        <p className="catagory-title">Optimal Score</p>
                        <p className="catagory-value">{aicvdReport.optimal}</p>
                    </div>
                </div>
                <div className="d-flex flex-row margin-top-20 margin-bottom-40">
                    <p className="note-heading">Note:</p>
                    <p className="note-text">The risk category is determined through the ratio between participant score and optimal score at multiple decimal points. The Outputs are shown in whole numbers.</p>
                </div>
                <div className="d-flex flex-row align-items-center margin-bottom-40">
                    <div className="style-element-container bottom-0">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                    <div className="sub-heading-with-logo margin-left-right-8">
                        <h1 className="sub-heading">Top Modifiable Risk Factors</h1>
                        <img className="sub-heading-icon" src="./receipt-outline.svg" alt="list.svg" />
                    </div>
                    <div className="style-element-container bottom-0 rotate-180">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <div className="top-modifiable-risk-factors-container margin-bottom-40">
                    <p className="modifiable-risk-factor">Physical Inactivity</p>
                    <p className="modifiable-risk-factor">Heart Symptoms</p>
                    <p className="modifiable-risk-factor">Hypertension</p>
                </div>
                <div className="d-flex flex-row align-items-center margin-bottom-40">
                    <div className="style-element-container bottom-0">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                    <div className="sub-heading-with-logo margin-left-right-8">
                        <h1 className="sub-heading">Recommended Protocol</h1>
                        <img className="sub-heading-icon" src="./receipt-outline.svg" alt="list.svg" />
                    </div>
                    <div className="style-element-container bottom-0 rotate-180">
                        <div className="style-line"></div>
                        <div className="style-dot"></div>
                    </div>
                </div>
                <p className="recommended-protocal-sub-heading">Lab Investigation</p>
                <p className="recommended-protocal-value">Complete Blood Count. Fasting and Post Prandial Blood Sugar. Lipid Profile. Urea &#38; Creatinine + Other tests as deemed ti (e.a. HBA16) Homocvsteine Levels Lipoprotein a Neutrophil / Lympnocvte Ratio hsCR</p>
                <p className="recommended-protocal-sub-heading">Diagnostics and imaging</p>
                <p className="recommended-protocal-value">ECG, Chest X-ray, 2D Echocardiography, Dobutamine Stress Echo MT ADVANCED Tests- Cardiac CT or Coronary Angiography Thallium Stress Test</p>
                <p className="recommended-protocal-sub-heading">Referral</p>
                <p className="recommended-protocal-value">Cardiologist Referral (Urgent)</p>
                <p className="recommended-protocal-sub-heading">Advice</p>
                <p className="recommended-protocal-value">EDUCATE on lifestyle management REPEAT VISIT every 3 months or earlier for:- Symptoms of Coronary, Artery Disease, - Any other Surgical or Other Procedure Intervention - Adults of anv age if Diabetes, Hypertension, Dyslipidemia or Smoking 1 persIsts as risk factors</p>
                <p className="recommended-protocal-sub-heading">TREATMENT PROTOCOL</p>
                <p className="recommended-protocal-value">Weight control management. It Is recommended to consistently encourage weight control through an appropriate balance or onsical activity, calorIc intake and formal behavioural programmes when indicated to active and maintain a healthy BM&lt;25) Lipid management: To maintain a baseline. Annual control of lipids. glucose metabolism and creatinine are recommended DIabeteS: HDAIc &lt; %% &lt; 53 mmol/mol). Blood pressure control: &lt; 140/90 mmHg Smoking / Tobacco Use cessation: No exposure to tobacco in any form or support in smoking cessation PSychosocial management: Psychosocial risk tractor screening should be considered</p>
                <button className="button next-button margin-top-30" type='submit'>
                    <p className="button-text">Download Report</p>
                </button>
                <div className="end-line"></div>
                <p className="copy-rights-text">
                    <span className="font-weight-700">Copyright @{new Date().getFullYear()} Powered By Apollo Hospitals.</span><br /> All rights reserved.
                </p>
            </div>
        )
    }
}

export default withRouter(AicvdReport);