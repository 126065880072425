import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./index.css";
import { MdDeleteOutline } from "react-icons/md";
import { IoAddCircleSharp } from "react-icons/io5";
import { MdDragIndicator } from "react-icons/md";

const RadioOption = ({ item, onRemove, onAdd, onChange }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="radio-option">
      <div className="drag-handle" {...attributes} {...listeners}>
        <MdDragIndicator className="option-operation-icons" />
      </div>
      <input
        type="radio"
        name="radioOptions"
        className="box-input-element radio-button"
      />
      <span className="c-radio"></span>
      <input
        type="text"
        value={item.label}
        onChange={(e) => onChange(item.id, e.target.value)}
        className="text-input-element"
      />

      <button className="add-btn" onClick={() => onAdd(item.id)}>
        <IoAddCircleSharp className="option-operation-icons" />
      </button>
      <button className="remove-btn" onClick={() => onRemove(item.id)}>
        <MdDeleteOutline className="option-operation-icons" />
      </button>
    </div>
  );
};

export default RadioOption;
