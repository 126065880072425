import React, { useState, useEffect } from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const OptionList = ({
  placeholder,
  id,
  name,
  options,
  setOptions,
  onChange,
  OptionComponent,
  value,
}) => {
  const [listOptions, setListOptions] = useState(options);

  useEffect(() => {
    setListOptions(options);
  }, [options]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = listOptions.findIndex((option) => option.id === active.id);
      const newIndex = listOptions.findIndex((option) => option.id === over.id);
      const newListOptions = arrayMove(listOptions, oldIndex, newIndex);
      setListOptions(newListOptions);
      setOptions(newListOptions); // Ensure the parent state is updated
    }
  };

  const handleRemove = (id) => {
    const newListOptions = listOptions.filter((option) => option.id !== id);
    setListOptions(newListOptions);
    setOptions(newListOptions); // Ensure the parent state is updated
  };

  const handleAdd = (index) => {
    const newItem = {
      id: `option-${Date.now()}`, // Use a timestamp to ensure unique IDs
      label: `Option ${listOptions.length + 1}`,
      checked: false,
    };
    const newListOptions = [
      ...listOptions.slice(0, index + 1),
      newItem,
      ...listOptions.slice(index + 1),
    ];
    setListOptions(newListOptions);
    setOptions(newListOptions); // Ensure the parent state is updated
  };

  const handleChange = (id, value) => {
    const newListOptions = listOptions.map((option) =>
      option.id === id ? { ...option, label: value } : option
    );
    setListOptions(newListOptions);
    setOptions(newListOptions); // Ensure the parent state is updated
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={listOptions} strategy={verticalListSortingStrategy}>
        <input
          type="text"
          id={id}
          placeholder={placeholder}
          className="input-field"
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
        />
        {listOptions.map((option, index) => (
          <OptionComponent
            key={option.id}
            item={option}
            onRemove={() => handleRemove(option.id)}
            onAdd={() => handleAdd(index)}
            onChange={handleChange}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default OptionList;
