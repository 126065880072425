import Cookies from "js-cookie";
import React from "react";
import { withRouter } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

class PatientsList extends React.Component {
  state = {
    allPatientList: [],
    patinetsList: [],
    starts_from: 0,
    patient_list_complete_status: false,
    formId: this.props.location.state,
    api_response_patient_list: [],
    searchField: "all",
    searhquery: "",
    campid: Cookies.get("global_camp_id") || "", // Include campId in state
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchPatientListFromCamp();
    const formId = Cookies.get("global_form_id");
    this.setState({ formId: formId });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    };
  }

  findValue = (data, key) => {
    if (key in data) {
      return data[key];
    }
    for (const k in data) {
      if (typeof data[k] === "object" && data[k] !== null) {
        const found = this.findValue(data[k], key);
        if (found !== undefined) {
          return found;
        }
      }
    }
    return undefined;
  };

  filterPatientList = async () => {
    console.log(this.state.campid, "this.state.campid");
    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/search-api-on-patient-data?query=${this.state.searhquery}&searchField=${this.state.searchField}&campid=${this.state.campid}`;
    const jwt_token = Cookies.get("jwt_token");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const responseObj = await response.json();

      if (responseObj.status === "success") {
        this.setState({
          api_response_patient_list: responseObj.patients_list,
          patient_list_complete_status:
            responseObj.patient_list_complete_status,
        });
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  updateSearchfilter = (event) => {
    const field = event.target.value;
    this.setState({
      searchField: field,
    });
  };

  searchquery = (event) => {
    const query = event.target.value;
    this.setState({
      searhquery: query,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { searhquery, searchField, campid } = this.state;
    if (
      prevState.searhquery !== searhquery ||
      prevState.searchField !== searchField ||
      prevState.campid !== campid
    ) {
      this.filterPatientList();
    }
  }

  fetchPatientListFromCamp = async () => {
    this._isMounted = true;
    const campId = Cookies.get("global_camp_id");
    const url = `https://apollo-totalhealth-camp-ai.azurewebsites.net/patient-list?campId=${campId}&starts_from=${this.state.starts_from}`;
    const jwt_token = Cookies.get("jwt_token");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${jwt_token}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      let patientList = responseObj.patients_list.map((each) => ({
        id: each.id,
        campId: each.camp_id,
        email: each.email,
        mobile: each.mobile,
        uhid: each.uhid,
        name: each.name,
        gender: each.gender,
        age: each.age,
        dateOfBirth: each.dob,
        pincode: each.pincode,
        location: each.location,
        pDiabetes: each.p_diabetes,
        pHypertension: each.p_hypertension,
        pHeart_disease: each.p_heart_disease,
        pDyslipidemia: each.p_dyslipidemia,
        pAnemia: each.p_anemia,
        pCancer: each.p_cancer,
        fDiabetes: each.f_diabetes,
        fHypertension: each.f_hypertension,
        fHeart_disease: each.f_heart_disease,
        fDyslipidemia: each.f_dyslipidemia,
        fAnemia: each.f_anemia,
        fCancer: each.f_cancer,
        diet: each.diet,
        alcohol: each.alcohol,
        tobacco: each.tobacco,
        smoking: each.smoking,
        physicalActivity: each.physical_activity,
        psychologicalStress: each.psychological_stress,
        height: each.height,
        weight: each.weight,
        bmi: each.bmi,
        heartRate: each.heart_rate,
        sBp: each.systolic_bp,
        dBp: each.diastolic_bp,
        temperature: each.temperature,
        chestPain: each.chest_pain,
        respirationRate: each.rate_of_respiration,
        albumin: each.albumin,
        alkphosAlkalinePhosphotase: each.alkphos_alkaline_phosphotase,
        sgpt: each.sgpt,
        sgot: each.sgot,
        totalBilirubin: each.total_bilirubin,
        cholesterol: each.cholesterol,
        hdlCholesterol: each.hdl_cholesterol,
        plateletCount: each.platelet_count,
        totalProtiens: each.total_protiens,
        inr: each.inr,
        createdAt: each.created_at,
        breathlessness: each.breathlessness,
        wheezes: each.wheezes,
        pulseRate: each.pulserate,
        doctorReview: each.review,
        aicvdScore: each.aicvdscore,
        aicvdCat: each.aicvdcat,
        aicvdOptimal: each.aicvdoptimal,
        copdScore: each.copdscore,
        liverScore: each.liverscore,
        RBS: each.RBS,
        copdCat: each.copdcat,
        diaScore: each.diascore,
        diaRisk: each.diarisk,
        covidInfected: each.covid_infected,
        dateOfRtpct: each.date_of_rtpcr,
        covidVaccinated: each.covid_vaccinated,
        covidVaccineName: each.covid_vaccine_name,
        noOfDose: each.no_of_dose,
        lastDateOfDose: each.date_of_last_dose,
        spo2: each.spo2,
        waist: each.waist,
        changeInBodyWeight: each.change_in_weight,
        pulseRhythm: each.pulse_rhythm,
        aicvdLabReport: each.aicvd_lab_report,
        prediabetesLabReport: each.prediabetes_lab_report,
        doctorName: each.doctor_name,
      }));


      let currentPatientList = this.state.allPatientList;
      currentPatientList.push(...patientList);
      
      let totalPatientCount = this.state.starts_from;
      if (this._isMounted) {
        this.setState({
          api_response_patient_list: responseObj.patients_list,
          allPatientList: currentPatientList,
          patinetsList: currentPatientList,
          starts_from: totalPatientCount + currentPatientList.length,
          patient_list_complete_status:
            responseObj.patient_list_complete_status,
          campid: campId,
        });
      }
    }
  };

  filterUserList = (event) => {
    const userEnterWord = event.target.value.toLowerCase();

    const filterList = this.state.allPatientList.filter((each) =>
      each.name.toLowerCase().includes(userEnterWord)
    );
    this.setState({
      patinetsList: filterList,
    });
  };

  pushToReviewPage = (event) => {
    const id = event.target.value;

    Cookies.set("id", id, { expires: 1, path: "/" });

    const { history } = this.props;
    history.push("/participant-review");
  };

  getPatientReport = (event) => {
    const id = event.target.value;
    Cookies.set("id", id, { expires: 1, path: "/" });

    const { history } = this.props;
    history.push("/pdf-report");
  };

  pushToPatientReport = (event) => {
    const id = event.target.value;
    Cookies.set("patient_id", id, { expires: 1, path: "/" });

    const paticularPatientData = this.state.api_response_patient_list.filter(
      (each) => each.id === id
    );

    const { history } = this.props;
    localStorage.setItem(
      "patientReport",
      JSON.stringify(paticularPatientData[0])
    );

    history.push("/patient-review-page");
  };

  editPatientDetails = (patient_id) => {
    const editPatientDetails = this.state.api_response_patient_list.filter(
      (each) => each.id === patient_id
    );
    const { history } = this.props;
    history.push({
      pathname: "/form-fields",
      state: {
        editPatientDetails: editPatientDetails[0],
        formId: editPatientDetails[0].formId,
        patient_id: patient_id,
      },
    });
  };

  renderPatientCard = (data, index) => {
    console.log(data, "data");
    const jwt_token = Cookies.get("jwt_token");
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };

    const parseJwtObj = parseJwt(jwt_token);
    const role = parseJwtObj.uRole;

    return (
      <div id="card" className="details-card-container" key={index}>
        <div className="d-flex flex-row justify-content-between align-items-center card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Patient ID</p>
            <p className="content">{data.uhid}</p>
          </div>
          <div className="title-with-content-container">
            <p className="title">Age</p>
            <p className="content ">{data.age}</p>
          </div>
          <div className="title-with-content-container">
            <p className="title">Gender</p>
            <p className="content ">{data.gender}</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Name</p>
            <p className="content overflow-x-auto">{data.name}</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between card-content-gap">
          <div className="title-with-content-container">
            <p className="title">Doctor Review</p>
            <p className="content overflow-x-auto">
              {data.review === null ||
              data.review === "Null" ||
              data.review === "NULL"
                ? "Doctor Review is Pending"
                : data.review}
            </p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="title-with-content-container width-50">
            <p className="title">Camp Location</p>
            <p className="content highlight-camp-content">{data.location}</p>
          </div>
          {role === "doctor" ? (
            <div className="card-buttons-container">
              <>
                <button
                  className="button camp-view-button"
                  value={data.id}
                  onClick={this.pushToPatientReport}
                >
                  View
                </button>
              </>
            </div>
          ) : (
            <>
              <button
                className="button edit-button"
                value={data.id}
                onClick={() => this.editPatientDetails(data.id)}
              >
                Edit
              </button>
              <button
                className="button camp-view-button"
                value={data.id}
                onClick={this.pushToPatientReport}
              >
                View
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  pushToCampsList = () => {
    const { history } = this.props;
    history.push("/camps-list");
  };

  pushToCreatePatinet = () => {
    const { history } = this.props;
    history.push({
      pathname: "/form-fields",
      state: { formId: this.state.formId },
    });
  };

  render() {
    const { patient_list_complete_status, api_response_patient_list } =
      this.state;
    const jwt_token = Cookies.get("jwt_token");
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        console.log(e.message);
        return null;
      }
    };

    const parseJwtObj = parseJwt(jwt_token);
    const role = parseJwtObj.uRole;

    // const campData = JSON.parse(localStorage.getItem('camp_data'));
    // const splitedEndDate = campData.endDate.split('-');
    // const todayDate = new Date();
    // const campEndDate = new Date(`${splitedEndDate[1]}/${splitedEndDate[0]}/${splitedEndDate[2]}`)

    // let campStatus = false;

    return (
      <div className="participant-list-conainer">
        <div className="menu-icon-with-apollo-logo">
          <button className="button back-button" onClick={this.pushToCampsList}>
            <img src="./back-arrow.svg" alt="back-arrow.svg" />
          </button>
          <img
            className="small-apollo-logo"
            src="./logo.png"
            alt="apollo.png"
          />
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="large-back-button-container">
            <button
              className="button back-button"
              onClick={this.pushToCampsList}
            >
              <img src="./back-arrow.svg" alt="back-arrow.svg" />
            </button>
            <p className="large-back-button-text">Back</p>
          </div>
          <div className="health-camp-heading-container">
            <p className="apollo-hospitals-text">Apollo Hospitals</p>
            <h1 className="heading text-bold">Health Camps</h1>
            <div className="heading-style-element-container">
              <div className="style-line"></div>
              <div className="style-dot"></div>
            </div>
          </div>
        </div>
        <div className="sub-heading-with-button-container">
          <div className="sub-heading-with-logo">
            <h1 className="sub-heading">Participant List</h1>
            <img
              className="sub-heading-icon"
              src="./layers-outline.svg"
              alt="list.svg"
            />
          </div>
          {role === "executive" && (
            <button
              className="button add-button"
              onClick={this.pushToCreatePatinet}
            >
              Create Participant
            </button>
          )}
        </div>
        <div className="input-container max-width-400 margin-none search-with-select">
          <div className="input-with-logo">
            <input
              type="text"
              className="login-input"
              placeholder="Search by Name"
              name="search"
              onChange={this.searchquery}
            />
            <img className="icon" src="./search.svg" alt="apollo.png" />
          </div>
          <div className="search-select-container">
            <select className="select-bar" onChange={this.updateSearchfilter}>
              <option value="all">All</option>
              <option value="name">Name</option>
              <option value="uhid">ID</option>
              <option value="mob">Phone Number</option>
              <option value="age">Age</option>
              <option value="gender">Gender</option>
            </select>
            <IoIosArrowDown className="search-dropdown-icon" />
          </div>
        </div>
        <div className="details-cards-container">
          {api_response_patient_list.length > 0 ? (
            api_response_patient_list.map((each, index) =>
              this.renderPatientCard(each, index)
            )
          ) : (
            <div className="d-flex flex-row justify-content-center">
              <p className="not-found-text">Oops..! User Not Found</p>
              <img
                className="sub-heading-icon color-gray"
                src="./sad-outline.svg"
                alt="sad.svg"
              />
            </div>
          )}
          {patient_list_complete_status ? (
            <div className="show-more-button-container">
              <button
                className="button show-more"
                onClick={this.fetchPatientListFromCamp}
              >
                View More
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(PatientsList);
